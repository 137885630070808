import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';

import { Button, Input, Form, Space } from 'antd';

import FormHeader from '@controls/form-header/form-header';

import { exception } from '@extensions/notification';
import { Permission, hasPermission } from '@enums/permission';

import { userLoaded } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { IUserSession } from '@entities/user-session';
import { ITag } from '@entities/tag';

const Tag = () => {
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [entity, setEntity] = useState<ITag>({ id: undefined, name: undefined });
    const [loading, setLoading] = useState<boolean>(false);
    const [manageTag] = useState<boolean>(hasPermission(userSession.permissions, Permission.ManageTags));

    const onSave = () => {
        setLoading(true);

        serverFetch(`tags`, { method: 'POST', bodyData: entity })
            .then(() => {
                setLoading(false);
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка добавления тега', ex, () => d(userLoaded(undefined)));
            });
    };

    return (
        <>
            <FormHeader title='Добавить тег' />
            {!loading && (
                <Form colon={false} labelCol={{ span: 3 }} wrapperCol={{ span: 8 }} onFinish={onSave}>
                    <Form.Item
                        initialValue={entity.id}
                        required
                        label='Тег'
                        name='code'
                        rules={[
                            { required: true, message: 'Укажите название тега' },
                            {
                                validator: async (_, value) => {
                                    let isExist = await serverFetch(`tags/exist/${value}`, { method: 'GET' })
                                        .then((data) => {
                                            return data;
                                        })
                                        .catch((ex) => {
                                            exception(api, 'Ошибка проверки тега', ex, () => d(userLoaded(undefined)));
                                        });

                                    if (isExist) return Promise.reject('Такой тег уже существует');
                                    return Promise.resolve();
                                },
                            },
                        ]}
                    >
                        <Input
                            disabled={!manageTag}
                            autoFocus
                            prefix='#'
                            onChange={(data) => {
                                setEntity({ ...entity, name: data.target.value });
                            }}
                        />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 3, span: 8 }}>
                        <Space size={'small'} style={{ float: 'right' }}>
                            <Button type='text' onClick={() => navigate(-1)}>
                                Отменить
                            </Button>
                            {manageTag && (
                                <Button type='primary' htmlType='submit' loading={loading}>
                                    Сохранить
                                </Button>
                            )}
                        </Space>
                    </Form.Item>
                </Form>
            )}

            {contextHolder}
        </>
    );
};

export default Tag;
