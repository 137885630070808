import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Divider, Col, Row, Input, Form } from 'antd';
import { notification } from 'antd';

import FormHeader from '@controls/form-header/form-header';
import Consignments from '../../core/warehouse/consignments/consignments';

import { userLoaded } from '@store/actions';

import { exception, error } from '@extensions/notification';
import { serverFetch } from '@src/core/server';
import { TruckStatus } from '@enums/truck-status';

const Truck = () => {
    const { TextArea } = Input;

    const { id } = useParams();

    const d = useDispatch();

    const userSession = useSelector((s) => s.userSession);
    const [api, contextHolder] = notification.useNotification();

    const [entity, setEntity] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        initData();
    }, []);

    const initData = async () => {
        if (!id) {
            setEntity({});
            return;
        }

        setLoading(true);

        serverFetch(`trucks/${id}`, { method: 'GET' })
            .then((data) => {
                setEntity(data);
                setLoading(false);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка получения машины', ex, () => d(userLoaded(null)));
            });
    };

    return (
        <>
            <Row>
                <FormHeader title='Приемка машины' />
            </Row>

            {!loading && (
                <>
                    <Row>
                        <Col span={12}>
                            <Form colon={false} labelCol={{ span: 6 }} wrapperCol={{ span: 10 }}>
                                <Form.Item initialValue={entity.number} label='Номер машины' name='number' wrapperCol={{ span: 5 }}>
                                    <Input disabled={true} />
                                </Form.Item>
                                <Form.Item label='Комментарий' name='comment' initialValue={entity?.comment}>
                                    <TextArea rows={4} disabled={true} />
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>

                    <Consignments truckId={id} viewOnly={entity.status === TruckStatus.Delivered} />
                </>
            )}

            {contextHolder}
        </>
    );
};

export default Truck;
