import React, { useState, useEffect } from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';

import { Dayjs } from 'dayjs';

import { useElementSize } from 'usehooks-ts';

import { Input, Table, Form, Switch, DatePicker, notification, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { LoadingOutlined, ReloadOutlined, SendOutlined, FilterFilled, EyeFilled } from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';
import Filter from '@controls/filter/filter';

import { exception, securityRestriction } from '@extensions/notification';

import { delayAction } from '@extensions/utils';

import { userLoaded, setFilter } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { IUserSession } from '@entities/user-session';
import { INotification } from '@entities/notification';
import { INotificationFilter } from '@entities/notification-filter';

import { Permission, hasPermission } from '@enums/permission';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const filterContext: string = 'Notifications';

const Notifications = () => {
    const initFilter: INotificationFilter = { personalOnly: false, unreadOnly: true };

    const { RangePicker } = DatePicker;

    const [containerRef, { width, height }] = useElementSize();
    const [filterRef, { width: filterWidth, height: filterHeight }] = useElementSize();

    const navigate = useNavigate();
    const resolved = useResolvedPath('');

    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);
    const filter = useAppSelector<INotificationFilter>((s) => s.filters[filterContext]);

    const [entities, setEntities] = useState<Array<INotification>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);

    const [showFilter, setShowFilter] = useState<boolean>(true);

    useEffect(() => {
        if (!refreshRequired) return;

        setRefreshRequired(false);

        setEntities([]);

        setLoading(true);

        serverFetch('notifications', { method: 'GET', queryParams: filter })
            .then((data) => {
                setEntities(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения сообщений', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    }, [refreshRequired]);

    useEffect(() => {
        if (!filter) {
            d(setFilter(initFilter, filterContext));

            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => setRefreshRequired(true),
                    },
                    {
                        type: 'primary',
                        label: 'Отправить',
                        key: 'send',
                        icon: <SendOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.SendNotification)) {
                                securityRestriction(api, [Permission.SendNotification]);
                                return;
                            }

                            navigate(`${resolved.pathname}/new`);
                        },
                    },
                ]}
                farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setShowFilter(!showFilter);
                        },
                    },
                ]}
            />
        );
    };

    const renderFilter = () => {
        return (
            <div ref={filterRef}>
                <Filter
                    items={[
                        <Input
                            key='searchText'
                            placeholder='Получатель / ИНН / Контрагент / Сообщение'
                            value={filter?.searchText}
                            style={{ width: 320 }}
                            onChange={(data) => {
                                d(setFilter({ ...filter, searchText: data.target.value }, filterContext));
                            }}
                        />,
                        <RangePicker
                            style={{ width: 260 }}
                            allowEmpty={[true, true]}
                            key='created'
                            format='DD.MM.YYYY'
                            placeholder={['Отправлено с', 'до']}
                            cellRender={(current) => {
                                return <div className='ant-picker-cell-inner'>{(current as Dayjs).date()}</div>;
                            }}
                            value={[
                                filter?.createdFrom ? dayjs(filter?.createdFrom) : null,
                                filter?.createdTo ? dayjs(filter?.createdTo) : null,
                            ]}
                            onChange={(value) => {
                                if (!value) {
                                    d(
                                        setFilter(
                                            {
                                                ...filter,
                                                createdFrom: undefined,
                                                createdTo: undefined,
                                            },
                                            filterContext
                                        )
                                    );

                                    return;
                                }

                                if (value[0]) {
                                    d(
                                        setFilter(
                                            {
                                                ...filter,
                                                createdFrom: dayjs(value[0])
                                                    .utc(true)
                                                    .set('hour', 0)
                                                    .set('minute', 0)
                                                    .set('second', 0)
                                                    .toString(),
                                            },
                                            filterContext
                                        )
                                    );
                                }

                                if (value[1]) {
                                    d(
                                        setFilter(
                                            {
                                                ...filter,
                                                createdTo: dayjs(value[1])
                                                    .utc(true)
                                                    .set('hour', 23)
                                                    .set('minute', 59)
                                                    .set('second', 59)
                                                    .toString(),
                                            },
                                            filterContext
                                        )
                                    );
                                }
                            }}
                        />,
                        <Form.Item key='unreadOnly' label='Непрочитанные' style={{ margin: 0 }}>
                            <Switch
                                checked={filter?.unreadOnly}
                                onChange={(value) => {
                                    d(setFilter({ ...filter, unreadOnly: value }, filterContext));
                                }}
                            />
                        </Form.Item>,
                    ]}
                    onClear={() => d(setFilter(initFilter, filterContext))}
                />
            </div>
        );
    };

    const renderTable = () => {
        const columns: ColumnsType<INotification> = [
            {
                title: '',
                align: 'center',
                width: 35,
                render: (_, record) => {
                    return (
                        record.isRead && (
                            <Tooltip title='Прочитано'>
                                <EyeFilled style={{ color: 'var(--primary-color)' }} />
                            </Tooltip>
                        )
                    );
                },
            },
            {
                title: 'Получатель',
                dataIndex: 'recipient',
                width: 200,
            },
            {
                title: 'Контрагент',
                dataIndex: 'legalName',
                width: 250,
            },
            {
                title: 'ИНН',
                dataIndex: 'inn',
                width: 200,
            },
            {
                title: 'Сообщение',
                width: 500,
                render: (_: any, record: INotification) => {
                    let dots = record.body && record.body.length > 143 ? '...' : '';
                    return `${record.body?.substring(0, 143)}${dots}`;
                },
            },
            {
                title: 'Отправлено',
                width: 130,
                render: (_: any, record: INotification) => {
                    return dayjs.utc(record.createdOn).local().format('DD.MM.YYYY HH:mm');
                },
            },
            {
                title: 'Отправитель',
                dataIndex: 'createdByName',
                width: 250,
            },

            {},
        ];

        return (
            <Table
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={entities}
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                onRow={(record) => {
                    return {
                        style: { fontWeight: record.isRead ? '' : 600 },
                    };
                }}
                pagination={false}
                virtual={true}
                scroll={{ y: height + (showFilter ? 0 : 58) }}
            />
        );
    };

    return (
        <div ref={containerRef} style={{ height: `calc(100vh - 195px - ${filterHeight}px)` }}>
            {renderToolbar()}
            {showFilter && renderFilter()}
            {renderTable()}

            {contextHolder}
        </div>
    );
};

export default Notifications;
