import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';

import { saveAs } from 'file-saver';

import { useElementSize } from 'usehooks-ts';

import { useAppDispatch, useAppSelector } from '@store/hooks';

import { Table, Input, notification, Form, Flex, Modal, ColorPicker, Drawer, Button, Tag, Tooltip, InputNumber } from 'antd';
import { ColumnsType } from 'antd/es/table';
import type { GetRef } from 'antd';

import {
    LoadingOutlined,
    ReloadOutlined,
    FilterFilled,
    HistoryOutlined,
    SwapOutlined,
    DollarOutlined,
    FileExcelOutlined,
} from '@ant-design/icons';

import Filter from '@controls/filter/filter';
import Toolbar from '@controls/toolbar/toolbar';
import Transactions from '@controls/transactions/transactions';

import DepositingModal from '@src/components/balances/depositing-modal';
import RefundModal from '@components/balances/refund-modal';
import TransferAccountsModal from '@src/components/balances/transfer-accounts-modal';
import DebtWriteOffModal from '@src/components/balances/debt-write-off-modal';

import { serverFetch } from '@src/core/server';

import { exception, success } from '@extensions/notification';
import { NoColor } from '@src/core/types';
import { delayAction, toFinanceString, dataURItoBlob } from '@extensions/utils';
import { userLoaded, setFilter } from '@store/actions';

import { ITransactionParams } from '@entities/transaction-params';
import { IUserFilter } from '@entities/user-filter';
import { IUserBalance } from '@entities/user-balance';
import { ISystemSetting } from '@entities/system-setting';

import { Currency, enumSign as currencySign } from '@enums/currency';
import { SystemSettingType } from '@enums/system-setting-type';

import { BillIcon, WalletIcon } from '@icons/index';
import { RubleFilledIcon, UsdFilledIcon, EuroFilledIcon } from '@icons/filled/index';

const dayjs = require('dayjs');

var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

type TableRef = GetRef<typeof Table>;

const filterContext: string = 'Balances';

const Balances = () => {
    const initFilter: IUserFilter = { isActive: true, isArchived: false };

    const navigate = useNavigate();
    const resolved = useResolvedPath('../finance');

    const [containerRef, { width, height }] = useElementSize();
    const [filterRef, { width: filterWidth, height: filterHeight }] = useElementSize();

    const tableRef = useRef<TableRef>(null);
    const [colorForm] = Form.useForm();
    const [internalUsdExchangeRateForm] = Form.useForm();

    const filter = useAppSelector<IUserFilter>((s) => s.filters[filterContext]);

    const d = useAppDispatch();

    const [api, notificationContextHolder] = notification.useNotification();

    const [showFilter, setShowFilter] = useState<boolean>(true);

    const [balances, setBalances] = useState<Array<IUserBalance>>([]);
    const [currentBalance, setCurrentBalance] = useState<IUserBalance>();
    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [totalBalance, setTotalBalance] = useState<number>(0);

    const [lastSelectedKey, setLastSelectedKey] = useState<React.Key>();

    const [openDepositingDialog, setOpenDepositingDialog] = useState<boolean>(false);
    const [openDebtWriteOffDialog, setOpenDebtWriteOffDialog] = useState<boolean>(false);
    const [openRefundDialog, setOpenRefundDialog] = useState<boolean>(false);
    const [openTransferAccountsDialog, setOpenTransferAccountsDialog] = useState<boolean>(false);
    const [openColorDialog, setOpenColorDialog] = useState<boolean>(false);

    const [openTransactions, setOpenTransactions] = useState<boolean>(false);

    const [internalUsdExchangeRateSetting, setInternalUsdExchangeRateSetting] = useState<ISystemSetting>();
    const [openInternalUsdExchangeRate, setOpenInternalUsdExchangeRate] = useState<boolean>(false);

    const [cbUsdExchangeRate, setCbUsdExchangeRate] = useState<number>(0);

    useEffect(() => {
        let cleanup = false;

        if (!refreshRequired) return;

        setSelectedIds([]);
        setCurrentBalance(undefined);
        setBalances([]);

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch('users/balances', { method: 'GET', queryParams: filter })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения балансов', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('systemSettings/cbusdexchangerate', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения курса ЦБ', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch(`systemSettings/${SystemSettingType.InternalExchangeRate}`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения внутреннего курса', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                let entities: Array<IUserBalance> = result[0][0];

                setBalances(entities);
                setCbUsdExchangeRate(result[0][1]);

                let internalUsdExchangeRateSetting: ISystemSetting = result[0][2];
                setInternalUsdExchangeRateSetting(internalUsdExchangeRateSetting);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    useEffect(() => {
        if (!loading && lastSelectedKey) {
            tableRef.current?.scrollTo({ key: lastSelectedKey });
            setLastSelectedKey(undefined);
        }
    }, [loading]);

    useEffect(() => {
        if (!filter) {
            d(setFilter(initFilter, filterContext));
            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    useEffect(() => {
        let balance: number = 0;

        balances.map((b) => {
            balance += b.amount || 0;
        });

        setTotalBalance(balance);
    }, [balances]);

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            setLastSelectedKey(selectedRowKeys[0]);

            let entity = balances.find((o) => o.userId == selectedRowKeys[0]);
            setCurrentBalance(entity);
        } else {
            setCurrentBalance(undefined);
        }
    };

    const onDebtRemind = () => {
        serverFetch('bills/debts/notification', { method: 'POST' })
            .then(() => {
                success(api, 'Уведомления успешно отправлены');
            })
            .catch((ex) => {
                exception(api, 'Ошибка отправки уведомлений', ex, () => d(userLoaded(undefined)));
            });
    };

    const onDepositin = (entity: ITransactionParams) => {
        if (!entity) return;

        serverFetch(`transactions/depositing`, { method: 'POST', bodyData: entity })
            .then(() => {
                setOpenDepositingDialog(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка внесения денежных средств', ex, () => d(userLoaded(undefined)));
            });
    };

    const onDebtWriteOff = (entity: ITransactionParams) => {
        if (!entity) return;

        serverFetch(`transactions/debtwriteoff`, { method: 'POST', bodyData: entity })
            .then(() => {
                setOpenDebtWriteOffDialog(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка внесения денежных средств', ex, () => d(userLoaded(undefined)));
            });
    };

    const onTransfer = (entity: ITransactionParams) => {
        if (!entity) return;

        serverFetch(`transactions/transfer`, { method: 'POST', bodyData: entity })
            .then(() => {
                setOpenTransferAccountsDialog(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка перевода между счетами', ex, () => d(userLoaded(undefined)));
            });
    };

    const onRefund = (entity: ITransactionParams) => {
        if (!entity) return;

        serverFetch(`transactions/refund`, { method: 'POST', bodyData: entity })
            .then(() => {
                setOpenRefundDialog(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка возвращения денежных средств', ex, () => d(userLoaded(undefined)));
            });
    };

    const onColor = (data: any) => {
        serverFetch(`users/color`, {
            method: 'POST',
            bodyData: { ids: selectedIds, color: typeof data.color === 'string' ? data.color : data.color.toHexString() },
        })
            .then(() => {
                colorForm.resetFields();

                onSelectChange([]);
                setRefreshRequired(true);
                setOpenColorDialog(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка изменения цвета', ex, () => d(userLoaded(undefined)));
            });
    };

    const onExport = () => {
        serverFetch(`users/${currentBalance?.userId}/exportbalances`, { method: 'GET' })
            .then((data) => {
                let fileBody = `data:${data.contentType};base64,${data.fileContents}`;
                saveAs(dataURItoBlob(fileBody), data.fileDownloadName);
            })
            .catch((ex) => {
                exception(api, 'Ошибка выгрузки счетов', ex, () => d(userLoaded(undefined)));
            });
    };

    const onInternalUsdExchangeRate = (data: ISystemSetting) => {
        data.type = SystemSettingType.InternalExchangeRate;

        serverFetch(`systemSettings`, { method: 'POST', bodyData: data })
            .then(() => {
                internalUsdExchangeRateForm.resetFields();
                setInternalUsdExchangeRateSetting(data);
                setOpenInternalUsdExchangeRate(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка сохранения внутреннего курса', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderFilter = () => {
        return (
            <div ref={filterRef}>
                <Filter
                    items={[
                        <Input
                            style={{ width: 150 }}
                            key='login'
                            placeholder='Логин'
                            value={filter?.login}
                            onChange={(data: any) => {
                                d(setFilter({ ...filter, login: data.target.value }, filterContext));
                            }}
                        />,
                        <Input
                            style={{ width: 300 }}
                            key='searchtText'
                            placeholder='Контрагент'
                            value={filter?.searchtText}
                            onChange={(data: any) => {
                                d(setFilter({ ...filter, searchtText: data.target.value }, filterContext));
                            }}
                        />,
                    ]}
                    onClear={() => d(setFilter(initFilter, filterContext))}
                />
            </div>
        );
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Счета на оплату',
                        key: 'bills',
                        disabled: loading || !currentBalance,
                        icon: <BillIcon />,
                        onClick: () => {
                            navigate(`${resolved.pathname}/bills/${currentBalance?.userId}`);
                        },
                    },
                    {
                        label: 'Выгрузить в Excel',
                        key: 'exportToExcel',
                        disabled: loading || !currentBalance,
                        icon: <FileExcelOutlined />,
                        onClick: () => onExport(),
                    },
                    /*  {
                        label: 'Задать цвет',
                        key: 'color',
                        disabled: loading || selectedIds.length <= 0,
                        icon: <BgColorsOutlined />,
                        onClick: () => setOpenColorDialog(true),
                    }, */
                    /*  {
                        label: 'Отправить уведомления о задолженности',
                        key: 'debtRemind',
                        icon: <NotificationOutlined />,
                        onClick: () => onDebtRemind(),
                    }, */
                ]}
                farControl={
                    <Flex gap='middle' align='center'>
                        <Flex gap='small'>
                            <Tooltip title='Задать внутренний курс'>
                                <Button type='primary' icon={<DollarOutlined />} onClick={() => setOpenInternalUsdExchangeRate(true)} />
                            </Tooltip>
                            <Tag color='#b7eb8f' style={{ fontSize: 18, padding: 5, color: '#000000', fontWeight: 600 }}>
                                Внутренний курс: {internalUsdExchangeRateSetting ? internalUsdExchangeRateSetting.value : 0}
                                {currencySign(Currency.Rub)}
                            </Tag>
                        </Flex>
                        <Tag color='#ffee96' style={{ fontSize: 18, padding: 5, color: '#000000', fontWeight: 600 }}>
                            Курс ЦБ: {cbUsdExchangeRate} {currencySign(Currency.Rub)}
                        </Tag>
                    </Flex>
                }
                farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setShowFilter(!showFilter);
                        },
                    },
                ]}
            />
        );
    };

    const renderTable = () => {
        const columns: ColumnsType<IUserBalance> = [
            {
                title: 'Логин',
                width: 200,
                dataIndex: 'userLogin',
                render: (_, record) => {
                    return (
                        <div>
                            <span style={{ marginRight: 5 }}>
                                <a
                                    onClick={() => {
                                        navigate(`${resolved.pathname}/balancedetails/${record?.userId}`);
                                    }}
                                >
                                    {record.userLogin}
                                </a>
                            </span>

                            <Flex align='center' gap='small' style={{ marginTop: 7 }}>
                                <Tooltip placement='topLeft' title='Внести денежные средства на счет'>
                                    <Button
                                        icon={<WalletIcon style={{ fontSize: 16 }} />}
                                        size='small'
                                        onClick={() => {
                                            setCurrentBalance(balances.find((b) => b.userId == record.userId));
                                            setOpenDepositingDialog(true);
                                        }}
                                    />
                                </Tooltip>

                                <Tooltip placement='topLeft' title='Перевод между счетами'>
                                    <Button
                                        icon={<SwapOutlined />}
                                        size='small'
                                        onClick={() => {
                                            setCurrentBalance(balances.find((b) => b.userId == record.userId));
                                            setOpenTransferAccountsDialog(true);
                                        }}
                                    />
                                </Tooltip>
                                <Tooltip placement='topLeft' title='История операций'>
                                    <Button
                                        icon={<HistoryOutlined />}
                                        size='small'
                                        onClick={() => {
                                            setCurrentBalance(balances.find((b) => b.userId == record.userId));
                                            setOpenTransactions(true);
                                        }}
                                    />
                                </Tooltip>

                                {/*             {
                        label: 'Вернуть денежные средства со счета',
                        key: 'refund',
                        disabled: loading || !currentBalance,
                        icon: <RefundIcon />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.AllowRevertPayment)) {
                                noAccessError(api, [Permission.AllowRevertPayment]);
                                return;
                            }

                            setOpenRefundDialog(true);
                        },
                    }, */}
                            </Flex>

                            <Flex align='start' gap='small' vertical={true} style={{ marginTop: 7 }}>
                                {record.useCashPayments && (
                                    <Tag color='green' style={{ color: '#000000' }}>
                                        Наличный
                                    </Tag>
                                )}
                                {record.useNonCashPayments && (
                                    <Tag color='#ffee96' style={{ color: '#000000' }}>
                                        Безналичный
                                    </Tag>
                                )}
                            </Flex>
                        </div>
                    );
                },
            },
            {
                title: 'Контрагент',
                width: 350,
                render: (_, record) => {
                    return (
                        <>
                            <div>{record.legalName}</div>
                            <div>{record.inn}</div>
                        </>
                    );
                },
            },
            {
                title: 'Расчётный счёт',
                width: 150,
                onCell: (record) => ({
                    style: {
                        fontWeight: 600,
                    },
                }),
                render: (_, record) => {
                    return (
                        <Flex gap='small' vertical={true}>
                            {record.userAccounts?.map((a) => {
                                return (
                                    <Flex key={a.id} align='center'>
                                        {a.currency == Currency.Rub ? (
                                            <RubleFilledIcon style={{ fontSize: 18, marginRight: 2 }} />
                                        ) : a.currency == Currency.Usd ? (
                                            <UsdFilledIcon style={{ fontSize: 18, marginRight: 2 }} />
                                        ) : (
                                            <EuroFilledIcon style={{ fontSize: 18, marginRight: 2 }} />
                                        )}

                                        <span>{toFinanceString(a.amount || 0, 2)}</span>
                                    </Flex>
                                );
                            })}
                        </Flex>
                    );
                },
            },
            {
                title: 'Долг перед клиентом',
                width: 200,
                onCell: (record) => ({
                    style: {
                        background: '#FFEE96',
                    },
                }),
                render: (_, record) => {
                    return (
                        <Flex gap='small' vertical={true}>
                            {record.userDebts?.map((a) => {
                                return (
                                    <div>
                                        {a.debtName}:
                                        <span style={{ marginLeft: 5, fontWeight: 600, color: 'var(--main-red)' }}>
                                            {toFinanceString(a.amount || 0, 2)} {currencySign(a.currency)}
                                        </span>
                                    </div>
                                );
                            })}
                        </Flex>
                    );
                },
            },
            {
                title: 'Баланс $',
                width: 200,
                align: 'center',
                onCell: (record) => ({
                    style: {
                        fontWeight: 600,
                        color: '#FFFFFF',
                        background: record.amount >= 0 ? 'var(--main-green)' : 'var(--main-red)',
                    },
                }),
                render: (_, record) => {
                    return <span>{toFinanceString(record.amount || 0, 2)}</span>;
                },
            },
            {},
        ];

        return (
            <Table
                ref={tableRef}
                rowKey='userId'
                size='small'
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                columns={columns}
                dataSource={balances}
                pagination={false}
                rowSelection={{
                    selectedRowKeys: selectedIds,
                    onChange: onSelectChange,
                    columnWidth: 35,
                }}
                onRow={(record: IUserBalance) => {
                    return {
                        style: {
                            background: record.userColor,
                        },
                        onClick: (event) => {
                            if (!record.userId) return;

                            onSelectChange([record.userId]);
                        },
                    };
                }}
                virtual={true}
                footer={() => {
                    return (
                        <Flex justify='start' style={{ fontWeight: 700, fontSize: 16 }} align='center'>
                            <span>Итого:</span>
                            <span style={{ marginLeft: 5, color: totalBalance >= 0 ? 'var(--main-green)' : 'var(--main-red)' }}>
                                {toFinanceString(totalBalance, 2)} $
                            </span>
                        </Flex>
                    );
                }}
                scroll={{ y: height + (showFilter ? 0 : 58) }}
            />
        );
    };

    return (
        <div ref={containerRef} style={{ height: `calc(100vh - 233px - ${filterHeight}px)` }}>
            {renderToolbar()}
            {showFilter && renderFilter()}
            {renderTable()}

            {currentBalance && openDepositingDialog && (
                <DepositingModal
                    userId={currentBalance.userId}
                    userLogin={currentBalance.userLogin}
                    onCancel={() => setOpenDepositingDialog(false)}
                    onSave={(value: ITransactionParams) => onDepositin(value)}
                    api={api}
                />
            )}

            {currentBalance && openDebtWriteOffDialog && (
                <DebtWriteOffModal
                    userId={currentBalance.userId}
                    userLogin={currentBalance.userLogin}
                    onCancel={() => setOpenDebtWriteOffDialog(false)}
                    onSave={(value: ITransactionParams) => onDebtWriteOff(value)}
                    api={api}
                />
            )}

            {currentBalance && openTransferAccountsDialog && (
                <TransferAccountsModal
                    userId={currentBalance.userId}
                    userLogin={currentBalance.userLogin}
                    accounts={currentBalance.userAccounts || []}
                    onCancel={() => setOpenTransferAccountsDialog(false)}
                    onSave={(value: ITransactionParams) => onTransfer(value)}
                    api={api}
                />
            )}

            {currentBalance && openRefundDialog && (
                <RefundModal
                    userId={currentBalance.userId}
                    userLogin={currentBalance.userLogin}
                    balance={currentBalance.amount}
                    onCancel={() => setOpenRefundDialog(false)}
                    onSave={(value: ITransactionParams) => onRefund(value)}
                    api={api}
                />
            )}

            <Modal
                width={400}
                title='Задать цвет'
                open={openColorDialog}
                okText='ОК'
                closable={false}
                onOk={() => {
                    colorForm.submit();
                }}
                onCancel={() => {
                    colorForm.resetFields();
                    setOpenColorDialog(false);
                }}
            >
                <Form colon={false} wrapperCol={{ span: 24 }} form={colorForm} onFinish={onColor}>
                    <Form.Item name='color' initialValue={NoColor} required rules={[{ required: true, message: 'Укажите цвет' }]}>
                        <ColorPicker
                            styles={{ popupOverlayInner: { width: 292 } }}
                            presets={[
                                {
                                    label: '',
                                    colors: [
                                        NoColor,
                                        '#F5222D4D',
                                        '#FA8C164D',
                                        '#FADB144D',
                                        '#8BBB114D',
                                        '#52C41A4D',
                                        '#13A8A84D',
                                        '#1677FF4D',
                                        '#2F54EB4D',
                                        '#722ED14D',
                                        '#EB2F964D',
                                    ],
                                },
                            ]}
                            panelRender={(_, { components: { Picker, Presets } }) => (
                                <div
                                    className='custom-panel'
                                    style={{
                                        display: 'flex',
                                        width: 250,
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: 1,
                                        }}
                                    >
                                        <Presets />
                                    </div>
                                </div>
                            )}
                        />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                width={250}
                title='Задать внутренний курс'
                open={openInternalUsdExchangeRate}
                okText='ОК'
                closable={false}
                onOk={() => {
                    internalUsdExchangeRateForm.submit();
                }}
                onCancel={() => {
                    internalUsdExchangeRateForm.resetFields();
                    setOpenInternalUsdExchangeRate(false);
                }}
            >
                <Form colon={false} wrapperCol={{ span: 24 }} form={internalUsdExchangeRateForm} onFinish={onInternalUsdExchangeRate}>
                    <Form.Item name='value'>
                        <InputNumber precision={4} decimalSeparator=',' min={0} stringMode addonAfter={currencySign(Currency.Rub)} />
                    </Form.Item>
                </Form>
            </Modal>

            {currentBalance && openTransactions && (
                <Drawer
                    closable
                    destroyOnClose
                    title={`Операции "${currentBalance.userLogin}"`}
                    placement='right'
                    open={true}
                    onClose={() => setOpenTransactions(false)}
                    width={1200}
                >
                    <Transactions userId={currentBalance.userId} isViewMode={false} onRefresh={() => setRefreshRequired(true)} />
                </Drawer>
            )}

            {notificationContextHolder}
        </div>
    );
};

export default Balances;
