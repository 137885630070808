import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { notification } from 'antd';

import { Switch, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { LoadingOutlined, ReloadOutlined, SaveOutlined } from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';
import FormHeader from '@controls/form-header/form-header';

import { exception } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppDispatch } from '@store/hooks';

import { IRoute } from '@entities/route';

import { serverFetch } from '@src/core/server';

const ManageRoutes = () => {
    const [api, contextHolder] = notification.useNotification();

    const { journalId, journalNumber } = useParams();

    const d = useAppDispatch();

    const [routes, setRoutes] = useState<Array<IRoute>>([]);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!refreshRequired) return;

        setRefreshRequired(false);
        init();
    }, [refreshRequired]);

    const init = () => {
        setRoutes([]);

        setLoading(true);

        serverFetch(`shipmentjournals/${journalId}/route`, { method: 'GET' })
            .then((data) => {
                setRoutes(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения маршрута', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    };

    const onSave = () => {
        setLoading(true);

        serverFetch(`shipmentjournals/${journalId}/route`, { method: 'POST', bodyData: routes })
            .then(() => {
                setLoading(false);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка сохранения маршрута', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Сохранить',
                        key: 'save',
                        disabled: loading,
                        icon: <SaveOutlined />,
                        onClick: () => {
                            onSave();
                        },
                    },
                ]}
            />
        );
    };

    const renderTable = () => {
        const columns: ColumnsType<IRoute> = [
            {
                title: 'Город доставки',
                render: (_: any, record: IRoute) => {
                    return `${record.cityName}`;
                },
                width: 200,
            },
            {
                title: 'Транзит',
                align: 'center',
                width: 80,
                render: (_, record) => {
                    return (
                        record.isTransit && (
                            <>
                                <div className='transitWarehouseTag'>{record.transitWarehouseCode}</div>
                            </>
                        )
                    );
                },
            },
            {
                title: 'Прямая доставка',
                align: 'center',
                width: 140,
                render: (_, record) => {
                    return (
                        <Switch
                            size='small'
                            disabled={!record.transitWarehouseId}
                            checked={!record.isTransit}
                            onChange={(value) => {
                                let isTransit = !value;

                                let copyRoutes = [...routes];
                                let route = copyRoutes.find((r) => r.cityId === record.cityId);
                                if (route) {
                                    route.isTransit = isTransit;
                                    setRoutes(copyRoutes);
                                }
                            }}
                        />
                    );
                },
            },
            {
                title: ' ',
            },
        ];

        return (
            <Table
                rowKey='cityId'
                size='small'
                columns={columns}
                dataSource={routes}
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                pagination={false}
                scroll={{ y: `calc(100vh - 175px)` }}
            />
        );
    };

    return (
        <>
            <FormHeader title={`Управление маршрутом (${journalNumber})`} />
            {renderToolbar()}
            {renderTable()}

            {contextHolder}
        </>
    );
};

export default ManageRoutes;
