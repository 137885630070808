export enum ContactType {
    SystemSupport = 1,
}

export const enumLabel = (value?: ContactType) => {
    switch (value) {
        case ContactType.SystemSupport:
            return 'Техническая поддержка';
        default:
            return '';
    }
};