import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { saveAs } from 'file-saver';
import { useElementSize } from 'usehooks-ts';

import FormHeader from '@controls/form-header/form-header';

import { useAppDispatch, useAppSelector } from '@store/hooks';

import { Table, notification, Form, Drawer, Flex, Tooltip, Tag, Modal } from 'antd';
import { ColumnsType } from 'antd/es/table';
import type { GetRef } from 'antd';

import {
    ReloadOutlined,
    HistoryOutlined,
    SwapOutlined,
    CalendarOutlined,
    CheckCircleFilled,
    QuestionCircleOutlined,
    PlusOutlined,
    EditOutlined,
    FileExcelOutlined,
} from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';
import Transactions from '@controls/transactions/transactions';
import DepositingModal from '@src/components/balances/depositing-modal';
import TransferAccountsModal from '@src/components/balances/transfer-accounts-modal';
import DebtWriteOffModal from '@src/components/balances/debt-write-off-modal';
import BillModal from '@src/components/balances/bill-modal';

import { serverFetch } from '@src/core/server';

import { exception, securityRestriction, warning } from '@extensions/notification';
import { delayAction, toFinanceString, dataURItoBlob } from '@extensions/utils';
import { userLoaded, setFilter } from '@store/actions';

import { ITransactionParams } from '@entities/transaction-params';
import { IUserFilter } from '@entities/user-filter';
import { IUserBalanceDetail } from '@entities/user-balance-detail';
import { IConsigneeTariffSetting } from '@entities/consignee-tariff-setting';
import { IUser } from '@entities/user';
import { IUserAccount } from '@entities/user-account';
import { IUserSession } from '@entities/user-session';

import { UnitType } from '@enums/unit-type';
import { WeightType } from '@enums/weight-type';
import { Permission, hasPermission } from '@enums/permission';

import { TruckIcon, AirplaneIcon, BoxesIcon, ServicePayIcon, PayOffDebtIcon, WalletIcon } from '@icons/index';
import { UsdFilledIcon } from '@icons/filled/index';
import { IBill } from '@src/core/entities/bill';

const dayjs = require('dayjs');

var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

type TableRef = GetRef<typeof Table>;

const filterContext: string = 'BalanceDetails';

const BalanceDetails = () => {
    const initFilter: IUserFilter = { isActive: true, isArchived: false };

    const { userId } = useParams();

    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [containerRef, { width, height }] = useElementSize();

    const tableRef = useRef<TableRef>(null);
    const [modal, modalContextHolder] = Modal.useModal();

    const d = useAppDispatch();

    const [api, notificationContextHolder] = notification.useNotification();

    const filter = useAppSelector<IUserFilter>((s) => s.filters[filterContext]);

    const [showFilter, setShowFilter] = useState<boolean>(true);

    const [balances, setBalances] = useState<Array<IUserBalanceDetail>>([]);
    const [currentBalance, setCurrentBalance] = useState<IUserBalanceDetail>();
    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [lastSelectedKey, setLastSelectedKey] = useState<React.Key>();

    const [openDepositingDialog, setOpenDepositingDialog] = useState<boolean>(false);
    const [openDebtWriteOffDialog, setOpenDebtWriteOffDialog] = useState<boolean>(false);
    const [openTransferAccountsDialog, setOpenTransferAccountsDialog] = useState<boolean>(false);
    const [openBillDialog, setOpenBillDialog] = useState<boolean>(false);

    const [tariffSettings, setTariffSettings] = useState<Array<IConsigneeTariffSetting>>([]);
    const [userAccounts, setUserAccounts] = useState<Array<IUserAccount>>([]);

    const [openTransactions, setOpenTransactions] = useState<boolean>(false);
    const [user, setUser] = useState<IUser>();

    useEffect(() => {
        let cleanup = false;

        if (!refreshRequired) return;

        setSelectedIds([]);
        setCurrentBalance(undefined);
        setBalances([]);

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch(`users/${userId}/balancedetails`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения балансов', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch(`consignees/tariffsettings`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения настроек тарифа грузополучателей', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch(`users/${userId}`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения пользователя', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch(`users/${userId}/accounts`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения счетов пользователя', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setBalances(result[0][0]);
                setTariffSettings(result[0][1]);
                setUser(result[0][2]);
                setUserAccounts(result[0][3]);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    useEffect(() => {
        if (!loading && lastSelectedKey) {
            tableRef.current?.scrollTo({ key: lastSelectedKey });
            setLastSelectedKey(undefined);
        }
    }, [loading]);

    useEffect(() => {
        if (!filter) {
            d(setFilter(initFilter, filterContext));
            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            setLastSelectedKey(selectedRowKeys[0]);

            let entity = balances.find((o) => o.id == selectedRowKeys[0]);
            setCurrentBalance(entity);
        } else {
            setCurrentBalance(undefined);
        }
    };

    const onDepositin = (entity: ITransactionParams) => {
        if (!entity) return;

        serverFetch(`transactions/depositing`, { method: 'POST', bodyData: entity })
            .then(() => {
                setOpenDepositingDialog(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка внесения денежных средств', ex, () => d(userLoaded(undefined)));
            });
    };

    const onDebtWriteOff = (entity: ITransactionParams) => {
        if (!entity) return;

        serverFetch(`transactions/debtwriteoff`, { method: 'POST', bodyData: entity })
            .then(() => {
                setOpenDebtWriteOffDialog(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка внесения денежных средств', ex, () => d(userLoaded(undefined)));
            });
    };

    const onTransfer = (entity: ITransactionParams) => {
        if (!entity) return;

        serverFetch(`transactions/transfer`, { method: 'POST', bodyData: entity })
            .then(() => {
                setOpenTransferAccountsDialog(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка перевода между счетами', ex, () => d(userLoaded(undefined)));
            });
    };

    const onDebiting = () => {
        if (!userId || selectedIds.length <= 0) return;

        let entity: ITransactionParams = {
            billIds: selectedIds as Array<string>,
            userId: userId,
        };

        serverFetch(`transactions/debiting`, { method: 'POST', bodyData: entity })
            .then(() => {
                setRefreshRequired(true);
            })
            .catch((ex) => {
                setRefreshRequired(true);
                exception(api, 'Ошибка оплаты счетов', ex, () => d(userLoaded(undefined)));
            });
    };

    const onSaveBill = (entity: IBill) => {
        if (!entity) return;

        serverFetch(`bills`, { method: entity.id ? 'PUT' : 'POST', bodyData: entity })
            .then(() => {
                setOpenBillDialog(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка выставления счета', ex, () => d(userLoaded(undefined)));
            });
    };

    const onExport = () => {
        serverFetch(`users/${userId}/exportbalances`, { method: 'GET' })
            .then((data) => {
                let fileBody = `data:${data.contentType};base64,${data.fileContents}`;
                saveAs(dataURItoBlob(fileBody), data.fileDownloadName);
            })
            .catch((ex) => {
                exception(api, 'Ошибка выгрузки счетов', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Добавить счет',
                        key: 'addBill',
                        disabled: loading,
                        icon: <PlusOutlined />,
                        onClick: () => {
                            setOpenBillDialog(true);
                        },
                    },
                    {
                        label: 'Изменить счет',
                        key: 'editBill',
                        disabled: loading || !currentBalance,
                        icon: <EditOutlined />,
                        onClick: () => {
                            if (currentBalance?.boxGroupId) {
                                warning(api, 'Редактировать можно только счета на дополнительные расходы');
                                return;
                            }
                            setOpenBillDialog(true);
                        },
                    },
                    {
                        label: 'Оплатить счета',
                        disabled: loading || selectedIds.length <= 0,
                        key: 'pay',
                        type: 'primary',
                        icon: <ServicePayIcon />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.AllowRevertPayment)) {
                                securityRestriction(api, [Permission.AllowRevertPayment]);
                                return;
                            }

                            let total = 0;
                            selectedIds.map((id) => {
                                let bill = balances.find((b) => (b.id == id as string));
                                if (bill) {
                                    total += bill.totalPriceFinal || bill.totalPrice;
                                }
                            });

                            modal.confirm({
                                title: `Оплатить выбранные счета на сумму ${total}$?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'ОК',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onDebiting();
                                },
                            });
                        },
                    },
                    {
                        label: 'Погасить долг',
                        key: 'clodeDebt',
                        icon: <PayOffDebtIcon />,
                        onClick: () => {
                            setOpenDebtWriteOffDialog(true);
                        },
                    },
                    {
                        label: 'Внести денежные средства на счет',
                        key: 'wallet',
                        icon: <WalletIcon />,
                        onClick: () => {
                            setOpenDepositingDialog(true);
                        },
                    },

                    {
                        label: 'Перевод между счетами',
                        key: 'transfer',
                        icon: <SwapOutlined />,
                        onClick: () => {
                            setOpenTransferAccountsDialog(true);
                        },
                    },
                    {
                        label: 'Выгрузить в Excel',
                        key: 'exportToExcel',
                        disabled: loading,
                        icon: <FileExcelOutlined />,
                        onClick: () => onExport(),
                    },
                    {
                        label: 'История операций',
                        key: 'history',
                        icon: <HistoryOutlined />,
                        onClick: () => {
                            setOpenTransactions(true);
                        },
                    },
                ]}
                /*   farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setShowFilter(!showFilter);
                        },
                    },
                ]} */
            />
        );
    };

    const renderTable = () => {
        const columns: ColumnsType<IUserBalanceDetail> = [
            {
                title: '#',
                width: 80,
                align: 'center',
                render: (_, record) => {
                    return record.number;
                },
            },
            {
                title: 'Дата',
                width: 130,
                align: 'center',
                render: (_, record) => {
                    return record.billDate && dayjs.utc(record.billDate).local().format('DD.MM.YYYY');
                },
            },
            {
                title: 'Страна',
                dataIndex: 'countryName',
                width: 130,
            },
            {
                title: '',
                width: 250,
                onCell: () => ({
                    style: {
                        paddingTop: 0,
                    },
                }),
                render: (_, record) => {
                    if (!record.boxGroupId) return record.comment;

                    return (
                        <Form colon={false} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} style={{ marginBottom: 5 }}>
                            <Form.Item
                                label={
                                    <Tooltip title='Количество коробок'>
                                        <BoxesIcon />
                                    </Tooltip>
                                }
                                className='country-form-item'
                                style={{ fontWeight: 600 }}
                            >
                                {record.qty}
                            </Form.Item>
                            {record.loadingOn && (
                                <Form.Item
                                    label={
                                        <Tooltip title='Дата отгрузки'>
                                            <CalendarOutlined style={{ fontSize: 18 }} />
                                        </Tooltip>
                                    }
                                    className='country-form-item'
                                >
                                    <span style={{ backgroundColor: '#FFDD2D', padding: '0 4px' }}>
                                        {dayjs.utc(record.loadingOn).local().format('DD.MM.YYYY')}
                                    </span>
                                </Form.Item>
                            )}
                            {record.awbNumber && (
                                <Form.Item label='AWB' className='country-form-item'>
                                    <span style={{ backgroundColor: '#efefef', padding: '0 4px' }}>{record.awbNumber}</span>
                                </Form.Item>
                            )}
                            {record.cargoName && (
                                <Form.Item label={<AirplaneIcon style={{ fontSize: 14, marginLeft: 5 }} />} className='country-form-item'>
                                    <Tag color='var(--main-green)'>{record.cargoName}</Tag>
                                </Form.Item>
                            )}
                            {record.truckNumber && (
                                <Form.Item
                                    label={
                                        <Tooltip title='Номер машины'>
                                            <TruckIcon />
                                        </Tooltip>
                                    }
                                    className='country-form-item'
                                >
                                    <span>{record.isCombined ? record.combinedTruckNumbers : record.truckNumber}</span>
                                </Form.Item>
                            )}
                        </Form>
                    );
                },
            },
            {
                title: 'Вес Брутто',
                width: 120,
                align: 'center',
                render: (_: any, record) => {
                    if (record.unitType !== UnitType.Weight) return '';

                    let setting = tariffSettings.find((s) => s.consigneeId == record?.consigneeId && s.countryId == record?.countryId);

                    return (
                        record.grossWeight && (
                            <>
                                {record.grossWeight} <span style={{ fontWeight: 500 }}>кг</span>
                                {setting && setting.weightType == WeightType.Gross && (
                                    <Tooltip title='Расчетный вес'>
                                        <CheckCircleFilled style={{ color: '#FFDD2D', marginLeft: 5 }} />
                                    </Tooltip>
                                )}
                            </>
                        )
                    );
                },
            },
            {
                title: 'Объемный вес',
                width: 120,
                align: 'center',
                render: (_: any, record) => {
                    if (record.unitType !== UnitType.Weight) return '';

                    let setting = tariffSettings.find((s) => s.consigneeId == record?.consigneeId && s.countryId == record?.countryId);

                    return (
                        record.volumeWeight && (
                            <>
                                {record.volumeWeight} <span style={{ fontWeight: 500 }}>кг</span>
                                {(!setting || setting.weightType == WeightType.Volume) && (
                                    <Tooltip title='Расчетный вес'>
                                        <CheckCircleFilled style={{ color: '#FFDD2D', marginLeft: 5 }} />
                                    </Tooltip>
                                )}
                            </>
                        )
                    );
                },
            },
            {
                title: 'Тариф',
                width: 100,
                align: 'center',
                render: (_: any, record) => {
                    if (!record.boxGroupId) return;

                    return (
                        <div>
                            {record.flightPrice && (
                                <Flex align='center' gap='middle' className='flight-price'>
                                    <div>
                                        <Tooltip title='Перелет' placement='topRight'>
                                            <div>
                                                <AirplaneIcon style={{ fontSize: 12 }} />
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <div>{record.flightPrice}</div>
                                </Flex>
                            )}

                            <Flex align='center' gap='middle' className={record.cargoId ? 'flight-price' : 'truck-price'}>
                                <div>
                                    <Tooltip
                                        title={record.cargoId ? 'Транспортная доставка с перелетом' : 'Транспортная доставка'}
                                        placement='topRight'
                                    >
                                        <div>
                                            <TruckIcon style={{ fontSize: 12, color: record.cargoId ? 'var(--main-green)' : '' }} />
                                        </div>
                                    </Tooltip>
                                </div>
                                <div>{record.truckPrice}</div>
                            </Flex>
                        </div>
                    );
                },
            },
            {
                title: 'Без скидки ($)',
                width: 120,
                align: 'center',
                render: (_: any, record) => {
                    return (
                        <div style={{ color: record.totalPrice <= 0 ? 'red' : '' }}>
                            {record.totalPrice && toFinanceString(record.totalPrice, 2)}
                        </div>
                    );
                },
            },
            {
                title: 'Скидка ($)',
                width: 120,
                align: 'center',
                onCell: (record) => ({
                    style: {
                        fontWeight: 600,
                        background: '#d2dcd8',
                    },
                }),
                render: (_: any, record) => {
                    return (
                        <div style={{ color: record.totalPrice <= 0 ? 'red' : '' }}>
                            {record.totalPrice &&
                                record.totalPriceFinal &&
                                record.totalPrice > record.totalPriceFinal &&
                                toFinanceString(record.totalPrice - record.totalPriceFinal, 2)}
                        </div>
                    );
                },
            },
            {
                title: 'К оплате ($)',
                width: 120,
                align: 'center',
                onCell: (record) => ({
                    style: {
                        background: '#FFEE96',
                        fontWeight: 600,
                    },
                }),
                render: (_: any, record) => {
                    return (
                        <div style={{ color: record.totalPrice <= 0 ? 'red' : '' }}>
                            {toFinanceString(record.totalPriceFinal || record.totalPrice, 2)}
                        </div>
                    );
                },
            },
            {
                title: 'Баланс ($)',
                width: 120,
                align: 'center',
                onCell: (record) => ({
                    style: {
                        fontWeight: 600,
                    },
                }),
                render: (_: any, record) => {
                    return (
                        <div style={{ color: record.balance < 0 ? 'var(--main-red)' : 'var(--main-green)' }}>
                            {record.balance && toFinanceString(record.balance, 2)}
                        </div>
                    );
                },
            },
            {},
        ];

        return (
            <Table
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={balances}
                pagination={false}
                virtual={true}
                rowSelection={{
                    selectedRowKeys: selectedIds,
                    onChange: onSelectChange,
                    columnWidth: 35,
                }}
                onRow={(record) => {
                    return {
                        onClick: (event) => {
                            onSelectChange([record.id || '']);
                        },
                    };
                }}
                scroll={{ y: height + (showFilter ? 0 : 58) }}
            />
        );
    };

    let finalBalance = balances && balances.length > 0 ? balances[balances.length - 1].balance : 0;

    return (
        <div ref={containerRef} style={{ height: `calc(100vh - 237px)` }}>
            <FormHeader
                title={`Баланс "${user?.login}"`}
                container={
                    <Flex gap='small'>
                        <UsdFilledIcon style={{ marginRight: 2 }} />
                        <div style={{ fontSize: 18, fontWeight: 600, color: finalBalance < 0 ? 'var(--main-red)' : 'var(--main-green)' }}>
                            {toFinanceString(finalBalance, 2)}
                        </div>
                    </Flex>
                }
            />

            {renderToolbar()}
            {renderTable()}

            {user && openDepositingDialog && (
                <DepositingModal
                    userId={user.id || ''}
                    userLogin={user.login || ''}
                    onCancel={() => setOpenDepositingDialog(false)}
                    onSave={(value: ITransactionParams) => onDepositin(value)}
                    api={api}
                />
            )}

            {user && openDebtWriteOffDialog && (
                <DebtWriteOffModal
                    userId={user.id || ''}
                    userLogin={user.login || ''}
                    onCancel={() => setOpenDebtWriteOffDialog(false)}
                    onSave={(value: ITransactionParams) => onDebtWriteOff(value)}
                    api={api}
                />
            )}

            {user && openTransferAccountsDialog && (
                <TransferAccountsModal
                    userId={user.id || ''}
                    userLogin={user.login || ''}
                    accounts={userAccounts}
                    onCancel={() => setOpenTransferAccountsDialog(false)}
                    onSave={(value: ITransactionParams) => onTransfer(value)}
                    api={api}
                />
            )}

            {user && user.id && openTransactions && (
                <Drawer
                    closable
                    destroyOnClose
                    title={`Операции "${user.login}"`}
                    placement='right'
                    open={true}
                    onClose={() => setOpenTransactions(false)}
                    width={1200}
                >
                    <Transactions userId={user.id} isViewMode={false} onRefresh={() => setRefreshRequired(true)} />
                </Drawer>
            )}

            {user && openBillDialog && (
                <BillModal
                    userId={user.id || ''}
                    userLogin={user.login || ''}
                    billId={currentBalance?.id}
                    onCancel={() => setOpenBillDialog(false)}
                    onSave={(value: IBill) => onSaveBill(value)}
                    api={api}
                />
            )}

            {notificationContextHolder}
            {modalContextHolder}
        </div>
    );
};

export default BalanceDetails;
