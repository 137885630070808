export enum JournalStatus {
    New = 1,
    InProcess = 2,
    Completed = 3,
    Cancelled = 4,
}

export const enumLabel = (value: JournalStatus) => {
    switch (value) {
        case JournalStatus.New:
            return 'Новый';
        case JournalStatus.InProcess:
            return 'В процессе';
        case JournalStatus.Completed:
            return 'Завершен';
        case JournalStatus.Cancelled:
            return 'Отменен';
        default:
            return '';
    }
};
