import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { v4 as uuid } from 'uuid';

import 'core-js/actual/array/group';

const borderColor = '#90e5fc';

const TableRow = ({ item }) => {
    const styles = StyleSheet.create({
        row: {
            flexDirection: 'row',
            alignItems: 'right',
            textAlign: 'center',
        },

        rowItem: {
            flexDirection: 'row',
            alignItems: 'right',
            textAlign: 'center',
            height: 15,
            maxHeight: 15,
        },

        rowSign: {
            flexDirection: 'row',
            alignItems: 'right',
            textAlign: 'center',
            height: 25,
            maxHeight: 25,
        },

        consigneeCode: {
            paddingTop: 2,
            width: 40,
            borderRightColor: borderColor,
            borderRightWidth: 1,
            borderTopColor: borderColor,
            borderTopWidth: 1,
        },

        marking: {
            paddingTop: 2,
            width: 200,
            borderRightColor: borderColor,
            borderRightWidth: 1,
            borderTopColor: borderColor,
            borderTopWidth: 1,
        },
        phone: {
            paddingTop: 2,
            width: 100,
            borderRightColor: borderColor,
            borderRightWidth: 1,
            borderTopColor: borderColor,
            borderTopWidth: 1,
        },
        country: {
            paddingTop: 2,
            width: 100,
            borderRightColor: borderColor,
            borderRightWidth: 1,
            borderTopColor: borderColor,
            borderTopWidth: 1,
        },
        qty: {
            paddingTop: 2,
            width: 72,
            borderTopColor: borderColor,
            borderTopWidth: 1,
        },

        driverSign: {
            paddingTop: 4,
            paddingLeft: 4,
            textAlign: 'left',
            width: 200,
            borderRightColor: borderColor,
            borderRightWidth: 1,
            borderTopColor: borderColor,
            borderTopWidth: 1,
            borderBottomColor: borderColor,
            borderBottomWidth: 1,
        },

        consigneeSign: {
            paddingTop: 4,
            paddingLeft: 4,
            width: 200,
            textAlign: 'left',
            borderTopColor: borderColor,
            borderTopWidth: 1,
            borderRightColor: borderColor,
            borderRightWidth: 1,
            borderBottomColor: borderColor,
            borderBottomWidth: 1,
        },

        signDate: {
            paddingTop: 4,
            paddingLeft: 4,
            width: 112,
            textAlign: 'left',
            borderTopColor: borderColor,
            borderTopWidth: 1,
            borderBottomColor: borderColor,
            borderBottomWidth: 1,
        },
    });

    const rows = [];
    rows.push(
        <View style={styles.row} key={item.consigneeCode}>
            <Text style={styles.consigneeCode}>{item.consigneeCode}</Text>
            <Text style={styles.marking}>{item.markingCodes}</Text>
            <Text style={styles.phone}>{item.phone}</Text>
            <View>
                {item.boxGroups.map((b) => {
                    return (
                        <View style={styles.rowItem} key={uuid()}>
                            <Text style={styles.country}>{b.countryName}</Text>
                            <Text style={styles.qty}>{b.qty}</Text>
                        </View>
                    );
                })}
            </View>
        </View>
    );

    rows.push(
        <View style={styles.rowSign} key={uuid()}>
            <Text style={styles.driverSign}>Доставил:</Text>
            <Text style={styles.consigneeSign}>Принял:</Text>
            <Text style={styles.signDate}>Дата:</Text>
        </View>
    );

    return <Fragment>{rows}</Fragment>;
};

export default TableRow;
