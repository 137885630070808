import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Card, Steps, Row, Col, Tooltip, notification } from 'antd';
import { CarOutlined, EyeFilled, CheckCircleOutlined } from '@ant-design/icons';

import FormHeader from '@controls/form-header/form-header';

import { exception } from '@extensions/notification';
import { userLoaded } from '@store/actions';
import { useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { IUser } from '@entities/user';
import { IWaypoint } from '@entities/waypoint';
import { ITrackpoint } from '@entities/trackpoint';
import { IPoint } from '@entities/point';
import { ITruckOrder } from '@entities/truck-order';
import { ITransitWarehouse } from '@entities/transit-warehouse';
import { IImportDetails } from '@entities/import-details';
import { IDeliveryDetails } from '@entities/delivery-details';

import { TruckStatus } from '@enums/truck-status';
import { WaypointType } from '@enums/waypoint-type';
import { DeliveryOption, enumLabel as deliveryOptionLabel } from '@enums/delivery-option';

import { TruckIcon, AirplaneIcon, PickupIcon } from '@icons/index';

import './order.css';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const Order = () => {
    const [api, notificationContextHolder] = notification.useNotification();

    const d = useAppDispatch();

    const { userId, orderId } = useParams();

    const [importDetails, setImportDetails] = useState<IImportDetails>();
    const [deliveryDetails, setDeliveryDetails] = useState<IDeliveryDetails>();
    const [loading, setLoading] = useState<boolean>(false);
    const [user, setUser] = useState<IUser>();

    useEffect(() => {
        let cleanup = false;

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch(`orders/${orderId}/import`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения заказа', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch(`orders/${orderId}/delivery`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения машин', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch(`users/info/${userId}`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения пользователя', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setImportDetails(result[0][0]);
                setDeliveryDetails(result[0][1]);
                setUser(result[0][2]);

                setLoading(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, []);

    const getRouteSheet = (waypoints: Array<IWaypoint>, trackpoints: Array<ITrackpoint>, truckNumber: string | undefined) => {
        if (!waypoints || waypoints.length <= 0) return;

        let trackpointsBuff = [...trackpoints];
        let points: Array<IPoint> = [];
        let index = -1;
        let currentWaypoint: IWaypoint | undefined = undefined;

        let extraTrackpoints = trackpointsBuff.filter((t) => t.isExtraPoint);

        let waypointsBuff: Array<IWaypoint> = [];

        waypoints.map((w) => {
            let extraPoints = extraTrackpoints.filter((t) => t.waypointId === w.id);
            if (extraPoints.length > 0) {
                extraPoints.map((p) => {
                    waypointsBuff.push({ ...w, type: WaypointType.Onway, extraTrackpointId: p.id });
                });
            }

            waypointsBuff.push(w);
        });

        waypointsBuff.map((w) => {
            let title: any = '';
            if (!w.type || w.type === WaypointType.Onway) {
                title = <CarOutlined />;
            } else if (w.type === WaypointType.Destination) {
                title = w.name;
            } else {
                title = w.name;
            }

            let point: IPoint = { id: w.id, title: title, type: w.type };

            let trackpoint = w.extraTrackpointId
                ? trackpoints.find((t) => t.id === w.extraTrackpointId)
                : trackpoints.find((t) => !t.isExtraPoint && t.waypointId === w.id);

            if (trackpoint) {
                point.description = trackpoint.comment;
                point.subTitle = trackpoint.date && dayjs.utc(trackpoint.date).local().format('DD.MM.YYYY HH:mm');
            }

            points.push(point);
        });

        let lastTrackPoint: any = trackpointsBuff.pop();

        if (lastTrackPoint) {
            currentWaypoint = lastTrackPoint.isExtraPoint
                ? waypointsBuff.find((w) => w.extraTrackpointId === lastTrackPoint?.id)
                : waypointsBuff.find((w) => !w.extraTrackpointId && w.id === lastTrackPoint?.waypointId);

            if (currentWaypoint) {
                index = waypointsBuff.indexOf(currentWaypoint);
            }
        }

        return {
            index: index,
            points: points,
            currentWaypoint: currentWaypoint,
        };
    };

    const renderDeliveryOptionIcon = (option: DeliveryOption) => {
        switch (option) {
            case DeliveryOption.Truck:
                return (
                    <Tooltip placement='top' title={deliveryOptionLabel(DeliveryOption.Truck)}>
                        <TruckIcon className='delivery-option deliveryOptionBackground' />
                    </Tooltip>
                );
            case DeliveryOption.Pickup:
                return (
                    <Tooltip placement='top' title={deliveryOptionLabel(DeliveryOption.Pickup)}>
                        <PickupIcon className='delivery-option deliveryOptionBackground' />
                    </Tooltip>
                );
            case DeliveryOption.Airplane:
                return (
                    <Tooltip placement='top' title={deliveryOptionLabel(DeliveryOption.Airplane)}>
                        <AirplaneIcon className='delivery-option deliveryOptionBackground' />
                    </Tooltip>
                );
            default:
                return '';
        }
    };

    const renderImportCard = () => {
        let data = getRouteSheet(importDetails?.waypoints || [], importDetails?.trackpoints || [], importDetails?.truck?.number);

        return (
            <Card bordered={true} className='order-card'>
                <Row>
                    <Col span={12}>
                        {renderDeliveryOptionIcon(DeliveryOption.Truck)}

                        <h1 className='order-form-header' style={{ marginTop: 0 }}>
                            Общая информация
                        </h1>

                        <Form labelAlign='left' colon={false} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                            <Form.Item label='Отгрузка' className='order-form-item'>
                                <div className='order-form-loading'>
                                    {importDetails?.order?.loadingOn &&
                                        dayjs.utc(importDetails?.order.loadingOn).local().format('DD.MM.YYYY')}
                                </div>
                            </Form.Item>
                            <Form.Item className='order-form-item' label='Маркировка'>
                                <div className='order-form-value'>{importDetails?.order?.markingCode}</div>
                            </Form.Item>
                            <Form.Item label='Страна' className='order-form-item'>
                                <div className='order-form-value'>{importDetails?.order?.countryName}</div>
                            </Form.Item>
                            <Form.Item label='AWB' className='order-form-item'>
                                <div className='order-form-awb'>{importDetails?.order?.awbNumber}</div>
                            </Form.Item>
                            <Form.Item label='Количество' className='order-form-item'>
                                <div className='order-form-value'>{importDetails?.order?.boxQty}</div>
                            </Form.Item>
                        </Form>

                        {importDetails?.truck?.status !== TruckStatus.New && (
                            <>
                                <h1 className='order-form-header'>Отгружено</h1>
                                <Form labelAlign='left' colon={false} labelCol={{ span: 8 }} wrapperCol={{ span: 8 }}>
                                    {importDetails?.truck?.number && (
                                        <Form.Item label='Номер машины' className='order-form-item'>
                                            <div className='order-form-value'>
                                                {importDetails?.truck?.number}
                                                {importDetails?.truck?.trackingUrl && (
                                                    <Tooltip placement='topRight' title='Показать на карте'>
                                                        <a
                                                            className='trackingUrl'
                                                            href={importDetails?.truck?.trackingUrl}
                                                            target='_blank'
                                                            rel='noreferrer'
                                                        >
                                                            <EyeFilled />
                                                        </a>
                                                    </Tooltip>
                                                )}
                                            </div>
                                        </Form.Item>
                                    )}
                                    <Form.Item label='Количество' className='order-form-item'>
                                        <div className='order-form-value'>{importDetails?.order?.boxQty}</div>
                                    </Form.Item>
                                </Form>
                            </>
                        )}

                        {data && data.currentWaypoint && data.currentWaypoint.type === WaypointType.Destination && (
                            <>
                                <h1 className='order-form-header'>Принято на склад</h1>
                                <Form labelAlign='left' colon={false} labelCol={{ span: 8 }} wrapperCol={{ span: 8 }}>
                                    <Form.Item label='Количество' className='order-form-item'>
                                        <span
                                            className='order-form-value'
                                            style={{
                                                fontWeight: 600,
                                                color:
                                                    importDetails?.order?.acceptedBoxQty === importDetails?.order?.actualBoxQty
                                                        ? '#228B22'
                                                        : 'red',
                                            }}
                                        >
                                            {importDetails?.order?.acceptedBoxQty}
                                        </span>
                                        <span> / </span>
                                        <span className='order-form-value'>{importDetails?.order?.actualBoxQty}</span>
                                    </Form.Item>
                                    <Form.Item label='Остаток' className='order-form-item'>
                                        <span className='order-form-value'>
                                            {(importDetails?.order?.acceptedBoxQty || 0) - (importDetails?.order?.shippedBoxQty || 0)}
                                        </span>
                                    </Form.Item>
                                </Form>
                            </>
                        )}
                    </Col>
                    <Col span={7} offset={3}>
                        {data && <Steps progressDot direction='vertical' current={data.index} items={data.points} />}
                    </Col>
                </Row>
            </Card>
        );
    };

    const renderDeliveryCard = () => {
        return (
            <>
                {deliveryDetails?.transitOrders &&
                    deliveryDetails?.transitOrders.length > 0 &&
                    renderTransit(deliveryDetails.transitOrders, deliveryDetails.transitWarehouse)}

                {deliveryDetails?.deliveryOrders &&
                    deliveryDetails?.deliveryOrders.length > 0 &&
                    renderDelivery(deliveryDetails.deliveryOrders)}
            </>
        );
    };
    const renderTransit = (transitOrders: Array<ITruckOrder>, warehouse: ITransitWarehouse) => {
        return (
            <Card key='transitWarehouse' bordered={true} className='order-card delivery' style={{ borderColor: 'var(--primary-color)' }}>
                <Row>
                    <Col span={24}>
                        <h1 className='order-form-header' style={{ marginTop: 0, marginBottom: 20 }}>
                            Транзит
                        </h1>

                        {renderTruck(transitOrders, true)}

                        <h1 className='transit-warehouse' style={{ marginTop: 0 }}>
                            Принято на склад "{warehouse.cityName}"
                        </h1>
                        <Form labelAlign='left' colon={false} labelCol={{ span: 8 }} wrapperCol={{ span: 8 }}>
                            <Form.Item label='Количество' className='order-form-item'>
                                <span
                                    className='order-form-value'
                                    style={{
                                        fontWeight: 600,
                                        color: warehouse?.acceptedBoxQty === warehouse?.boxQty ? '#228B22' : 'red',
                                    }}
                                >
                                    {warehouse?.acceptedBoxQty}
                                </span>
                                <span> / </span>
                                <span className='order-form-value'>{warehouse?.boxQty}</span>
                            </Form.Item>
                            <Form.Item label='Остаток' className='order-form-item'>
                                <span className='order-form-value'>
                                    {(warehouse?.acceptedBoxQty || 0) - (warehouse?.shippedBoxQty || 0)}
                                </span>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Card>
        );
    };

    const renderDelivery = (deliveryOrders: Array<ITruckOrder>) => {
        return (
            <Card key='delivery' bordered={true} className='order-card delivery'>
                <Row>
                    <Col span={24}>
                        <h1 className='order-form-header' style={{ marginTop: 0, marginBottom: 20 }}>
                            Доставка
                        </h1>

                        {renderTruck(deliveryOrders, false)}
                    </Col>
                </Row>
            </Card>
        );
    };

    const renderTruck = (truckOrders: Array<ITruckOrder>, isTransit: boolean) => {
        return truckOrders.map((order) => {
            let data = getRouteSheet(order?.waypoints || [], order?.trackpoints || [], order?.truck?.number);

            return (
                <Card
                    key={order.truck?.id}
                    bordered={true}
                    className='order-card'
                    bodyStyle={{ paddingBottom: 0 }}
                    style={{ marginBottom: 20 }}
                >
                    <Row>
                        <Col span={12}>
                            {renderDeliveryOptionIcon(order.deliveryOption)}

                            <Form labelAlign='left' colon={false} labelCol={{ span: 10 }} wrapperCol={{ span: 12 }}>
                                <Form.Item label='Количество' className='order-form-item'>
                                    <div className='order-form-value'>{order?.boxQty}</div>
                                </Form.Item>
                                {order.deliveryOption === DeliveryOption.Truck && (
                                    <Form.Item label='Телефон водителя' className='order-form-item'>
                                        <div className='order-form-value'>{order?.truck?.driverPhone}</div>
                                    </Form.Item>
                                )}
                                {order?.truck?.number && (
                                    <Form.Item label='Номер машины' className='order-form-item'>
                                        <div className='order-form-value'>
                                            {order?.truck?.number}
                                            {order?.truck?.trackingUrl && (
                                                <Tooltip placement='topRight' title='Показать на карте'>
                                                    <a
                                                        className='trackingUrl'
                                                        href={order?.truck?.trackingUrl}
                                                        target='_blank'
                                                        rel='noreferrer'
                                                    >
                                                        <EyeFilled />
                                                    </a>
                                                </Tooltip>
                                            )}
                                        </div>
                                    </Form.Item>
                                )}
                            </Form>
                        </Col>
                        <Col span={7} offset={4}>
                            {data && <Steps progressDot direction='vertical' current={data.index} items={data.points} />}
                        </Col>
                        {order.isReceived && (
                            <Col>
                                <Tooltip title='Груз получен' color={'#389e0d'}>
                                    <CheckCircleOutlined className='received' />
                                </Tooltip>
                            </Col>
                        )}
                    </Row>
                </Card>
            );
        });
    };

    return (
        <>
            <FormHeader title={`Детали партии`} />
            <Row style={{ marginTop: 10 }}>
                <Col span={12}>{renderImportCard()}</Col>
                <Col span={11}>{renderDeliveryCard()}</Col>
            </Row>

            {notificationContextHolder}
        </>
    );
};

export default Order;
