import React from 'react';
import { Font, Document, Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import Roboto from '@assets/roboto/Roboto-Regular.ttf';
import RobotoBold from '@assets/roboto/Roboto-Bold.ttf';

var JsBarcode = require('jsbarcode');

Font.register({
    family: 'Roboto',
    fonts: [{ src: Roboto }, { src: RobotoBold, fontWeight: 600 }],
    format: 'truetype',
});

// Create styles
const styles = StyleSheet.create({
    page: {
        fontFamily: 'Roboto',
        color: 'black',
        border: '1px solid black',
        borderRadius: 10,
        textAlign: 'center',
        padding: 10,
    },
    container: {
        display: 'flex' /* or inline-flex */,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },

    cityContainer: {
        display: 'flex' /* or inline-flex */,
        justifyContent: 'center',
        alignItems: 'left',
        width: 180,
    },
    city: {
        textAlign: 'left',
        fontSize: 22,
        fontWeight: 600,
        overflowWrap: 'break-word',
    },

    userCode: {
        textAlign: 'left',
        fontSize: 48,
        fontWeight: 'bold',
    },

    userInfo: {
        textAlign: 'left',
        marginTop: 5,
        fontSize: 16,
    },
    userPhone: {
        textAlign: 'left',
        marginTop: 5,
        fontSize: 16,
    },
    country: {
        position: 'absolute',
        bottom: 10,
        left: 10,
        fontSize: 16,
    },
    barcode: {
        width: 240,
        position: 'absolute',
        left: 25,
        bottom: 25,
        height: 65,
    },
    brand: {
        position: 'absolute',
        bottom: 10,
        right: 10,
        fontSize: 16,
        fontWeight: 600,
    },

    viewer: {
        width: window.innerWidth, //the pdf viewer will take up all of the width and height
        height: window.innerHeight,
    },
});

const BarcodePdf = ({ ...props }) => {
    const { labels } = props;

    function textToBase64Barcode(code) {
        var canvas = document.createElement('canvas');
        JsBarcode(canvas, code, { format: 'CODE128' });
        return canvas.toDataURL('image/png');
    }

    return (
        <Document>
            {labels &&
                labels.map((e) => {
                    let userInfo = e.consignee?.fullName ? `${e.consignee?.fullName} / ${e.markingCode}` : e.markingCode;
                    return (
                        <Page key={e.box.id} size='A7' style={styles.page} orientation={'landscape'}>
                            <View>
                                <View style={styles.container}>
                                    <View style={styles.cityContainer}>
                                        <Text style={styles.city}>{e.consignee?.cityName}</Text>
                                    </View>
                                    <View>
                                        <Text style={styles.userCode}>{e.consignee?.code}</Text>
                                    </View>
                                </View>
                            </View>
                            <Text style={styles.userInfo}>{userInfo}</Text>
                            <Text style={styles.userPhone}>{e.consignee && !e.consignee.hidePhone ? e.consignee.phone : ' '}</Text>
                            <Image style={styles.barcode} src={textToBase64Barcode(e.box.number)}></Image>

                            <Text style={styles.country}>{e.countryName}</Text>
                            <Text style={styles.brand}>FRESH LINE</Text>
                        </Page>
                    );
                })}
        </Document>
    );
};
export default BarcodePdf;
