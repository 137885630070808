import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, ColorPicker, Input, InputNumber, Form, Space, Divider, notification } from 'antd';

import FormHeader from '@controls/form-header/form-header';

import { exception } from '@extensions/notification';
import { NoColor } from '@src/core/types';
import { Permission, hasPermission } from '@enums/permission';

import { userLoaded } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { IUserSession } from '@entities/user-session';
import { IPackageTemplate } from '@entities/package-template';

const PackageTemplate = () => {
    const { id } = useParams();

    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [entity, setEntity] = useState<IPackageTemplate>({
        id: undefined,
        name: undefined,
        qtyPerPallet: undefined,
        color: NoColor,
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [managePackageTemplate] = useState<boolean>(hasPermission(userSession.permissions, Permission.ManagePackageTemplates));

    useEffect(() => {
        initData();
    }, []);

    const initData = () => {
        if (!id) {
            return;
        }

        setLoading(true);

        serverFetch(`packagetemplates/${id}`, { method: 'GET' })
            .then((data) => {
                setEntity(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения упаковки', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    };

    const onSave = () => {
        setLoading(true);

        serverFetch(`packagetemplates`, { method: id ? 'PUT' : 'POST', bodyData: entity })
            .then(() => {
                setLoading(false);
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка сохранения упаковки', ex, () => d(userLoaded(undefined)));
            });
    };

    return (
        <>
            <FormHeader title={`${id ? (!managePackageTemplate ? 'Информация об упаковке' : 'Изменить упаковку') : 'Добавить упаковку'}`} />
            {!loading && (
                <Form colon={false} labelCol={{ span: 3 }} wrapperCol={{ span: 8 }} onFinish={onSave}>
                    <Form.Item
                        initialValue={entity?.name}
                        required
                        label='Название'
                        name='name'
                        rules={[{ required: true, message: 'Укажите название упаковки' }]}
                    >
                        <Input
                            disabled={!managePackageTemplate}
                            autoFocus
                            onChange={(data) => {
                                setEntity({ ...entity, name: data.target.value });
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        initialValue={entity?.qtyPerPallet}
                        required
                        label='На паллете'
                        name='qtyPerPallet'
                        rules={[{ required: true, message: 'Укажите количество упаковок на паллете' }]}
                    >
                        <InputNumber
                            disabled={!managePackageTemplate}
                            addonAfter='шт'
                            onChange={(value: number | null) => {
                                setEntity({ ...entity, qtyPerPallet: value });
                            }}
                        />
                    </Form.Item>
                    <Form.Item initialValue={entity?.length} label='Длина' name='length'>
                        <InputNumber
                            disabled={!managePackageTemplate}
                            addonAfter='см'
                            onChange={(value: number | null) => {
                                setEntity({ ...entity, length: value });
                            }}
                        />
                    </Form.Item>
                    <Form.Item initialValue={entity?.width} label='Ширина' name='width'>
                        <InputNumber
                            disabled={!managePackageTemplate}
                            addonAfter='см'
                            onChange={(value: number | null) => {
                                setEntity({ ...entity, width: value });
                            }}
                        />
                    </Form.Item>
                    <Form.Item initialValue={entity?.height} label='Высота' name='height'>
                        <InputNumber
                            disabled={!managePackageTemplate}
                            addonAfter='см'
                            onChange={(value: number | null) => {
                                setEntity({ ...entity, height: value });
                            }}
                        />
                    </Form.Item>
                    <Form.Item initialValue={entity.color || ''} required label='Цвет' name='color'>
                        <ColorPicker
                            allowClear
                            styles={{ popupOverlayInner: { width: 492 } }}
                            //value={value}
                            onChangeComplete={(color) => {
                                setEntity({ ...entity, color: color.toHexString() });
                            }}
                            presets={[
                                {
                                    label: 'Рекомендованные',
                                    colors: [
                                        '#F5222D',
                                        '#FA8C16',
                                        '#FADB14',
                                        '#8BBB11',
                                        '#52C41A',
                                        '#13A8A8',
                                        '#1677FF',
                                        '#2F54EB',
                                        '#722ED1',
                                        '#EB2F96',
                                        '#F5222D4D',
                                        '#FA8C164D',
                                        '#FADB144D',
                                        '#8BBB114D',
                                        '#52C41A4D',
                                        '#13A8A84D',
                                        '#1677FF4D',
                                        '#2F54EB4D',
                                        '#722ED14D',
                                        '#EB2F964D',
                                    ],
                                },
                            ]}
                            panelRender={(_, { components: { Picker, Presets } }) => (
                                <div
                                    className='custom-panel'
                                    style={{
                                        display: 'flex',
                                        width: 468,
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: 1,
                                        }}
                                    >
                                        <Presets />
                                    </div>
                                    <Divider
                                        type='vertical'
                                        style={{
                                            height: 'auto',
                                        }}
                                    />
                                    <div
                                        style={{
                                            width: 234,
                                        }}
                                    >
                                        <Picker />
                                    </div>
                                </div>
                            )}
                        />
                    </Form.Item>
                    <Form.Item label='' wrapperCol={{ offset: 3, span: 8 }}>
                        <Space size={'small'} style={{ float: 'right' }}>
                            <Button type='text' onClick={() => navigate(-1)}>
                                Отменить
                            </Button>
                            {managePackageTemplate && (
                                <Button type='primary' htmlType='submit' loading={loading}>
                                    Сохранить
                                </Button>
                            )}
                        </Space>
                    </Form.Item>
                </Form>
            )}

            {contextHolder}
        </>
    );
};

export default PackageTemplate;
