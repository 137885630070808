import React from 'react';
import Icon from '@ant-design/icons';

const ServicePayIcon = ({ ...props }) => {
    return <Icon component={svg} style={{ fontSize: 18 , ...props.style }} />;
};
export default ServicePayIcon;

const svg = ({ ...props }) => (
    <svg fill='currentColor' {...props} version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
        <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
        <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round'></g>
        <g id='SVGRepo_iconCarrier'>
            <g>
                <g>
                    <path d='M371.856,89.284v90.674H140.144V89.284H88.497V512h335.005V89.284H371.856z M199.229,471.326h-59.084v-59.086h59.084 V471.326z M199.229,385.011h-59.084v-59.086h59.084V385.011z M285.542,471.326h-59.084v-59.086h59.084V471.326z M285.542,385.011 h-59.084v-59.086h59.084V385.011z M371.856,471.326h-59.084v-59.086h59.084V471.326z M371.856,385.011h-59.084v-59.086h59.084 V385.011z M371.856,298.697H140.144v-78.183h231.712V298.697z'></path>
                </g>
            </g>
            <g>
                <g>
                    <polygon points='318.484,19.395 287.243,0 256,19.395 224.758,0 193.516,19.395 170.561,5.145 170.561,149.539 341.439,149.539 341.439,5.145 '></polygon>
                </g>
            </g>
        </g>
    </svg>
);
