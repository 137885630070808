import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Modal, Select, Table, Button, Col, Row, Input, Tooltip, Form, Space } from 'antd';
import { LoadingOutlined, ReloadOutlined, PrinterOutlined, SwapOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import { notification } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { usePDF } from '@react-pdf/renderer';
import printJS from 'print-js';

import Toolbar from '@controls/toolbar/toolbar';
import FormHeader from '@controls/form-header/form-header';
import PrintProcessing from '@controls/print-processing';

import { exception, securityRestriction, error } from '@extensions/notification';
import { userLoaded } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { serverFetch } from '@src/core/server';
import BarcodePdf from '@print-forms/barcode-pdf';

import { IConsignmentLabel } from '@entities/consignment-label';
import { IBox } from '@entities/box';
import { IBoxGroup } from '@entities/box-group';
import { IMarking } from '@entities/marking';
import { IUserSession } from '@entities/user-session';

import { Permission, hasPermission } from '@enums/permission';

const Remaining = () => {
    const { TextArea } = Input;

    const { markingId, countryId, truckId } = useParams();
    const [api, contextHolder] = notification.useNotification();

    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const d = useAppDispatch();

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [boxGroup, setBoxGroup] = useState<IBoxGroup>();
    const [boxes, setBoxes] = useState<Array<IBox>>();
    const [loading, setLoading] = useState<boolean>(false);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [openTransfer, setOpenTransfer] = useState<boolean>(false);
    const [newMarkingId, setNewMarkingId] = useState<string | undefined>(markingId);

    const [markings, setMarkings] = useState<Array<IMarking>>([]);
    const [printData, setPrintData] = useState<IConsignmentLabel | undefined>();
    const [startPrint, setStartPrint] = useState<boolean>(false);

    const [pdfInstance, updatePdf] = usePDF();

    useEffect(() => {
        let cleanup = false;

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch('markings', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения маркировок', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            await loadBoxes();

            Promise.all([promises]).then((result) => {
                if (cleanup) return;
                setMarkings(result[0][0]);

                setLoading(false);
            });
        };

        fetchData();
        return () => {
            cleanup = true;
        };
    }, []);

    useEffect(() => {
        if (!refreshRequired) return;

        setRefreshRequired(false);
        loadBoxes();
    }, [refreshRequired]);

    const loadBoxes = async () => {
        setLoading(true);

        serverFetch(`remainings/boxes`, {
            method: 'GET',
            queryParams: { markingId: markingId, countryId: countryId, truckIds: [truckId] },
        })
            .then((data) => {
                setBoxes(data);

                let item = data[0] as IBoxGroup;
                if (item) {
                    setBoxGroup(item);
                }

                setLoading(false);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка получения деталей груза', ex, () => d(userLoaded(undefined)));
            });
    };

    useEffect(() => {
        if (printData) {
            updatePdf(<BarcodePdf labels={printData} />);
            setStartPrint(true);
        }
    }, [printData]);

    useEffect(() => {
        if (startPrint && !pdfInstance.loading && pdfInstance.blob) {
            setStartPrint(false);
            setPrintData(undefined);

            const blobURL = URL.createObjectURL(pdfInstance.blob);
            printJS(blobURL);
        }
    }, [startPrint, pdfInstance]);

    const getPrintData = (ids: Array<string>) => {
        serverFetch(`warehouse/box/print`, { method: 'POST', bodyData: ids })
            .then((data) => {
                setPrintData(data);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения груза', ex, () => d(userLoaded(undefined)));
            });
    };

    const onTransferBox = () => {
        if (!markingId || !boxGroup) return;

        let data = {
            markingId: markingId,
            countryId: countryId,
            boxIds: selectedRowKeys,
            newMarkingId: newMarkingId,
            truckId: truckId,
        };

        serverFetch(`remainings/transfer`, { method: 'POST', bodyData: data })
            .then(() => {
                setNewMarkingId(undefined);
                setRefreshRequired(true);
                setOpenTransfer(false);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка перемещения коробок', ex, () => d(userLoaded(undefined)));
            });
    };

    const onDeleteBox = (boxId: string) => {
        setLoading(true);

        serverFetch(`warehouse/box/${boxId}`, { method: 'DELETE' })
            .then(() => {
                setLoading(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка удаления коробки', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Перенести на другую маркировку',
                        key: 'transfer',
                        disabled: selectedRowKeys.length <= 0,
                        icon: <SwapOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageWarehouse)) {
                                securityRestriction(api, [Permission.ManageWarehouse]);
                                return;
                            }

                            if (!boxGroup?.canTransfer) {
                                error(api, 'Нельзя переместить коробки пока не завершится приемка партии');
                                return;
                            }

                            setOpenTransfer(true);
                        },
                    },
                    {
                        label: 'Печать этикеток',
                        key: 'print',
                        disabled: selectedRowKeys.length <= 0,
                        icon: <PrinterOutlined />,
                        onClick: () => {
                            if (
                                !hasPermission(userSession.permissions, Permission.ManageWarehouse) &&
                                !hasPermission(userSession.permissions, Permission.PrintDocuments)
                            ) {
                                securityRestriction(api, [Permission.ManageWarehouse, Permission.PrintDocuments]);
                                return;
                            }

                            getPrintData(selectedRowKeys as Array<string>);
                        },
                    },
                ]}
            />
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const renderTable = () => {
        const columns: ColumnsType<IBox> = [
            {
                title: 'Номер',
                dataIndex: 'number',
                width: 200,
            },
            {
                title: 'Страна',
                render: () => {
                    return boxGroup?.countryName;
                },
                width: 180,
            },
            { title: ' ' },
            {
                title: ' ',
                key: 'boxAction',
                align: 'center',
                width: 80,
                render: (_: any, record: IBox) => {
                    return (
                        <Space>
                            <Tooltip placement='topRight' title='Удалить коробку'>
                                <Button
                                    icon={<DeleteOutlined />}
                                    size={'small'}
                                    onClick={() => {
                                        if (!hasPermission(userSession.permissions, Permission.DeleteWarehouseBox)) {
                                            securityRestriction(api, [Permission.DeleteWarehouseBox]);
                                            return;
                                        }

                                        Modal.confirm({
                                            title: `Удалить коробку "${record.number}"?`,
                                            okType: 'primary',
                                            icon: <QuestionCircleOutlined />,
                                            okText: 'Удалить',
                                            cancelText: 'Отмена',
                                            onOk: () => {
                                                onDeleteBox(record.id);
                                            },
                                        });
                                    }}
                                />
                            </Tooltip>

                            <Tooltip placement='topRight' title='Печать этикетки'>
                                <Button
                                    icon={<PrinterOutlined />}
                                    size={'small'}
                                    onClick={() => {
                                        if (
                                            !hasPermission(userSession.permissions, Permission.ManageWarehouse) &&
                                            !hasPermission(userSession.permissions, Permission.PrintDocuments)
                                        ) {
                                            securityRestriction(api, [Permission.ManageWarehouse, Permission.PrintDocuments]);
                                            return;
                                        }

                                        getPrintData([record.id]);
                                    }}
                                />
                            </Tooltip>
                        </Space>
                    );
                },
            },
        ];

        return (
            <Table
                rowKey='id'
                size='small'
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                columns={columns}
                dataSource={boxes}
                pagination={false}
                scroll={{ y: `calc(100vh - 190px)` }}
                rowSelection={{
                    selectedRowKeys,
                    onChange: onSelectChange,
                }}
            />
        );
    };

    return (
        <>
            <Row>
                <Col span={9}>
                    <Row>
                        <FormHeader title={`Партия`} />
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form colon={false} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
                                <Form.Item label='ID'>
                                    <Input disabled value={boxGroup?.consigneeCode} />
                                </Form.Item>
                                <Form.Item label='Маркировка'>
                                    <Input disabled value={boxGroup?.markingCode} />
                                </Form.Item>
                                <Form.Item label='Комментарий'>
                                    <TextArea disabled rows={4} value={boxGroup?.comment} />
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </Col>
                <Col offset={1} span={14}>
                    <div style={{ marginTop: 10 }}>
                        {renderToolbar()}
                        {renderTable()}
                    </div>
                </Col>
            </Row>

            <Modal
                title='Переместить на маркировку'
                open={openTransfer}
                onOk={() => onTransferBox()}
                onCancel={() => setOpenTransfer(false)}
            >
                <Select
                    style={{ width: '100%' }}
                    autoFocus
                    showSearch
                    value={newMarkingId}
                    onChange={(value) => setNewMarkingId(value)}
                    optionFilterProp='children'
                    filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                    filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                    options={
                        markings &&
                        markings.map((m) => {
                            return { value: m.id, label: `${m.code} / ${m.cityName}` };
                        })
                    }
                />
            </Modal>

            {startPrint && <PrintProcessing />}

            {contextHolder}
        </>
    );
};

export default Remaining;
