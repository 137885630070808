import React, { useState, useEffect } from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';

import { useElementSize } from 'usehooks-ts';

import { Input, Modal, Table, ColorPicker, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
    LoadingOutlined,
    ReloadOutlined,
    EditOutlined,
    DeleteOutlined,
    PlusOutlined,
    QuestionCircleOutlined,
    FilterFilled,
} from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';
import Filter from '@controls/filter/filter';

import { exception, securityRestriction } from '@extensions/notification';
import { Permission, hasPermission } from '@enums/permission';

import { userLoaded, setFilter } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';

import { delayAction } from '@extensions/utils';

import { IUserSession } from '@entities/user-session';
import { IPackageTemplateFilter } from '@entities/package-template-filter';
import { IPackageTemplate } from '@entities/package-template';

import { serverFetch } from '@src/core/server';

const filterContext: string = 'PackageTemplates';

const PackageTemplates = () => {
    const initFilter: IPackageTemplateFilter = {};

    const [containerRef, { width, height }] = useElementSize();
    const [filterRef, { width: filterWidth, height: filterHeight }] = useElementSize();
    const navigate = useNavigate();
    const resolved = useResolvedPath('');
    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);
    const filter = useAppSelector<IPackageTemplateFilter>((s) => s.filters[filterContext]);

    const [packageTemplates, setPackageTemplates] = useState<Array<IPackageTemplate>>([]);
    const [currentPackageTemplate, setCurrentPackageTemplate] = useState<IPackageTemplate>();

    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [showFilter, setShowFilter] = useState<boolean>(true);
    const [colors, setColors] = useState<Array<string>>([]);
    useEffect(() => {
        if (!refreshRequired) return;

        let cleanup = false;

        const fetchData = async () => {
            let promises = [
                await serverFetch('packageTemplates/colors', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения транспортного тарифа', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('packageTemplates', { method: 'GET', queryParams: filter })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения упаковок', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setColors(result[0][0]);
                setPackageTemplates(result[0][1]);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    useEffect(() => {
        if (!filter) {
            d(setFilter(initFilter, filterContext));
            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    const onDelete = () => {
        if (!currentPackageTemplate) return;

        serverFetch(`packagetemplates/${currentPackageTemplate.id}`, { method: 'DELETE' })
            .then(() => {
                onSelectChange([]);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка удаления упаковки', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        type: 'primary',
                        label: 'Добавить',
                        key: 'add',
                        icon: <PlusOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManagePackageTemplates)) {
                                securityRestriction(api, [Permission.ManagePackageTemplates]);
                                return;
                            }

                            navigate(`${resolved.pathname}/new`);
                        },
                    },
                    {
                        label: `${
                            hasPermission(userSession.permissions, Permission.ManagePackageTemplates)
                                ? 'Изменить'
                                : 'Информация об упаковке'
                        }`,
                        key: 'edit',
                        disabled: !currentPackageTemplate,
                        icon: <EditOutlined />,
                        onClick: () => {
                            navigate(`${resolved.pathname}/${currentPackageTemplate?.id}`);
                        },
                    },
                    {
                        label: 'Удалить',
                        key: 'delete',
                        disabled: !currentPackageTemplate,
                        icon: <DeleteOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.DeletePackageTemplate)) {
                                securityRestriction(api, [Permission.DeletePackageTemplate]);
                                return;
                            }

                            Modal.confirm({
                                title: `Удалить упаковку "${currentPackageTemplate?.name}"?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'Удалить',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onDelete();
                                },
                            });
                        },
                    },
                ]}
                farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setShowFilter(!showFilter);
                        },
                    },
                ]}
            />
        );
    };

    const renderFilter = () => {
        return (
            <div ref={filterRef}>
                <Filter
                    items={[
                        <Input
                            key='name'
                            placeholder='Название'
                            value={filter?.name}
                            onChange={(data) => {
                                d(setFilter({ ...filter, name: data.target.value }, filterContext));
                            }}
                        />,
                        <ColorPicker
                            key='color'
                            styles={{ popupOverlayInner: { width: 235 } }}
                            onChangeComplete={(color) => {
                                d(setFilter({ ...filter, color: color.toHexString() }, filterContext));
                            }}
                            value={filter?.color || '#fafafa'}
                            presets={[
                                {
                                    label: '',
                                    colors: colors,
                                },
                            ]}
                            panelRender={(_, { components: { Picker, Presets } }) => (
                                <div
                                    className='custom-panel'
                                    style={{
                                        display: 'flex',
                                        width: 250,
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: 1,
                                        }}
                                    >
                                        <Presets />
                                    </div>
                                </div>
                            )}
                        />,
                    ]}
                    onClear={() => d(setFilter(initFilter, filterContext))}
                />
            </div>
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let entity = packageTemplates.find((o) => o.id == selectedRowKeys[0]);
            setCurrentPackageTemplate(entity);
        } else {
            setCurrentPackageTemplate(undefined);
        }
    };

    const renderTable = () => {
        const columns: ColumnsType<IPackageTemplate> = [
            {
                title: 'Название',
                dataIndex: 'name',
                width: 300,
            },
            {
                title: 'Количество на паллете',
                dataIndex: 'qtyPerPallet',
                align: 'center',
                width: 200,
            },
            {
                title: 'Размер',
                align: 'center',
                width: 200,
                render: (_, record) => {
                    return `${record.length || 0} x ${record.width || 0} x ${record.height || 0}`;
                },
            },
            {},
        ];

        const tableLoading = {
            spinning: loading,
            indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
        };

        return (
            <Table
                rowKey='id'
                size='small'
                rowSelection={{
                    selectedRowKeys: selectedIds,
                    onChange: onSelectChange,
                    type: 'radio',
                }}
                columns={columns}
                dataSource={packageTemplates}
                loading={tableLoading}
                onRow={(record: IPackageTemplate) => {
                    return {
                        style: {
                            background: record.color,
                        },
                        onClick: (event) => {
                            if (!record.id) return;

                            onSelectChange([record.id]);
                        },
                    };
                }}
                pagination={false}
                scroll={{ y: height + (showFilter ? 0 : 58) }}
            />
        );
    };

    return (
        <div ref={containerRef} style={{ height: `calc(100vh - 182px - ${filterHeight}px)` }}>
            {renderToolbar()}
            {showFilter && renderFilter()}
            {renderTable()}

            {contextHolder}
        </div>
    );
};

export default PackageTemplates;
