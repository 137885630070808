import React, { useState, useEffect } from 'react';

import { Form, Modal, Select, Input, InputNumber } from 'antd';
import { NotificationInstance } from 'antd/lib/notification/interface';

import { exception } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppDispatch } from '@store/hooks';

import { toFinanceString } from '@extensions/utils';
import { serverFetch } from '@src/core/server';

import { ITransactionParams } from '@entities/transaction-params';
import { IUserDebt } from '@entities/user-debt';
import { IUserDebtFilter } from '@entities/user-debt-filter';

import { enumSign as currencySign } from '@enums/currency';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

interface IDepositin {
    userId: string;
    userLogin: string;
    onSave: (value: ITransactionParams) => void;
    onCancel: () => void;
    api: NotificationInstance;
}

const DebtWriteOffModal = (props: IDepositin) => {
    const { TextArea } = Input;

    const [form] = Form.useForm();
    const d = useAppDispatch();

    const { api, userLogin, userId, onSave, onCancel } = props;

    const [userDebts, setUserDebts] = useState<Array<IUserDebt>>([]);
    const [currentUserDebt, setCurrentUserDebt] = useState<IUserDebt>();
    const [loadingUserDebts, setLoadingUserDebts] = useState<boolean>(false);

    useEffect(() => {
        form.setFieldsValue({
            userId: userId,
        });

        let filter: IUserDebtFilter = {
            userId: userId,
            isArchived: false,
        };

        setLoadingUserDebts(true);

        serverFetch('userdebts', { method: 'GET', queryParams: filter })
            .then((data) => {
                setUserDebts(data);
                setLoadingUserDebts(false);
            })
            .catch((ex) => {
                setLoadingUserDebts(false);
                exception(api, 'Ошибка получения долгов перед клиентом', ex, () => d(userLoaded(undefined)));
            });
    }, []);

    const onSaveTransaction = (entity: ITransactionParams) => {
        onSave(entity);
    };

    return (
        <Modal
            destroyOnClose={true}
            width={550}
            title={`Списать долг перед "${userLogin}"`}
            open={true}
            okText='ОК'
            onOk={() => {
                form.submit();
            }}
            onCancel={() => onCancel()}
        >
            <Form
                colon={false}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                style={{ marginTop: 20 }}
                form={form}
                onFinish={onSaveTransaction}
            >
                <Form.Item hidden name='userId'>
                    <Input />
                </Form.Item>
                <Form.Item hidden name='currency'>
                    <Select />
                </Form.Item>

                <Form.Item name='userDebtId' label='Долг' rules={[{ required: true, message: 'Укажите долг' }]}>
                    <Select
                        loading={loadingUserDebts}
                        onChange={(value: string) => {
                            setCurrentUserDebt(userDebts.find((d) => d.id == value));
                        }}
                        options={userDebts.map((t) => {
                            return { value: t.id, label: `${t.debtName} ${toFinanceString(t.amount)} ${currencySign(t.currency)}` };
                        })}
                    />
                </Form.Item>
                <Form.Item label='Сумма' name='amount' wrapperCol={{ span: 9 }} rules={[{ required: true, message: 'Укажите сумму' }]}>
                    <InputNumber
                        precision={2}
                        decimalSeparator=','
                        min={0}
                        style={{ width: '100%' }}
                        addonAfter={currencySign(currentUserDebt?.currency)}
                    />
                </Form.Item>
                <Form.Item label='Комментарий' name='comment' required>
                    <TextArea rows={4} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default DebtWriteOffModal;
