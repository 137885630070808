import React, { useState, useEffect } from 'react';

import { Form, Modal, Select, Input } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { userLoaded } from '@store/actions';
import { useAppDispatch } from '@store/hooks';

import { exception } from '@src/core/extensions/notification';

import { IMarking } from '@entities/marking';
import { IConsignee } from '@entities/consignee';

import { serverFetch } from '@src/core/server';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

interface ITransfer {
    fromConsignee: IConsignee;
    consignees: Array<IConsignee>;
    onSave: (value: IMarking) => void;
    onCancel: () => void;
    api: any;
}

const TransferMarkingModal = (props: ITransfer) => {
    const [modal, modalContextHolder] = Modal.useModal();

    const [form] = Form.useForm();

    const { fromConsignee, consignees, onSave, onCancel, api } = props;

    const d = useAppDispatch();

    const [markings, setMarkings] = useState<Array<IMarking>>([]);

    useEffect(() => {
        serverFetch(`markings`, { method: 'GET', queryParams: { consigneeId: fromConsignee.id } })
            .then((data: Array<IMarking>) => {
                setMarkings(data);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения маркировок', ex, () => d(userLoaded(undefined)));
            });
    }, []);

    return (
        <>
            <Modal
                destroyOnClose={true}
                width={450}
                title={`Перенос маркировки c ID = "${fromConsignee.code}"`}
                open={true}
                okText='ОК'
                onOk={() => {
                    modal.confirm({
                        title: `Перенести маркировку?`,
                        icon: <QuestionCircleOutlined />,
                        okType: 'primary',
                        okText: 'ОК',
                        cancelText: 'Отмена',
                        onOk: () => {
                            form.submit();
                        },
                    });
                }}
                onCancel={() => onCancel()}
            >
                <Form
                    colon={false}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{ marginTop: 20 }}
                    form={form}
                    onFinish={onSave}
                >
                    <Form.Item hidden name='userId'>
                        <Input />
                    </Form.Item>
                    <Form.Item name='id' label='Маркировка' rules={[{ required: true, message: 'Укажите маркировку' }]}>
                        <Select
                            filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                            filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                            options={markings.map((a) => {
                                return {
                                    value: a.id,
                                    label: a.code,
                                };
                            })}
                        />
                    </Form.Item>
                    <Form.Item name='consigneeId' label='ID' rules={[{ required: true, message: 'Укажите ID грузополучателя' }]}>
                        <Select
                            showSearch
                            filterOption={(input, option) => (`${option?.label}` as string).toLowerCase().startsWith(input.toLowerCase())}
                            filterSort={(a, b) => (a?.label && b?.label && a?.label > b?.label ? 1 : -1)}
                            options={consignees
                                .filter((c) => c.id != fromConsignee.id)
                                .map((a) => {
                                    return {
                                        value: a.id,
                                        label: a.code,
                                    };
                                })}
                        />
                    </Form.Item>
                </Form>
            </Modal>
            {modalContextHolder}
        </>
    );
};

export default TransferMarkingModal;
