import React, { useState, useEffect } from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';
import { notification } from 'antd';

import { Input, Table, Tooltip, Tag, Form, Modal } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
    LoadingOutlined,
    ReloadOutlined,
    FilterFilled,
    UnorderedListOutlined,
    PrinterOutlined,
    UsergroupAddOutlined,
    BarcodeOutlined,
    ExclamationCircleOutlined,
    CheckOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';

import { usePDF } from '@react-pdf/renderer';
import printJS from 'print-js';

import PrintProcessing from '@controls/print-processing';
import Filter from '@controls/filter/filter';
import Toolbar from '@controls/toolbar/toolbar';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import BarcodePdf from '@print-forms/barcode-pdf';
import TruckAcceptancePdf from '@print-forms/truck-acceptance/truck-acceptance-pdf';

import { delayAction } from '@extensions/utils';
import { exception, securityRestriction, error, success } from '@extensions/notification';
import { userLoaded, setFilter } from '@store/actions';
import { serverFetch } from '@src/core/server';

import { IUserSession } from '@entities/user-session';

import { Permission, hasPermission } from '@enums/permission';
import { AreaType } from '@enums/area-type';
import { enumLabel as jobStatusLabel } from '@enums/job-status';

import { ITruckFilter } from '@entities/truck-filter';
import { ITruck } from '@entities/truck';
import { ICountry } from '@entities/country';
import { IConsignment } from '@entities/consignment';
import { ICity } from '@entities/city';

import { WarehouseType } from '@enums/warehouse-type';
import { TruckType } from '@enums/truck-type';

import { HoldIcon, BoxesIcon } from '@icons/index';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const filterContext: string = 'Acceptance';

const Acceptance = () => {
    const initFilter: ITruckFilter = {
        type: TruckType.Import,
        isArchived: false,
    };

    const navigate = useNavigate();
    const resolved = useResolvedPath('');

    const [modal, modalContextHolder] = Modal.useModal();

    const d = useAppDispatch();
    const [api, contextHolder] = notification.useNotification();

    const userSession = useAppSelector<IUserSession>((s) => s.userSession);
    const warehouseType = useAppSelector<WarehouseType>((s) => s.warehouseType);

    const [showFilter, setShowFilter] = useState<boolean>(true);

    const filter = useAppSelector<ITruckFilter>((s) => s.filters[filterContext]);

    const [headers, setHeaders] = useState<Array<ITruck>>([]);
    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const [currentTruck, setCurrentTruck] = useState<ITruck>();
    const [countries, setCountries] = useState<Array<ICountry>>([]);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [printData, setPrintData] = useState<any>();
    const [loadingPrintData, setLoadingPrintData] = useState<boolean>(false);
    const [startPrint, setStartPrint] = useState<boolean>(false);
    const [pdfInstance, updatePdf] = usePDF();

    const [startPrintAcceptance, setStartPrintAcceptance] = useState<boolean>(false);
    const [loadingPrintAcceptanceData, setLoadingPrintAcceptanceData] = useState(false);
    const [printAcceptanceData, setPrintAcceptanceData] = useState<any>();
    const [acceptancePdfInstance, updateAcceptancePdf] = usePDF();

    useEffect(() => {
        let cleanup = false;

        if (!refreshRequired) return;

        setCountries([]);
        setHeaders([]);
        setSelectedIds([]);
        setCurrentTruck(undefined);

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch('countries', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения стран', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            if (!warehouseType || warehouseType === WarehouseType.Main) {
                promises.push(
                    await serverFetch('warehouse/acceptance/trucks', { method: 'GET', queryParams: filter })
                        .then((data) => {
                            return data;
                        })
                        .catch((ex) => {
                            exception(api, 'Ошибка получения машин', ex, () => d(userLoaded(undefined)));
                        })
                );
            } else {
                promises.push(
                    await serverFetch('transitwarehouse/acceptance/trucks', { method: 'GET', queryParams: filter })
                        .then((data) => {
                            return data;
                        })
                        .catch((ex) => {
                            exception(api, 'Ошибка получения машин', ex, () => d(userLoaded(undefined)));
                        })
                );
            }

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setCountries(result[0][0].filter((c: ICountry) => c.generateTariff));

                let entities = result[0][1];
                setHeaders(entities);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    useEffect(() => {
        if (!filter) {
            d(setFilter(initFilter, filterContext));
            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    useEffect(() => {
        if (printData) {
            updatePdf(<BarcodePdf labels={printData} />);
            setLoadingPrintData(false);
            setStartPrint(true);
        }
    }, [printData]);

    useEffect(() => {
        if (startPrint && !pdfInstance.loading && pdfInstance.blob) {
            setStartPrint(false);
            setPrintData(undefined);

            const blobURL = URL.createObjectURL(pdfInstance.blob);
            printJS(blobURL);
        }
    }, [startPrint, pdfInstance]);

    useEffect(() => {
        if (printAcceptanceData) {
            updateAcceptancePdf(<TruckAcceptancePdf printData={printAcceptanceData} countries={countries} />);
            setLoadingPrintAcceptanceData(false);
            setStartPrintAcceptance(true);
        }
    }, [printAcceptanceData]);

    useEffect(() => {
        if (startPrintAcceptance && !acceptancePdfInstance.loading && acceptancePdfInstance.blob) {
            setStartPrintAcceptance(false);
            setPrintAcceptanceData(undefined);

            const blobURL = URL.createObjectURL(acceptancePdfInstance.blob);
            printJS(blobURL);
        }
    }, [startPrintAcceptance, acceptancePdfInstance]);

    const getPrintData = () => {
        setLoadingPrintData(true);

        serverFetch(`warehouse/acceptance/${currentTruck?.id}/printbarcode`, { method: 'GET' })
            .then((data) => {
                setPrintData(data);
            })
            .catch((ex) => {
                setLoadingPrintData(false);
                exception(api, 'Ошибка получения груза', ex, () => d(userLoaded(undefined)));
            });
    };

    const getPrintAcceptanceData = () => {
        setLoadingPrintAcceptanceData(true);

        serverFetch(`warehouse/acceptance/${currentTruck?.id}/print`, { method: 'GET' })
            .then((data) => {
                let printData = {
                    truckNumber: currentTruck?.number,
                    consignments: data,
                };
                setPrintAcceptanceData(printData);
            })
            .catch((ex) => {
                setLoadingPrintAcceptanceData(false);
                exception(api, 'Ошибка получения груза', ex, () => d(userLoaded(undefined)));
            });
    };

    const generateBoxes = () => {
        setLoading(true);

        serverFetch(`warehouse/acceptance/${currentTruck?.id}/generateboxes`, { method: 'POST' })
            .then(() => {
                success(api, 'Генерация штрих-кодов', `Успешно`);

                setLoading(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка генерации штрих-кодов', ex, () => d(userLoaded(undefined)));
            });
    };

    const onComplete = () => {
        if (!currentTruck) return;

        if (!warehouseType || warehouseType === WarehouseType.Main) {
            serverFetch(`warehouse/${currentTruck?.id}/completeacceptance`, { method: 'POST' })
                .then(() => {
                    onSelectChange([]);
                    setRefreshRequired(true);
                })
                .catch((ex) => {
                    exception(api, 'Ошибка завершения приемки', ex, () => d(userLoaded(undefined)));
                });
        } else {
            serverFetch(`transitwarehouse/${currentTruck?.id}/completeacceptance`, { method: 'POST' })
                .then(() => {
                    onSelectChange([]);
                    setRefreshRequired(true);
                })
                .catch((ex) => {
                    exception(api, 'Ошибка завершения приемки', ex, () => d(userLoaded(undefined)));
                });
        }
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Детали поставки',
                        key: 'delivery-details',
                        disabled: !currentTruck,
                        icon: <UnorderedListOutlined />,
                        onClick: () => {
                            let canPrint = currentTruck?.consignments?.every((e) => e.hasBoxNumbers);
                            if (!canPrint) {
                                error(api, 'Сгенерируйте штрих-коды');
                                return;
                            }

                            navigate(`${resolved.pathname}/${currentTruck?.id}`);
                        },
                    },
                    {
                        label: 'Сгенерировать штрих-коды',
                        key: 'generateBarcode',
                        disabled:
                            !currentTruck ||
                            currentTruck?.consignments?.every((e) => e.hasBoxNumbers) ||
                            (warehouseType && warehouseType != WarehouseType.Main),
                        icon: <BarcodeOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageWarehouse)) {
                                securityRestriction(api, [Permission.ManageWarehouse]);
                                return;
                            }

                            generateBoxes();
                        },
                    },
                    {
                        label: 'Печатать штрих-коды',
                        key: 'printBarcode',
                        disabled: loading || !currentTruck || startPrint || (warehouseType && warehouseType != WarehouseType.Main),
                        icon: <PrinterOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageWarehouse)) {
                                securityRestriction(api, [Permission.ManageWarehouse]);
                                return;
                            }

                            let canPrint = currentTruck?.consignments?.every((e) => e.hasBoxNumbers);
                            if (!canPrint) {
                                error(api, 'Сгенерируйте штрих-коды');
                                return;
                            }

                            getPrintData();
                        },
                    },
                    {
                        label: 'Назначить исполнителя',
                        key: 'assignJob',
                        disabled: !currentTruck,
                        icon: <UsergroupAddOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageWarehouse)) {
                                securityRestriction(api, [Permission.ManageWarehouse]);
                                return;
                            }

                            let canPrint = currentTruck?.consignments?.every((e) => e.hasBoxNumbers);
                            if (!canPrint) {
                                error(api, 'Сгенерируйте штрих-коды');
                                return;
                            }

                            navigate(`/warehouse/performers/${AreaType.Acceptance}/${currentTruck?.id}`);
                        },
                    },
                    {
                        label: 'Печать листа сверки',
                        key: 'print',
                        disabled: loading || !currentTruck || startPrint,
                        icon: <PrinterOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.PrintDocuments)) {
                                securityRestriction(api, [Permission.PrintDocuments]);
                                return;
                            }

                            getPrintAcceptanceData();
                        },
                    },
                    {
                        label: 'Завершить приемку',
                        key: 'complete-acceptance',
                        disabled: !currentTruck,
                        type: 'primary',
                        icon: <CheckOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.CompleteAcceptanceForce)) {
                                securityRestriction(api, [Permission.CompleteAcceptanceForce]);
                                return;
                            }

                            modal.confirm({
                                title: `Вы хотите завершить приемку машины принудительно "${currentTruck?.number}"?`,
                                okType: 'primary',
                                icon: <QuestionCircleOutlined />,
                                okText: 'ОК',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onComplete();
                                },
                            });
                        },
                    },
                ]}
                farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setShowFilter(!showFilter);
                        },
                    },
                ]}
            />
        );
    };

    const renderFilter = () => {
        return (
            <Filter
                items={[
                    <Input
                        style={{ width: 150 }}
                        key='number'
                        placeholder='Машина'
                        value={filter?.number}
                        onChange={(data) => {
                            d(setFilter({ ...filter, number: data.target.value }, filterContext));
                        }}
                    />,
                    <Input
                        style={{ width: 130 }}
                        key='consigneeCode'
                        placeholder='ID клиента'
                        value={filter?.consigneeCode}
                        onChange={(data) => {
                            d(setFilter({ ...filter, consigneeCode: data.target.value }, filterContext));
                        }}
                    />,
                    <Input
                        style={{ width: 130 }}
                        key='markingCode'
                        placeholder='Маркировка'
                        value={filter?.markingCode}
                        onChange={(data) => {
                            d(setFilter({ ...filter, markingCode: data.target.value }, filterContext));
                        }}
                    />,
                    <Input
                        style={{ width: 130 }}
                        key='awbNumber'
                        placeholder='AWB'
                        value={filter?.awbNumber}
                        onChange={(data) => {
                            d(setFilter({ ...filter, awbNumber: data.target.value }, filterContext));
                        }}
                    />,
                ]}
                onClear={() => d(setFilter(initFilter, filterContext))}
            />
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let item = headers.find((o) => o.id === selectedRowKeys[0]);
            setCurrentTruck(item);
        } else {
            setCurrentTruck(undefined);
        }
    };

    const expandedConsignments = (record: ICity) => {
        let consignments = record.consignments?.map((p) => {
            let boxGroups: any = [];
            let totalQty: number = 0;
            let totalScannedQty: number = 0;

            p.boxGroups?.map((b) => {
                if (!b.countryId) return;

                boxGroups[b.countryId] = { qty: b.qty, scannedBoxQty: b.scannedBoxQty, awbNumber: b.awbNumber };
                totalQty += b.qty || 0;
                totalScannedQty += b.scannedBoxQty || 0;
            });

            p = {
                ...p,
                ...boxGroups,
                totalQty: totalQty,
                totalScannedQty: totalScannedQty,
            };
            return p;
        });

        const columns: ColumnsType<IConsignment> = [
            {
                title: 'ID',
                dataIndex: 'consigneeCode',
                align: 'center',
                width: 60,
                render: (_, record) => {
                    return (
                        <>
                            {record.consigneeCode}
                            <div className='transitWarehouseTag'>{record.warehouseCode}</div>
                        </>
                    );
                },
            },
            {
                title: 'Маркировка',
                width: 155,
                dataIndex: 'markingCode',
            },
        ];

        columns.push({
            title: 'Кол-во / шт',
            align: 'center',
            width: 150,
            dataIndex: 'totalQty',
            onCell: (record) => ({
                style: {
                    background: '#fafafa',
                    fontWeight: 600,
                },
            }),
            render: (_, record) => {
                return (
                    <>
                        <span style={{ fontWeight: 600, color: record.totalScannedQty === record.totalQty ? '#228B22' : 'red' }}>
                            {record.totalScannedQty}
                        </span>
                        <span> / </span>
                        <span>{record.totalQty}</span>
                    </>
                );
            },
        });

        countries.map((c) => {
            columns.push({
                title: c.name,
                width: 280,
                align: 'center',
                render: (_, record) => {
                    let boxGroups = record.boxGroups?.filter((bg) => bg.countryId === c.id);
                    if (!boxGroups || boxGroups.length <= 0) return <></>;

                    return boxGroups.map((bg) => {
                        return (
                            <Form labelAlign='left' labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} style={{ marginBottom: 5 }}>
                                <Form.Item
                                    label={
                                        <Tooltip title='Количество'>
                                            <BoxesIcon />
                                        </Tooltip>
                                    }
                                    className='country-form-item'
                                    style={{ fontWeight: 600 }}
                                >
                                    <Tag color='purple' style={{ marginBottom: 2 }}>
                                        {bg.itemName}
                                    </Tag>
                                    <>
                                        <span style={{ color: bg.scannedBoxQty === bg.qty ? '#228B22' : 'red' }}>{bg.scannedBoxQty}</span>
                                        <span> / </span>
                                        <span>{bg.qty}</span>
                                    </>
                                    {bg.onHold && (
                                        <Tooltip placement='top' title={bg.onHoldReason} color='darkorchid'>
                                            <HoldIcon style={{ color: 'darkorchid', fontSize: 18, marginLeft: 5 }} />
                                        </Tooltip>
                                    )}
                                </Form.Item>

                                {bg.awbNumber && (
                                    <Form.Item label='AWB' className='country-form-item'>
                                        <span style={{ backgroundColor: '#efefef', padding: '0 4px' }}>{bg.awbNumber}</span>
                                    </Form.Item>
                                )}
                            </Form>
                        );
                    });
                },
            });
        });

        return <Table rowKey='cityKey' size='small' columns={columns} dataSource={consignments} pagination={false} />;
    };

    const expandedCities = (record: ITruck) => {
        if (!record || !record.totalQty || record.totalQty <= 0) {
            //  warning(api, "Пожалуйста сгенерируйте штрих-коды")
            return;
        }

        let cities: Array<ICity> = [];
        let cityNames: Array<string> = [];

        record.consignments?.map((e) => {
            let city = cities.find((c) => c.name === e.cityName && c.tag === e.tag);
            if (!city) {
                city = {
                    id: `${e.cityName}#${e.tag}`,
                    name: e.cityName,
                    tag: e.tag,
                    totalQty: 0,
                    totalScannedQty: 0,
                    consignments: [],
                };
                cities.push(city);
                cityNames.push(e.cityName || '');
            }

            e.boxGroups?.map((b) => {
                if (city) {
                    if (!city.totalQty) city.totalQty = 0;
                    if (!city.totalScannedQty) city.totalScannedQty = 0;

                    city.totalQty += b.qty || 0;
                    city.totalScannedQty += b.scannedBoxQty || 0;
                }
            });

            city.consignments?.push(e);
        });

        const columns: ColumnsType<ICity> = [
            {
                title: 'Город доставки',
                width: 215,
                onCell: () => ({
                    style: {
                        fontWeight: 700,
                    },
                }),
                render: (_, record) => {
                    return (
                        <>
                            <span>{record.name}</span>
                            {record.tag && (
                                <Tag color='var(--primary-color)' style={{ marginLeft: 10, color: '#000000' }}>
                                    #{record.tag}
                                </Tag>
                            )}
                        </>
                    );
                },
            },
            {
                title: 'Кол-во / шт',
                align: 'center',
                width: 150,
                onCell: () => ({
                    style: {
                        background: '#fff8d5',
                        fontWeight: 700,
                    },
                }),
                render: (_, record) => {
                    return (
                        <>
                            <span style={{ color: record.totalScannedQty === record.totalQty ? '#228B22' : 'red' }}>
                                {record.totalScannedQty}
                            </span>
                            <span> / </span>
                            <span>{record.totalQty}</span>
                        </>
                    );
                },
            },
            { title: ' ' },
        ];

        return (
            <Table
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={cities}
                pagination={false}
                expandable={{ expandedRowRender: expandedConsignments, defaultExpandedRowKeys: cityNames }}
            />
        );
    };

    const renderTable = () => {
        const columns: ColumnsType<ITruck> = [
            { title: 'Машина', dataIndex: 'number', key: 'number', width: 150 },
            {
                title: 'Дата отправки',
                width: 130,
                render: (_, record) => {
                    return record.departureOn && dayjs.utc(record.departureOn).local().format('DD.MM.YYYY');
                },
            },
            {
                title: 'Общее кол-во / шт',
                width: 150,
                align: 'center',
                onCell: () => ({
                    style: {
                        background: '#FFEE96',
                        fontWeight: 700,
                    },
                }),
                render: (_, record) => {
                    return record.consignments?.every((e) => e.hasBoxNumbers) ? (
                        <>
                            <span style={{ fontWeight: 600, color: record.totalScannedQty === record.totalQty ? '#228B22' : 'red' }}>
                                {record.totalScannedQty}
                            </span>
                            <span> / </span>
                            <span>{record.totalQty}</span>
                        </>
                    ) : (
                        <Tooltip placement='topRight' title='Сгенерируйте штрих-коды'>
                            <ExclamationCircleOutlined />
                        </Tooltip>
                    );
                },
            },

            {
                align: 'center',
                width: 40,
                render: (_, record) => {
                    return record.consignments?.every((e) => e.hasBoxNumbers) && <BarcodeOutlined />;
                },
            },
            {
                title: 'Исполнитель',
                key: 'performerName',
                dataIndex: 'performerName',
                width: 200,
            },
            {
                title: 'Статус выполнения',
                key: 'jobStatus',
                width: 160,
                render: (_, record) => {
                    return <>{jobStatusLabel(record.jobStatus)} </>;
                },
            },
            {},
        ];

        return (
            <Table
                rowKey='id'
                size='small'
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                columns={columns}
                expandable={{ expandedRowRender: expandedCities }}
                dataSource={headers}
                rowSelection={{
                    selectedRowKeys: selectedIds,
                    onChange: onSelectChange,
                    type: 'radio',
                }}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            onSelectChange([record.id || '']);
                        },
                    };
                }}
                pagination={false}
                scroll={{ y: `calc(100vh - ${showFilter ? '240px' : '175px'})`, x: 'max-content' }}
            />
        );
    };

    return (
        <>
            {renderToolbar()}
            {showFilter && renderFilter()}
            {renderTable()}
            {(loadingPrintData || loadingPrintAcceptanceData || startPrintAcceptance || startPrint) && <PrintProcessing />}

            {contextHolder}
            {modalContextHolder}
        </>
    );
};

export default Acceptance;
