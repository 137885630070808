import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';

import { saveAs } from 'file-saver';

import { useElementSize } from 'usehooks-ts';
import { MaskedInput } from 'antd-mask-input';
import { Input, Modal, Table, Select, Tag, Switch, Form, Tooltip, Flex, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
    LoadingOutlined,
    EditOutlined,
    DeleteOutlined,
    PlusOutlined,
    FilterFilled,
    ReloadOutlined,
    QuestionCircleOutlined,
    StopOutlined,
    EyeInvisibleFilled,
    UnorderedListOutlined,
    FileExcelOutlined,
    UserAddOutlined,
    SwapOutlined,
} from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';
import Filter from '@controls/filter/filter';
import TransferMarkingModal from '@components/consignees/transfer-marking-modal';

import { exception, securityRestriction, warning } from '@extensions/notification';
import { Permission, hasPermission, hasPermissionOnly } from '@enums/permission';

import { userLoaded, setFilter } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { getEnumList, delayAction, dataURItoBlob } from '@extensions/utils';

import { serverFetch } from '@src/core/server';
import { UsersIcon, UserIcon, WarehouseIcon, PickupIcon, BillIcon } from '@src/core/icons';

import { IUserSession } from '@entities/user-session';
import { IConsigneeFilter } from '@entities/consignee-filter';
import { IConsignee } from '@entities/consignee';
import { ICity } from '@entities/city';
import { IUser } from '@entities/user';
import { IMarking } from '@entities/marking';

import { ConsigneeType, enumLabel as consigneeEnumLabel } from '@enums/consignee-type';
import { DeliveryType, enumLabel as deliveryTypeLabel } from '@enums/delivery-type';
import { WarehouseType, enumLabel as warehouseTypeLabel } from '@enums/warehouse-type';
import { UserType } from '@enums/user-type';

interface IConsigneeLockParams {
    lockReason?: string;
}

const filterContext: string = 'Consignees';

const Consignees = () => {
    const initFilter: IConsigneeFilter = { isArchived: false, isLockedOnly: false };

    const { TextArea } = Input;

    const [containerRef, { width, height }] = useElementSize();
    const [filterRef, { width: filterWidth, height: filterHeight }] = useElementSize();

    const navigate = useNavigate();
    const resolved = useResolvedPath('');

    const [lockForm] = Form.useForm();

    const [api, contextHolder] = notification.useNotification();
    const [modal, modalContextHolder] = Modal.useModal();

    const fileInputRef = useRef(null);

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);
    const filter = useAppSelector<IConsigneeFilter>((s) => s.filters[filterContext]);

    const [entities, setEntities] = useState<Array<IConsignee>>([]);
    const [toConsignees, setToConsignees] = useState<Array<IConsignee>>([]);
    const [cities, setCities] = useState<Array<ICity>>([]);
    const [contractors, setContractors] = useState<Array<IUser>>([]);
    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const [currentEntity, setCurrentEntity] = useState<IConsignee>();
    const [loading, setLoading] = useState<boolean>(false);
    const [showFilter, setShowFilter] = useState<boolean>(true);
    const [consigneeTypes] = useState(getEnumList(ConsigneeType, consigneeEnumLabel));
    const [openTransferDialog, setOpenTransferDialog] = useState<boolean>(false);

    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);

    const [lockOpen, setLockOpen] = useState<boolean>(false);

    useEffect(() => {
        let cleanup = false;

        const fetchData = async () => {
            let promises = [
                await serverFetch('cities', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения городов', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('users', { method: 'GET', queryParams: { types: [UserType.Supplier] /* isActiveOnly: true  */ } })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения поставщиков', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('consignees', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения грузополучателей', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setCities(result[0][0]);
                setContractors(result[0][1]);
                setToConsignees(result[0][2]);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, []);

    useEffect(() => {
        if (!refreshRequired) return;

        setRefreshRequired(false);
        refresh();
    }, [refreshRequired]);

    useEffect(() => {
        if (!filter) {
            d(setFilter(initFilter, filterContext));

            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    const onTransfer = (entity: IMarking) => {
        if (!entity) return;

        serverFetch(`markings/transfer`, { method: 'POST', bodyData: entity })
            .then(() => {
                setOpenTransferDialog(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка переноса маркировки', ex, () => d(userLoaded(undefined)));
            });
    };

    const refresh = () => {
        setEntities([]);

        setLoading(true);

        serverFetch('consignees', { method: 'GET', queryParams: filter })
            .then((data) => {
                setEntities(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения грузополучателей', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    };

    const onDelete = () => {
        serverFetch(`consignees`, { method: 'DELETE', bodyData: selectedIds })
            .then(() => {
                onSelectChange([]);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка удаления грузополучателя', ex, () => d(userLoaded(undefined)));
            });
    };

    const onSetLock = (entity: IConsigneeLockParams) => {
        serverFetch(`consignees/setlock`, {
            method: 'POST',
            bodyData: {
                ids: selectedIds,
                lockReason: entity.lockReason,
            },
        })
            .then(() => {
                lockForm.resetFields();
                setLockOpen(false);
                onSelectChange([]);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка блокировки грузополучателя', ex, () => d(userLoaded(undefined)));
            });
    };

    const onExport = () => {
        serverFetch('consignees/export', { method: 'GET', queryParams: filter })
            .then((data) => {
                let fileBody = `data:${data.contentType};base64,${data.fileContents}`;
                saveAs(dataURItoBlob(fileBody), data.fileDownloadName);
            })
            .catch((ex) => {
                exception(api, 'Ошибка выгрузки грузополучателей', ex, () => d(userLoaded(undefined)));
            });
    };

    const onAddUser = () => {
        serverFetch(`consignees/${currentEntity?.id}/user`, { method: 'POST' })
            .then(() => {
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка создание личного кабинета', ex, () => d(userLoaded(undefined)));
            });
    };

    const onFixIds = () => {
        serverFetch('consignees/fixids', { method: 'POST' })
            .then(() => {
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка фиксации нумерации ID грузополучателей', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            refresh();
                        },
                    },
                    {
                        type: 'primary',
                        label: 'Добавить',
                        key: 'add',
                        icon: <PlusOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageConsignees)) {
                                securityRestriction(api, [Permission.ManageConsignees]);
                                return;
                            }

                            navigate(`${resolved.pathname}/new`);
                        },
                    },
                    {
                        label: `${
                            hasPermission(userSession.permissions, Permission.ManageConsignees)
                                ? 'Изменить'
                                : 'Информация о грузополучателе'
                        }`,
                        key: 'edit',
                        disabled: !currentEntity,
                        icon: <EditOutlined />,
                        onClick: () => {
                            navigate(`${resolved.pathname}/${currentEntity?.id}`);
                        },
                    },
                    {
                        label: 'Перенести маркировку',
                        key: 'transfer',
                        disabled: !currentEntity,
                        icon: <SwapOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.TransferMarking)) {
                                securityRestriction(api, [Permission.TransferMarking]);
                                return;
                            }

                            setOpenTransferDialog(true);
                        },
                    },
                    {
                        label: 'Удалить',
                        key: 'delete',
                        disabled: selectedIds.length <= 0,
                        icon: <DeleteOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.DeleteConsignee)) {
                                securityRestriction(api, [Permission.DeleteConsignee]);
                                return;
                            }

                            modal.confirm({
                                title: `Удалить выбранных грузополучателей?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'Удалить',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onDelete();
                                },
                            });
                        },
                    },
                    {
                        label: 'Разблокировать / Заблокировать',
                        disabled: selectedIds.length <= 0,
                        key: 'lock',
                        icon: <StopOutlined />,
                        onClick: () => {
                            if (!hasPermissionOnly(userSession.permissions, Permission.LockConsignee)) {
                                securityRestriction(api, [Permission.LockConsignee]);
                                return;
                            }

                            setLockOpen(true);
                        },
                    },
                    {
                        label: 'Заказы пользователя',
                        key: 'orders',
                        disabled: !currentEntity,
                        icon: <UnorderedListOutlined />,
                        onClick: () => {
                            navigate(`/orders/${currentEntity?.userId}`);
                        },
                    },
                    {
                        label: 'Счета пользователя',
                        key: 'bills',
                        disabled: !currentEntity,
                        icon: <BillIcon />,
                        onClick: () => {
                            navigate(`/finance/bills/${currentEntity?.userId}`);
                        },
                    },
                    {
                        label: 'Выгрузить в Excel',
                        key: 'exportToExcel',
                        icon: <FileExcelOutlined />,
                        onClick: () => onExport(),
                    },
                    {
                        label: 'Создать личный кабинет',
                        disabled: !currentEntity || currentEntity.type != ConsigneeType.GroupMember,
                        key: 'user',
                        icon: <UserAddOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.AddSubClientAccount)) {
                                securityRestriction(api, [Permission.AddSubClientAccount]);
                                return;
                            }

                            if (currentEntity && currentEntity.subuserId) {
                                warning(api, 'Личный кабинет уже существует');
                                return;
                            }

                            modal.confirm({
                                title: `Создать личный кабинет для субклиента c ID ${currentEntity?.code}?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'ОК',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onAddUser();
                                },
                            });
                        },
                    },
                    /*        {
                        label: 'Fix IDs',
                        key: 'fixIds',
                        disabled: !hasPermissionOnly(userSession.permissions, Permission.FullAccess),
                        onClick: () => onFixIds(),
                    }, */
                ]}
                farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setShowFilter(!showFilter);
                        },
                    },
                ]}
            />
        );
    };

    const renderFilter = () => {
        return (
            <div ref={filterRef}>
                <Filter
                    items={[
                        <Input
                            key='code'
                            placeholder='ID клиента'
                            value={filter?.code}
                            style={{ width: 130 }}
                            onChange={(data) => {
                                d(setFilter({ ...filter, code: data.target.value }, filterContext));
                            }}
                        />,
                        <Input
                            key='markingCode'
                            placeholder='Маркировка'
                            value={filter?.markingCode}
                            onChange={(data) => {
                                d(setFilter({ ...filter, markingCode: data.target.value }, filterContext));
                            }}
                        />,
                        <Select
                            maxTagCount='responsive'
                            key='contractor'
                            placeholder='Поставщик'
                            value={filter?.contractorIds}
                            size='middle'
                            mode='multiple'
                            allowClear={true}
                            style={{ width: 200 }}
                            showSearch
                            onChange={(value) => d(setFilter({ ...filter, contractorIds: value }, filterContext))}
                            optionFilterProp='children'
                            filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                            filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                            options={contractors.map((c) => {
                                return { value: c.id, label: c.login };
                            })}
                        />,
                        <Input
                            key='searchText'
                            placeholder='ИНН / Контрагент'
                            value={filter?.searchText}
                            style={{ width: 230 }}
                            onChange={(data) => {
                                d(setFilter({ ...filter, searchText: data.target.value }, filterContext));
                            }}
                        />,
                        <MaskedInput
                            value={filter?.phone}
                            size='middle'
                            mask={'+7 (000) 000-00-00'}
                            onChange={(data) => {
                                d(setFilter({ ...filter, phone: data.maskedValue }, filterContext));
                            }}
                        />,
                        <Select
                            key='type'
                            placeholder='Тип'
                            allowClear={true}
                            style={{ width: 150 }}
                            value={filter?.type}
                            onChange={(value) => {
                                d(setFilter({ ...filter, type: value }, filterContext));
                            }}
                            options={consigneeTypes.map((t) => {
                                return { value: t.value, label: t.label };
                            })}
                        />,
                        <Select
                            key='city'
                            placeholder='Город доставки'
                            maxTagCount='responsive'
                            value={filter?.cityId}
                            allowClear={true}
                            style={{ width: 200 }}
                            showSearch
                            onChange={(value) => d(setFilter({ ...filter, cityId: value }, filterContext))}
                            optionFilterProp='children'
                            filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                            filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                            options={cities.map((c) => {
                                return { value: c.id, label: c.name };
                            })}
                        />,
                        <Form.Item key='isLockedOnly' label='Заблокированные' style={{ margin: 0 }}>
                            <Switch
                                checked={filter?.isLockedOnly}
                                onChange={(value) => {
                                    d(setFilter({ ...filter, isLockedOnly: value }, filterContext));
                                }}
                            />
                        </Form.Item>,

                        hasPermission(userSession.permissions, Permission.FullAccess) && (
                            <Form.Item key='isArchived' label='Архив' style={{ margin: 0 }}>
                                <Switch
                                    checked={filter?.isArchived}
                                    onChange={(value) => {
                                        d(setFilter({ ...filter, isArchived: value }, filterContext));
                                    }}
                                />
                            </Form.Item>
                        ),
                    ]}
                    onClear={() => d(setFilter(initFilter, filterContext))}
                />
            </div>
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let entity = entities.find((o) => o.id === selectedRowKeys[0]);
            setCurrentEntity(entity);
        } else {
            setCurrentEntity(undefined);
        }
    };

    const renderTable = () => {
        const columns: ColumnsType<IConsignee> = [
            {
                title: 'ID',
                align: 'center',
                width: 90,
                sortDirections: ['ascend', 'descend', 'ascend'],
                defaultSortOrder: 'ascend',
                sorter: (a, b) => (a.code || 0) - (b.code || 0),
                onCell: (record) => ({
                    style: {
                        background: record.isLocked ? '#ff9999' : '#fafafa',
                        fontWeight: 600,
                    },
                }),
                render: (_: any, record: IConsignee) => {
                    return (
                        <Tooltip placement='top' title={record.lockReason} color='red'>
                            {record.type == ConsigneeType.Main ? (
                                <a
                                    href={`${window.location.protocol}//${window.location.host}/settings/users/${record?.userId}`}
                                    target='_blank'
                                >
                                    {record.code}
                                </a>
                            ) : record.type == ConsigneeType.GroupMember && record.subuserId ? (
                                <a
                                    style={{ color: 'var(--main-red)' }}
                                    href={`${window.location.protocol}//${window.location.host}/settings/users/${record?.subuserId}`}
                                    target='_blank'
                                >
                                    {record.code}
                                </a>
                            ) : (
                                <>{record.code}</>
                            )}
                            <Flex justify='center'>
                                <Tooltip placement='top' title={`Тип: "${consigneeEnumLabel(record.type)}"`}>
                                    <div>
                                        {record.type === ConsigneeType.GroupMember ? (
                                            <UsersIcon style={{ fontSize: 18 }} />
                                        ) : (
                                            <UserIcon style={{ fontSize: 14 }} />
                                        )}
                                    </div>
                                </Tooltip>
                                <Tooltip placement='top' title={`Доставка: "${deliveryTypeLabel(record.deliveryType)}"`}>
                                    <div>
                                        {record.deliveryType === DeliveryType.OnHand ? (
                                            <PickupIcon style={{ fontSize: 14, marginLeft: 5 }} />
                                        ) : (
                                            <WarehouseIcon style={{ fontSize: 14, marginLeft: 5 }} />
                                        )}
                                    </div>
                                </Tooltip>
                            </Flex>
                            {record.fromPlatina && <Tag color='#000000'>#PL</Tag>}
                        </Tooltip>
                    );
                },
            },
            {
                title: 'Маркировка',
                dataIndex: 'allMarkings',
                width: 300,
            },
            {
                title: 'Город доставки',
                dataIndex: 'cityName',
                width: 150,
            },
            {
                title: 'Склад',
                align: 'center',
                width: 70,
                render: (_, record) => {
                    return (
                        <Tooltip title={`${warehouseTypeLabel(record.warehouseType)} склад`}>
                            <div className={record.warehouseType === WarehouseType.Main ? 'mainWarehouseTag' : 'transitWarehouseTag'}>
                                {record.warehouseCode}
                            </div>
                        </Tooltip>
                    );
                },
            },
            {
                title: 'Поставщик',
                width: 130,
                onCell: (record) => ({
                    style: {
                        fontWeight: 600,
                    },
                }),
                render: (_: any, record: IConsignee) => {
                    return (
                        <a href={`${window.location.protocol}//${window.location.host}/settings/users/${record?.userId}`} target='_blank'>
                            {record.contractorName}
                        </a>
                    );
                },
            },
            {
                title: 'Тег',
                width: 150,
                align: 'center',
                render: (_, record) => {
                    return (
                        <>
                            {record.tag && (
                                <Tag color='var(--primary-color)' style={{ marginLeft: 10, color: '#000000' }}>
                                    #{record.tag}
                                </Tag>
                            )}
                        </>
                    );
                },
            },
            {
                title: 'Телефон',
                dataIndex: 'phone',
                width: 170,
                render: (_, record) => {
                    return (
                        <>
                            {record.phone}{' '}
                            {record.hidePhone && (
                                <Tooltip title='Номер телефона скрыт'>
                                    <EyeInvisibleFilled style={{ marginLeft: 5, color: 'plum' }} />
                                </Tooltip>
                            )}
                        </>
                    );
                },
            },
            {
                title: 'ИНН',
                dataIndex: 'inn',
                width: 110,
            },
            {
                title: 'Контрагент',
                dataIndex: 'legalName',
                width: 200,
            },
            {
                title: 'Юр. адрес',
                dataIndex: 'legalAddress',
                width: 300,
            },
            {
                title: 'Адрес',
                dataIndex: 'address',
                width: 350,
            },
            {
                title: 'ФИО',
                dataIndex: 'fullName',
                width: 250,
            },
            {},
        ];

        return (
            <Table
                rowKey='id'
                size='small'
                rowSelection={{
                    selectedRowKeys: selectedIds,
                    onChange: onSelectChange,
                    columnWidth: 35,
                }}
                columns={columns}
                dataSource={entities}
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                onRow={(record) => {
                    return {
                        onClick: (event) => {
                            onSelectChange([record.id || '']);
                        },
                    };
                }}
                pagination={false}
                virtual={true}
                scroll={{ y: height + (showFilter ? 0 : 58) }}
            />
        );
    };

    return (
        <div ref={containerRef} style={{ height: `calc(100vh - 182px - ${filterHeight}px)` }}>
            {renderToolbar()}
            {showFilter && renderFilter()}
            {renderTable()}

            <input
                type='file'
                accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
                onChange={(x: any) => {
                    let file = x.target.files[0];
                    if (file) {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                            if (reader && reader.result) {
                                var fileBody = (reader.result as string).replace(
                                    'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
                                    ''
                                );

                                serverFetch(`consignees/import`, { method: 'POST', bodyData: fileBody })
                                    .then(() => {})
                                    .catch((ex) => {
                                        exception(api, 'Ошибкаипорта файлаа', ex, () => d(userLoaded(undefined)));
                                    });
                            }

                            reader.onerror = (error) => console.log(error);
                        };
                    }
                }}
                onClick={(event: any) => {
                    event.target.value = null;
                }}
                multiple={false}
                ref={fileInputRef}
                style={{ display: 'none' }}
            />

            <Modal
                title={
                    <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'start' }}>
                        <QuestionCircleOutlined style={{ color: '#faad14', marginInlineEnd: 12, fontSize: 22 }} />
                        Заблокировать грузополучателей?
                    </div>
                }
                open={lockOpen}
                okText='Сохранить'
                onOk={() => lockForm.submit()}
                onCancel={() => {
                    lockForm.resetFields();
                    setLockOpen(false);
                }}
                closable={false}
            >
                <Form
                    colon={false}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    onFinish={onSetLock}
                    form={lockForm}
                    style={{ marginTop: 10 }}
                >
                    <Form.Item
                        required={true}
                        label='Причина'
                        name='lockReason'
                        rules={[{ required: true, message: 'Укажите причину блокировки' }]}
                    >
                        <TextArea autoFocus rows={4} />
                    </Form.Item>
                </Form>
            </Modal>

            {currentEntity && openTransferDialog && (
                <TransferMarkingModal
                    fromConsignee={currentEntity}
                    consignees={toConsignees}
                    onCancel={() => setOpenTransferDialog(false)}
                    onSave={(value: IMarking) => onTransfer(value)}
                    api={api}
                />
            )}

            {contextHolder}
            {modalContextHolder}
        </div>
    );
};

export default Consignees;
