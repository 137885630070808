export enum DiscountType {
    TruckWeight = 1,
    TruckPallet = 2,
    TruckCart = 3,
}

export const enumLabel = (value?: DiscountType) => {
    switch (value) {
        case DiscountType.TruckWeight:
            return 'Доставка с 1 КГ';
        case DiscountType.TruckPallet:
            return 'Доставка с 1 палета';
        case DiscountType.TruckCart:
            return 'Доставка с 1 телеги';
        default:
            return '';
    }
};
