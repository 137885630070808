import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import TableHeader from './table-header';
import TableRow from './table-row';

const borderColor = '#90e5fc';

const styles = StyleSheet.create({
    pageContainer: {
        margin: 20,
        fontSize: 7,
    },
    tableContainer: {
        flexDirection: 'column',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: borderColor,
        borderBottom: 'none',
    },
});
const PdfPage = ({ consignments, countries }) => (
    <View style={styles.pageContainer}>
        <View style={styles.tableContainer}>
            <TableHeader countries={countries} />
            <TableRow consignments={consignments} countries={countries} />
        </View>
    </View>
);

export default PdfPage;
