export enum ExchangeRateType {
    CentralBank = 1,
    Internal = 2,
}

export const enumLabel = (value?: ExchangeRateType) => {
    switch (value) {
        case ExchangeRateType.CentralBank:
            return 'Центробанк';
        case ExchangeRateType.Internal:
            return 'Внутренний';
        default:
            return '';
    }
};
