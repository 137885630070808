import React from 'react';
import Icon from '@ant-design/icons';

const WalletIcon = ({ ...props }) => {
    return <Icon component={svg} style={{ fontSize: 18, ...props.style }} />;
};
export default WalletIcon;

const svg = ({ ...props }) => (
    <svg fill='currentColor' {...props} version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
        <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
        <g id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'></g>
        <g id='SVGRepo_iconCarrier'>
            <g>
                <g>
                    <circle cx='206.016' cy='33.343' r='33.343'></circle>
                </g>
            </g>
            <g>
                <g>
                    <circle cx='306.046' cy='33.343' r='33.343'></circle>
                </g>
            </g>
            <g>
                <g>
                    <path d='M400.516,77.427H111.542c-39.898,0-72.617,32.719-72.617,72.617v61.129c0,18.415,14.928,33.343,33.343,33.343 c18.415,0,33.343-14.928,33.343-33.343v-50.015c0-9.207,7.838-17.045,17.045-17.045h266.745c9.207,0,16.298,7.838,16.298,17.045 v50.015c0,18.415,14.928,33.343,33.343,33.343c18.415,0,33.343-14.928,33.343-33.343v-61.129 C472.386,110.146,440.414,77.427,400.516,77.427z'></path>
                </g>
            </g>
            <g>
                <g>
                    <path d='M494.157,249.027c-12.048,17.398-32.209,28.833-54.927,28.833c-36.773,0-66.873-29.913-66.873-66.686v-33.717H138.954 v33.717c0,36.773-29.727,66.686-66.5,66.686c-22.714,0-42.693-11.432-54.738-28.823C9.498,269.068,5.17,290.019,5.17,311.203 C5.17,421.923,117.705,512,256.006,512S506.83,421.923,506.83,311.203C506.83,290.006,502.368,269.052,494.157,249.027z'></path>
                </g>
            </g>
        </g>
    </svg>
);
