import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { notification } from 'antd';

import { Input, Form, Table, Button, Tag, Modal, Row, Col, Space, Select } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import {
    LoadingOutlined,
    ReloadOutlined,
    DeleteOutlined,
    FilterFilled,
    QuestionCircleOutlined,
    CheckOutlined,
    PlusOutlined,
    ForkOutlined,
} from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';
import FormHeader from '@controls/form-header/form-header';
import Filter from '@controls/filter/filter';

import { delayAction, getEnumList } from '@extensions/utils';
import { exception, securityRestriction } from '@extensions/notification';
import { userLoaded, setFilter } from '@store/actions';
import { serverFetch } from '@src/core/server';

import { useAppDispatch, useAppSelector } from '@store/hooks';

import { IUserSession } from '@entities/user-session';
import { IShipmentJournalLine } from '@entities/shipment-journal-line';
import { IJournalLineFilter } from '@entities/journal-line-filter';
import { IShipmentJournal } from '@entities/shipment-journal';
import { JournalStatus, enumLabel as journalStatusLabel } from '@enums/journal-status';
import { Permission, hasPermission } from '@enums/permission';
import { DeliveryOption, enumLabel as deliveryOptionLabel } from '@enums/delivery-option';
import { IEnumItem } from '@enums/enum-item';

import '../warehouse.css';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

interface IShipmentHeader {
    cityId: string;
    cityName: string;
    tag: string;
    totalBoxQty: number;
    totalScannedBoxQty: number;
    totalVolume: number;
    lines: Array<IShipmentJournalLine>;
}

const filterContext: string = 'ShipmentJournal';

const ShipmentJournal = () => {
    const { id } = useParams();

    const initFilter: IJournalLineFilter = { journalIds: [id as string] };

    const { TextArea } = Input;

    const userSession = useAppSelector<IUserSession>((s) => s.userSession);
    const filter = useAppSelector<IJournalLineFilter>((s) => s.filters[filterContext]);

    const [form] = Form.useForm();

    const [modal, modalContextHolder] = Modal.useModal();

    const d = useAppDispatch();
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate();

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const [deliveryOptions] = useState<Array<IEnumItem>>(getEnumList(DeliveryOption, deliveryOptionLabel));
    const [entity, setEntity] = useState<IShipmentJournal>();
    const [headers, setHeaders] = useState<Array<IShipmentHeader>>([]);
    const [showFilter, setShowFilter] = useState<boolean>(true);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        let cleanup = false;

        if (!refreshRequired) return;

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch(`shipmentjournals/${id}`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения журнала  отправки', ex, () => d(userLoaded(undefined)));
                    }),
                await serverFetch(`shipmentjournals/${id}/lines`, { method: 'GET', queryParams: filter })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения деталей отправки', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setEntity(result[0][0]);
                setHeaders(result[0][1]);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    useEffect(() => {
        form.setFieldsValue({
            journalNumber: entity?.journalNumber,
            truckNumber: entity?.truckNumber,
            status: entity && journalStatusLabel(entity.status),
        });
    }, [entity]);

    const onSetToProcess = () => {
        serverFetch(`shipmentjournals/toprocess/${id}`, { method: 'POST' })
            .then(() => {
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка изменения статуса журнала', ex, () => d(userLoaded(undefined)));
            });
    };

    const onDeleteLines = () => {
        serverFetch(`shipmentjournals/revertshipmentlines`, { method: 'POST', bodyData: selectedRowKeys })
            .then(() => {
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка удаления коробок из журнала', ex, () => d(userLoaded(undefined)));
            });
    };

    const onShipBoxes = () => {
        setLoading(true);

        serverFetch(`shipmentjournals/shipboxes`, { method: 'POST', bodyData: selectedRowKeys })
            .then(() => {
                setLoading(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка отгрузки коробок', ex, () => d(userLoaded(undefined)));
            });
    };

    const onCompleteShipment = () => {
        setLoading(true);

        serverFetch(`warehouse/completeshipment/${id}`, { method: 'POST' })
            .then(() => {
                setLoading(false);
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка завершения партии', ex, () => d(userLoaded(undefined)));
            });
    };

    const onSetDeliveryOption = (option: DeliveryOption) => {
        serverFetch(`shipmentJournals/deliveryoption/${id}/${option}`, { method: 'POST' })
            .then(() => {
                entity && setEntity({ ...entity, deliveryOption: option });

                modal.info({
                    title: `Способ доставки изменен на "${deliveryOptionLabel(option)}"`,
                    onOk() {},
                });
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка изменения способа доставки', ex, () => d(userLoaded(undefined)));
            });
    };

    useEffect(() => {
        if (!filter || !filter.journalIds?.find((t) => t === id)) {
            d(setFilter(initFilter, filterContext));
            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Отгрузить коробки',
                        key: 'ship',
                        disabled: loading || selectedRowKeys.length <= 0 || entity?.status !== JournalStatus.InProcess,
                        type: 'primary',
                        icon: <CheckOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageWarehouse)) {
                                securityRestriction(api, [Permission.ManageWarehouse]);
                                return;
                            }

                            modal.confirm({
                                title: `Отгрузить выбранные коробки?`,
                                okType: 'primary',
                                icon: <QuestionCircleOutlined />,
                                okText: 'ОК',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onShipBoxes();
                                },
                            });
                        },
                    },
                    {
                        label: 'Добавить коробки',
                        key: 'add',
                        disabled: loading || entity?.status === JournalStatus.Completed || entity?.status === JournalStatus.Cancelled,
                        icon: <PlusOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageWarehouse)) {
                                securityRestriction(api, [Permission.ManageWarehouse]);
                                return;
                            }

                            navigate(`/warehouse/remainings/${id}`);
                        },
                    },
                    {
                        label: 'Удалить коробки',
                        key: 'delete',
                        disabled:
                            loading ||
                            selectedRowKeys.length <= 0 ||
                            entity?.status === JournalStatus.Completed ||
                            entity?.status === JournalStatus.Cancelled,
                        icon: <DeleteOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.DeleteWarehouseBox)) {
                                securityRestriction(api, [Permission.DeleteWarehouseBox]);
                                return;
                            }

                            modal.confirm({
                                title: `Удалить выбранные коробки из журнала?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'Удалить',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onDeleteLines();
                                },
                            });
                        },
                    },
                    {
                        label: 'Управление маршрутом',
                        key: 'manageRoutes',
                        disabled: loading || !id || !entity || entity?.status === JournalStatus.Cancelled,
                        icon: <ForkOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageWarehouse)) {
                                securityRestriction(api, [Permission.ManageWarehouse]);
                                return;
                            }

                            navigate(`/warehouse/shipmentjournals/manageoutes/${entity?.id}/${entity?.journalNumber}`);
                        },
                    },
                ]}
                farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setShowFilter(!showFilter);
                        },
                    },
                ]}
            />
        );
    };

    const renderFilter = () => {
        return (
            <Filter
                items={[
                    <Input
                        style={{ width: 130 }}
                        key='boxNumber'
                        placeholder='Коробка'
                        value={filter?.boxNumber}
                        onChange={(data) => {
                            d(setFilter({ ...filter, boxNumber: data.target.value }, filterContext));
                        }}
                    />,
                    <Input
                        style={{ width: 130 }}
                        key='markingCode'
                        placeholder='Маркировка'
                        value={filter?.markingCode}
                        onChange={(data) => {
                            d(setFilter({ ...filter, markingCode: data.target.value }, filterContext));
                        }}
                    />,
                    <Input
                        style={{ width: 130 }}
                        key='awbNumber'
                        placeholder='AWB'
                        value={filter?.awbNumber}
                        onChange={(data) => {
                            d(setFilter({ ...filter, awbNumber: data.target.value }, filterContext));
                        }}
                    />,
                ]}
                onClear={() => d(setFilter(initFilter, filterContext))}
            />
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const expandedRemainings = (record: IShipmentHeader) => {
        const columns: ColumnsType<IShipmentJournalLine> = [
            {
                title: 'Коробка',
                dataIndex: 'boxNumber',
                width: 180,
            },
            {
                title: 'ID',
                align: 'center',
                width: 80,
                render: (_, record) => {
                    return (
                        <>
                            {record.consigneeCode}
                            <div className='transitWarehouseTag'>{record.toWarehouseCode}</div>
                        </>
                    );
                },
            },

            {
                title: 'Маркировка',
                dataIndex: 'markingCode',
                width: 150,
            },
            {
                title: 'Страна',
                dataIndex: 'countryName',
                width: 150,
            },
            {
                title: 'AWB',
                width: 180,
                render: (_, record) => {
                    return (
                        record.awbNumber && (
                            <Tag color='#efefef' style={{ marginLeft: 10, color: '#000000' }}>
                                {record.awbNumber}
                            </Tag>
                        )
                    );
                },
            },
            {
                title: 'Дата приемки',
                render: (_: any, record: IShipmentJournalLine) => {
                    return dayjs.utc(record.acceptedOn).local().format('DD.MM.YYYY HH:mm');
                },
                align: 'center',
                width: 120,
            },
            { title: ' ' },
            {
                title: 'Загружено',
                align: 'center',
                width: 120,
                render: (_, record) => {
                    return record.shippedOn && <CheckOutlined />;
                },
            },
            {
                title: (
                    <>
                        Объем / м<sup>3</sup>
                    </>
                ),
                align: 'center',
                width: 120,
                onCell: () => ({
                    style: {
                        background: '#fafafa',
                        fontWeight: 600,
                    },
                }),
                render: (_: any, record: IShipmentJournalLine) => {
                    return record.calcVolume && record.calcVolume.toFixed(2);
                },
            },
        ];
        return (
            <Table
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={record.lines}
                pagination={false}
                rowSelection={{
                    selectedRowKeys: selectedRowKeys,
                    onChange: onSelectChange,
                }}
            />
        );
    };

    const renderTable = () => {
        const columns: ColumnsType<IShipmentHeader> = [
            {
                title: 'Город доставки',
                dataIndex: 'cityName',
                onCell: () => ({
                    style: {
                        fontWeight: 700,
                    },
                }),
                render: (_, record) => {
                    return (
                        <>
                            <span>{record.cityName}</span>
                            {record.tag && (
                                <Tag color='var(--primary-color)' style={{ marginLeft: 10, color: '#000000' }}>
                                    #{record.tag}
                                </Tag>
                            )}
                        </>
                    );
                },
            },
            {
                title: '',
            },
            {
                title: 'Кол-во / шт',
                align: 'center',
                width: 120,
                dataIndex: 'totalQty',
                onCell: () => ({
                    style: {
                        background: '#fff8d5',
                        fontWeight: 700,
                    },
                }),
                render: (_, record) => {
                    return (
                        <>
                            <span style={{ color: record.totalScannedBoxQty === record.totalBoxQty ? '#228B22' : 'red' }}>
                                {record.totalScannedBoxQty}
                            </span>
                            <span> / </span>
                            <span>{record.totalBoxQty}</span>
                        </>
                    );
                },
            },
            {
                title: (
                    <>
                        Объем / м<sup>3</sup>
                    </>
                ),
                align: 'center',
                width: 120,
                onCell: () => ({
                    style: {
                        background: '#fff8d5',
                        fontWeight: 700,
                    },
                }),
                render: (_: any, record: IShipmentHeader) => {
                    return record.totalVolume.toFixed(2);
                },
            },
        ];

        return (
            <Table
                rowKey='cityKey'
                size='small'
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                columns={columns}
                expandable={{
                    expandedRowRender: expandedRemainings,
                    defaultExpandedRowKeys: headers && headers.map((h) => h.cityName),
                }}
                dataSource={headers}
                pagination={false}
                scroll={{ y: `calc(100vh - ${showFilter ? 544 : 486}px)` }}
            />
        );
    };

    return (
        <>
            <Row>
                <FormHeader title={`Отгрузка`} />
            </Row>
            <Row>
                <Col span={24}>
                    {entity && (
                        <Form colon={false} labelCol={{ span: 4 }} wrapperCol={{ span: 4 }} labelWrap form={form}>
                            <Form.Item label='Номер журнала' name='journalNumber'>
                                <Input disabled={true} />
                            </Form.Item>
                            <Form.Item initialValue={entity?.deliveryOption} label='Вариант доставки' name='deliveryOption'>
                                <Select
                                    disabled={
                                        loading || entity?.status === JournalStatus.Completed || entity?.status === JournalStatus.Cancelled
                                    }
                                    onChange={(value) => {
                                        onSetDeliveryOption(value);
                                    }}
                                    options={deliveryOptions.map((o) => {
                                        return { value: o.value, label: o.label };
                                    })}
                                />
                            </Form.Item>
                            {entity.deliveryOption === DeliveryOption.Truck && (
                                <Form.Item label='Номер машины' name='truckNumber'>
                                    <Input disabled={true} />
                                </Form.Item>
                            )}
                            <Form.Item label='Статус' name='status'>
                                <Input disabled={true} />
                            </Form.Item>
                            {hasPermission(userSession.permissions, Permission.FinishShipmentJournal) &&
                                (entity.status === JournalStatus.New || entity.status === JournalStatus.InProcess) && (
                                    <Form.Item wrapperCol={{ offset: 11 }}>
                                        <Space size={'small'} style={{ float: 'right' }}>
                                            <Button
                                                type='primary'
                                                htmlType='submit'
                                                onClick={() => {
                                                    modal.confirm({
                                                        title: `Завершить журнал отгрузки?`,
                                                        content: 'Все неотгруженные коробки будут возвращены на остаток склада.',
                                                        icon: <QuestionCircleOutlined />,
                                                        okType: 'primary',
                                                        okText: 'ОК',
                                                        cancelText: 'Отмена',
                                                        onOk: () => {
                                                            onCompleteShipment();
                                                        },
                                                    });
                                                }}
                                                disabled={entity?.status === JournalStatus.New || loading}
                                            >
                                                Завершить
                                            </Button>
                                        </Space>
                                    </Form.Item>
                                )}

                            {entity.status === JournalStatus.Cancelled && (
                                <Form.Item label='Причина отмены' name='cancelReason' wrapperCol={{ span: 8 }}>
                                    <TextArea rows={6} disabled={true} />
                                </Form.Item>
                            )}
                        </Form>
                    )}
                </Col>
            </Row>

            {renderToolbar()}
            {showFilter && renderFilter()}
            {renderTable()}

            <Row style={{ marginBottom: 15, marginTop: 15 }}>
                <Col span={24}>
                    <Space size={'small'} style={{ float: 'right' }}>
                        <Button type='text' onClick={() => navigate(`/warehouse/shipmentjournals`)}>
                            Закрыть
                        </Button>
                        {entity?.status === JournalStatus.New && (
                            <Button
                                type='primary'
                                onClick={() => {
                                    if (!hasPermission(userSession.permissions, Permission.ManageWarehouse)) {
                                        securityRestriction(api, [Permission.ManageWarehouse]);
                                        return;
                                    }

                                    modal.confirm({
                                        title: `Отправить журнал в работу?`,
                                        icon: <QuestionCircleOutlined />,
                                        okType: 'primary',
                                        okText: 'ОК',
                                        cancelText: 'Отмена',
                                        onOk: () => {
                                            onSetToProcess();
                                        },
                                    });
                                }}
                                loading={loading}
                            >
                                Отправить в работу
                            </Button>
                        )}
                    </Space>
                </Col>
            </Row>

            {contextHolder}
            {modalContextHolder}
        </>
    );
};

export default ShipmentJournal;
