import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc';
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: borderColor,
        alignItems: 'center',
        height: 15,
        textAlign: 'center',
        fontWeight: 600,
        flexGrow: 1,
    },

    marking: {
        width: '40%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    country: {
        width: '40%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    qty: {
        borderLeftColor: borderColor,
        width: '20%',
    },
});

const TableHeader = () => {
    return (
        <View style={styles.container}>
            <Text style={styles.marking}>Маркировка</Text>
            <Text style={styles.country}>Страна</Text>
            <Text style={styles.qty}>Количество</Text>
        </View>
    );
};

export default TableHeader;
