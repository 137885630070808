import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';

import { v4 as uuid } from 'uuid';

import { useElementSize } from 'usehooks-ts';

import type { GetRef } from 'antd';
import { Modal, Table, Select, Flex, Button, Tag, Tooltip, Form, Input, InputNumber, notification, Checkbox, Divider } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
    LoadingOutlined,
    ReloadOutlined,
    EditOutlined,
    CopyOutlined,
    DeleteOutlined,
    PlusOutlined,
    QuestionCircleOutlined,
    CheckOutlined,
    CheckCircleFilled,
    AppstoreAddOutlined,
    FilterFilled,
    MessageFilled,
} from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';
import Filter from '@controls/filter/filter';

import { exception, securityRestriction, warning } from '@extensions/notification';

import { userLoaded, setFilter } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { serverFetch } from '@src/core/server';

import { IUserSession } from '@entities/user-session';
import { ITariffHeader } from '@entities/tariff-header';
import { ITariff } from '@entities/tariff';
import { IBulkTariff } from '@entities/bulk-tariff';
import { ITariffFilter } from '@entities/tariff-filter';
import { ITariffLine } from '@entities/tariff-line';
import { ICity } from '@entities/city';
import { ICountry } from '@entities/country';
import { IUser } from '@entities/user';
import { ICargo } from '@entities/cargo';
import { ICountryItem } from '@entities/country-item';

import { Permission, hasPermission } from '@enums/permission';
import { UserType } from '@enums/user-type';

import { IDictionary } from '@src/core/types';

import { TruckIcon, AirplaneIcon } from '@icons/index';

import './tariffs.css';

type TableRef = GetRef<typeof Table>;

const filterContext: string = 'Tariffs';

const Tariffs = () => {
    const { TextArea } = Input;

    const tableRef = useRef<TableRef>(null);

    const [containerRef, { width, height }] = useElementSize();
    const [filterRef, { width: filterWidth, height: filterHeight }] = useElementSize();

    const [headerForm] = Form.useForm();
    const [bulkTariffForm] = Form.useForm();

    const navigate = useNavigate();
    const resolved = useResolvedPath('');

    const [api, contextHolder] = notification.useNotification();
    const [modal, modalContextHolder] = Modal.useModal();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);
    const filter = useAppSelector<ITariffFilter>((s) => s.filters[filterContext]);

    const [headers, setHeaders] = useState<Array<ITariffHeader>>([]);
    const [currentHeader, setCurrentHeader] = useState<ITariffHeader>();
    const [refreshHeadersRequired, setRefreshHeadersRequired] = useState<boolean>(true);
    const [loadingHeaders, setLoadingHeaders] = useState<boolean>(false);

    const [tariffs, setTariffs] = useState<Array<ITariff>>([]);
    const [currentTariff, setCurrentTariff] = useState<ITariff>();

    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const [refreshTariffsRequired, setRefreshTariffsRequired] = useState<boolean>(false);
    const [loadingTariffs, setLoadingTariffs] = useState<boolean>(false);
    const [copyInProcess, setCopyInProcess] = useState<boolean>(false);

    const [headerOpen, setHeaderOpen] = useState<boolean>(false);
    const [copyHeaderOpen, setCopyHeaderOpen] = useState<boolean>(false);
    const [bulkTariffOpen, setBulkTariffOpen] = useState<boolean>(false);
    const [bulkUpdating, setBulkUpdating] = useState<boolean>(false);

    const [showFilter, setShowFilter] = useState<boolean>(true);

    const [cities, setCities] = useState<Array<ICity>>([]);
    const [countries, setCountries] = useState<Array<ICountry>>([]);
    const [contractors, setContractors] = useState<Array<IUser>>([]);
    const [cargoes, setCargoes] = useState<Array<ICargo>>([]);
    const [countryItems, setCountryItems] = useState<Array<ICountryItem>>([]);

    const [lastChangedKey, setLastChangedKey] = useState<string>();

    const [bulkTariff, setBulkTariff] = useState<IBulkTariff>({
        ids: [],
        lines: [],
        setCommentRequired: false,
    });

    const [fieldSetRequirieds, setFieldSetRequirieds] = useState<IDictionary<boolean>>({});

    useEffect(() => {
        let cleanup = false;

        const fetchData = async () => {
            let promises = [
                await serverFetch('cargoes/all', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения авиаперевозчиков', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('cities', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения городов', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('users', { method: 'GET', queryParams: { types: UserType.Supplier, isActiveOnly: true } })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения поставщиков', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('countries', { method: 'GET', queryParams: { withTransportOnly: true } })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения стран', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('countries/items', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения продуктов', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setCargoes(result[0][0]);
                setCities(result[0][1]);
                setContractors(result[0][2]);

                let storedCountries: Array<ICountry> = result[0][3];
                setCountries(storedCountries.filter((e) => e.generateTariff));
                setCountryItems(result[0][4]);

                initBulkTariff();
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, []);

    const initBulkTariff = () => {
        bulkTariffForm.resetFields();

        let lines: Array<ITariffLine> = [];
        countries.map((c) => {
            let line: ITariffLine = {
                id: undefined,
                tariffId: undefined,
                countryId: c.id,
                unitType: c.unitType,
                countryName: c.name,
                details: [],
            };

            countryItems
                .filter((i) => i.countryId == c.id)
                .map((i) => {
                    cargoes
                        .filter((e) => e.countryId == c.id)
                        .map((e) => {
                            line.details.push({
                                id: undefined,
                                tariffLineId: undefined,
                                itemId: i.itemId,
                                itemName: i.itemName,
                                cargoId: e.id,
                                cargoName: e.name,
                                truckPrice: undefined,
                                flightPrice: undefined,
                            });
                        });

                    line.details.push({
                        id: undefined,
                        tariffLineId: undefined,
                        itemId: i.itemId,
                        itemName: i.itemName,
                        cargoId: undefined,
                        truckPrice: undefined,
                        flightPrice: undefined,
                    });
                });

            lines.push(line);
        });

        setBulkTariff({ ids: [], lines: lines, setCommentRequired: false });
    };

    useEffect(() => {
        if (countries) initBulkTariff();
    }, [countries]);

    useEffect(() => {
        if (!loadingTariffs && lastChangedKey) {
            tableRef.current?.scrollTo({ key: lastChangedKey });
            setLastChangedKey(undefined);
        }
    }, [loadingTariffs]);

    useEffect(() => {
        if (!refreshHeadersRequired) return;

        setHeaders([]);

        setRefreshHeadersRequired(false);

        setLoadingHeaders(true);

        serverFetch('tariffs/headers', { method: 'GET' })
            .then((data: Array<ITariffHeader>) => {
                setHeaders(data);

                let activeHeader = data.find((h) => h.isActive === true);
                setCurrentHeader(activeHeader);

                setLoadingHeaders(false);
            })
            .catch((ex) => {
                setLoadingHeaders(false);
                exception(api, 'Ошибка получения тарифов', ex, () => d(userLoaded(undefined)));
            });
    }, [refreshHeadersRequired]);

    useEffect(() => {
        if (!refreshTariffsRequired) return;

        setTariffs([]);

        setRefreshTariffsRequired(false);

        setLoadingTariffs(true);

        serverFetch('tariffs', { method: 'GET', queryParams: filter })
            .then((data: Array<ITariff>) => {
                setTariffs(data);
                setLoadingTariffs(false);
            })
            .catch((ex) => {
                setLoadingTariffs(false);
                exception(api, 'Ошибка получения деталей тарифа', ex, () => d(userLoaded(undefined)));
            });
    }, [refreshTariffsRequired]);

    useEffect(() => {
        setCurrentTariff(undefined);
        setTariffs([]);

        d(setFilter({ ...filter, headerId: currentHeader?.id }, filterContext));
    }, [currentHeader]);

    const onDelete = () => {
        if (!currentTariff) return;

        serverFetch(`tariffs/${currentTariff.id}`, { method: 'DELETE' })
            .then(() => {
                onSelectChange([]);
                setRefreshTariffsRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка удаления тарифова', ex, () => d(userLoaded(undefined)));
            });
    };

    const onAddHeader = (entity: ITariffHeader) => {
        serverFetch(`tariffs/headers`, { method: 'POST', bodyData: entity })
            .then(() => {
                headerForm.resetFields();

                setHeaderOpen(false);
                setRefreshHeadersRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка сохранения тарифа', ex, () => d(userLoaded(undefined)));
            });
    };

    const onCopyHeader = (entity: ITariffHeader) => {
        setCopyInProcess(true);

        serverFetch(`tariffs/headers/${currentHeader?.id}/copy`, { method: 'POST', bodyData: entity })
            .then(() => {
                headerForm.resetFields();

                setCopyHeaderOpen(false);
                setRefreshHeadersRequired(true);

                setCopyInProcess(false);
            })
            .catch((ex) => {
                setCopyInProcess(false);
                exception(api, 'Ошибка сохранения тарифа', ex, () => d(userLoaded(undefined)));
            });
    };

    const onDeleteHeader = () => {
        serverFetch(`tariffs/headers/${currentHeader?.id}`, { method: 'DELETE' })
            .then(() => {
                setRefreshHeadersRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка сохранения тарифа', ex, () => d(userLoaded(undefined)));
            });
    };

    const onActivate = () => {
        serverFetch(`tariffs/${currentHeader?.id}/activate`, { method: 'POST' })
            .then(() => {
                setRefreshHeadersRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка сохранения тарифа', ex, () => d(userLoaded(undefined)));
            });
    };

    const getHeaderlabel = (item: ITariffHeader) => {
        return (
            <div>
                <span style={{ marginRight: 10 }}>
                    {item.isActive === true ? <CheckCircleFilled style={{ color: 'var(--primary-color)' }} /> : ''}
                </span>
                <span>{item.name}</span>
            </div>
        );
    };

    const onGenerate = () => {
        setLoadingTariffs(true);

        serverFetch(`tariffs/${currentHeader?.id}/generate`, { method: 'POST' })
            .then(() => {
                setRefreshTariffsRequired(true);
            })
            .catch((ex) => {
                setLoadingTariffs(false);
                exception(api, 'Ошибка генерации тарифа', ex, () => d(userLoaded(undefined)));
            });
    };

    const onBulkUpdate = () => {
        if (!bulkTariff) return;

        let data = { ...bulkTariff };

        data.lines.map((l) => {
            l.details.map((d) => {
                d.setTruckPriceRequired = fieldSetRequirieds[`setTruckPriceRequired${l.countryId}${d.itemId}${d.cargoId}`];
                d.truckPrice = bulkTariffForm.getFieldValue(`truckPrice${l.countryId}${d.itemId}${d.cargoId}`);

                d.setFlightPriceRequired = fieldSetRequirieds[`setFlightPriceRequired${l.countryId}${d.itemId}${d.cargoId}`];
                d.flightPrice = bulkTariffForm.getFieldValue(`flightPrice${l.countryId}${d.itemId}${d.cargoId}`);
            });
        });

        modal.confirm({
            title: `Вы хотите применить массовые изменения?`,
            icon: <QuestionCircleOutlined />,
            okType: 'primary',
            okText: 'ОК',
            cancelText: 'Отмена',
            onOk: () => {
                setBulkUpdating(true);
                bulkTariff.ids = selectedIds as Array<string>;

                serverFetch(`tariffs/bulk`, { method: 'PUT', bodyData: bulkTariff })
                    .then(() => {
                        setBulkUpdating(false);

                        initBulkTariff();
                        setFieldSetRequirieds({});

                        setBulkTariffOpen(false);
                        setRefreshTariffsRequired(true);
                    })
                    .catch((ex) => {
                        setBulkUpdating(false);
                        exception(api, 'Ошибка массового изменения тарифа', ex, () => d(userLoaded(undefined)));
                    });
            },
        });
    };

    const renderHeaders = () => {
        return (
            <div className='header-container'>
                <Flex gap='small' align='center'>
                    <Select
                        placeholder='Тариф'
                        value={currentHeader?.id}
                        size='middle'
                        style={{ width: 300 }}
                        loading={loadingHeaders}
                        onChange={(value: string) => {
                            let header = headers.find((h) => h.id === value);
                            setCurrentHeader(header);
                        }}
                        options={headers.map((s) => {
                            return {
                                value: s.id,
                                label: getHeaderlabel(s),
                            };
                        })}
                    />
                    <Tooltip key='addHeader' placement='top' title='Добавить тариф'>
                        <Button
                            size='small'
                            shape='circle'
                            icon={<PlusOutlined />}
                            onClick={() => {
                                if (!hasPermission(userSession.permissions, Permission.ManageTransportTariffs)) {
                                    securityRestriction(api, [Permission.ManageTransportTariffs]);
                                    return;
                                }

                                setHeaderOpen(true);
                            }}
                            disabled={loadingHeaders}
                        />
                    </Tooltip>
                    <Tooltip key='copyHeader' placement='top' title='Скопировать тариф'>
                        <Button
                            size='small'
                            shape='circle'
                            icon={<CopyOutlined />}
                            onClick={() => {
                                if (!hasPermission(userSession.permissions, Permission.ManageTransportTariffs)) {
                                    securityRestriction(api, [Permission.ManageTransportTariffs]);
                                    return;
                                }

                                setCopyHeaderOpen(true);
                            }}
                            disabled={loadingHeaders}
                        />
                    </Tooltip>
                    <Tooltip key='deleteHeader' placement='top' title='Удалить тариф'>
                        <Button
                            size='small'
                            shape='circle'
                            icon={<DeleteOutlined />}
                            disabled={!currentHeader}
                            onClick={() => {
                                if (!hasPermission(userSession.permissions, Permission.DeleteTransportTariff)) {
                                    securityRestriction(api, [Permission.DeleteTransportTariff]);
                                    return;
                                }

                                modal.confirm({
                                    title: `Удалить тариф "${currentHeader?.name}"?`,
                                    icon: <QuestionCircleOutlined />,
                                    okType: 'primary',
                                    okText: 'Удалить',
                                    cancelText: 'Отмена',
                                    onOk: () => {
                                        onDeleteHeader();
                                    },
                                });
                            }}
                        />
                    </Tooltip>
                    <Tooltip key='activateHeader' placement='top' title='Активировать тариф'>
                        <Button
                            size='small'
                            shape='circle'
                            icon={<CheckOutlined />}
                            disabled={!currentHeader}
                            type='primary'
                            onClick={() => {
                                if (!hasPermission(userSession.permissions, Permission.ManageTransportTariffs)) {
                                    securityRestriction(api, [Permission.ManageTransportTariffs]);
                                    return;
                                }

                                if (tariffs.length <= 0) {
                                    warning(api, 'Активация тарифа', 'Добавьте хотя бы одну запись тарифа');
                                    return;
                                }

                                modal.confirm({
                                    title: `Сделать выбранный тариф "${currentHeader?.name}" активным?`,
                                    icon: <QuestionCircleOutlined />,
                                    okType: 'primary',
                                    okText: 'ОК',
                                    cancelText: 'Отмена',
                                    onOk: () => {
                                        onActivate();
                                    },
                                });
                            }}
                        />
                    </Tooltip>
                </Flex>
            </div>
        );
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: !currentHeader || loadingTariffs,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshTariffsRequired(true);
                        },
                    },
                    {
                        type: 'primary',
                        label: 'Добавить',
                        disabled: !currentHeader || loadingTariffs,
                        key: 'add',
                        icon: <PlusOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageTransportTariffs)) {
                                securityRestriction(api, [Permission.ManageTransportTariffs]);
                                return;
                            }

                            navigate(`${resolved.pathname}/new/${currentHeader?.id}`);
                        },
                    },
                    {
                        label: `${
                            hasPermission(userSession.permissions, Permission.ManageTransportTariffs) ? 'Изменить' : 'Информация о тарифе'
                        }`,
                        key: 'edit',
                        disabled: !selectedIds || selectedIds.length <= 0,
                        icon: <EditOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageTransportTariffs)) {
                                securityRestriction(api, [Permission.ManageTransportTariffs]);
                                return;
                            }

                            if (selectedIds.length === 1) {
                                setLastChangedKey(currentTariff?.id);
                                navigate(`${resolved.pathname}/${currentTariff?.id}`);
                            } else {
                                setBulkTariffOpen(true);
                            }
                        },
                    },
                    {
                        label: 'Копировать',
                        key: 'copy',
                        disabled: !currentTariff,
                        icon: <CopyOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageTransportTariffs)) {
                                securityRestriction(api, [Permission.ManageTransportTariffs]);
                                return;
                            }

                            navigate(`${resolved.pathname}/${currentTariff?.id}/${true}`);
                        },
                    },
                    {
                        label: 'Удалить',
                        key: 'delete',
                        disabled: !currentTariff,
                        icon: <DeleteOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.DeleteTransportTariff)) {
                                securityRestriction(api, [Permission.DeleteTransportTariff]);
                                return;
                            }

                            modal.confirm({
                                title: `Удалить тариф "${currentTariff?.markingCode} / ${currentTariff?.cityName}"?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'Удалить',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onDelete();
                                },
                            });
                        },
                    },
                    {
                        label: 'Сгенертровать',
                        key: 'generate',
                        disabled: !currentHeader || loadingTariffs,
                        icon: <AppstoreAddOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageTransportTariffs)) {
                                securityRestriction(api, [Permission.ManageTransportTariffs]);
                                return;
                            }

                            modal.confirm({
                                title: `Сгенерировать отсутствующие позиции для тарифа "${currentHeader?.name}"?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'ОК',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onGenerate();
                                },
                            });
                        },
                    },
                ]}
                farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setShowFilter(!showFilter);
                        },
                    },
                ]}
            />
        );
    };

    const renderFilter = () => {
        return (
            <div ref={filterRef}>
                <Filter
                    items={[
                        <Input
                            key='searchText'
                            placeholder='ID клиента / ИНН / Контрагент / Комментарий'
                            value={filter?.searchText}
                            style={{ width: 330 }}
                            onChange={(data) => {
                                d(setFilter({ ...filter, searchText: data.target.value }, filterContext));
                            }}
                        />,
                        <Input
                            key='markingCode'
                            placeholder='Маркировка'
                            value={filter?.markingCode}
                            onChange={(data) => {
                                d(setFilter({ ...filter, markingCode: data.target.value }, filterContext));
                            }}
                        />,
                        <Select
                            key='city'
                            placeholder='Расчетный город'
                            value={filter?.cityIds}
                            size='middle'
                            mode='multiple'
                            maxTagCount='responsive'
                            allowClear
                            style={{ width: 200 }}
                            onChange={(value) => d(setFilter({ ...filter, cityIds: value }, filterContext))}
                            filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                            filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                            options={cities.map((s) => {
                                return { value: s.id, label: s.name };
                            })}
                        />,
                        <Select
                            maxTagCount='responsive'
                            key='contractor'
                            placeholder='Поставщик'
                            value={filter?.contractorIds}
                            size='middle'
                            mode='multiple'
                            allowClear={true}
                            style={{ width: 200 }}
                            showSearch
                            onChange={(value) => d(setFilter({ ...filter, contractorIds: value }, filterContext))}
                            optionFilterProp='children'
                            filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                            filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                            options={contractors.map((c) => {
                                return { value: c.id, label: c.login };
                            })}
                        />,
                    ]}
                    onClear={() => d(setFilter({ headerId: currentHeader?.id }, filterContext))}
                />
            </div>
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let entity = tariffs.find((o) => o.id == selectedRowKeys[0]);
            setCurrentTariff(entity);
        } else {
            setCurrentTariff(undefined);
        }
    };

    const renderTable = () => {
        const columns: ColumnsType<ITariff> = [
            {
                title: 'ID',
                width: 60,
                align: 'center',
                dataIndex: 'consigneeCode',
                render: (_: any, record: ITariff) => {
                    return (
                        <>
                            <div>{record.consigneeCode}</div>
                            <div>{record.fromPlatina && <Tag color='#000000'>#PL</Tag>}</div>
                        </>
                    );
                },
            },
            {
                title: 'Маркировка',
                dataIndex: 'allMarkings',
                width: 300,
            },
            {
                title: 'ИНН',
                dataIndex: 'inn',
                width: 110,
            },
            {
                title: 'Контрагент',
                dataIndex: 'legalName',
                width: 200,
            },
            {
                title: 'Расчетный город',
                dataIndex: 'cityName',
                width: 200,
            },
            {
                title: 'Поставщик',
                width: 130,
                dataIndex: 'contractorName',
            },
            {
                width: 40,
                align: 'center',
                render: (_, record) => {
                    return (
                        record.comment && (
                            <Tooltip placement='topRight' title={record.comment}>
                                <div className='comment-btn'>
                                    <MessageFilled />
                                </div>
                            </Tooltip>
                        )
                    );
                },
            },
        ];

        countries.map((c) => {
            columns.push({
                title: c.name,
                width: 220,
                align: 'center',
                onCell: () => ({
                    style: {
                        padding: 0,
                    },
                }),
                render: (_: any, record) => {
                    let line = record.lines?.find((l) => l.countryId === c.id);
                    if (!line) return <></>;

                    return (
                        <>
                            {line.details?.map((d) => {
                                return (
                                    <Flex
                                        key={d.id}
                                        gap='small'
                                        align='center'
                                        justify='space-between'
                                        style={{
                                            background: d.cargoId ? '#f1f4f3' : '',
                                            borderBottom: d.cargoId ? '1px solid #d4dedb' : 'none',
                                            paddingLeft: 5,
                                            paddingRight: 5,
                                        }}
                                    >
                                        <div style={{ fontWeight: 500, width: 150, textAlign: 'left' }}>{d.itemName}</div>
                                        {d.cargoName && <div style={{ width: 100, textAlign: 'left' }}>{d.cargoName}</div>}
                                        {d.truckPrice && (
                                            <Tooltip
                                                title={d.cargoId ? 'Транспортная доставка с перелетом' : 'Транспортная доставка '}
                                                placement='topRight'
                                            >
                                                <Flex gap='small'>
                                                    <TruckIcon style={{ fontSize: 12, color: 'var(--main-green)' }} /> {d.truckPrice}
                                                </Flex>
                                            </Tooltip>
                                        )}
                                        {d.flightPrice && (
                                            <Tooltip title='Перелет' placement='topRight'>
                                                <Flex gap='small'>
                                                    <AirplaneIcon style={{ fontSize: 12 }} /> {d.flightPrice}
                                                </Flex>
                                            </Tooltip>
                                        )}
                                    </Flex>
                                );
                            })}
                        </>
                    );
                },
            });
        });

        columns.push({ width: 20 });
        columns.push({});

        return (
            <Table
                rowKey='id'
                ref={tableRef}
                size='small'
                rowSelection={{
                    selectedRowKeys: selectedIds,
                    onChange: onSelectChange,
                    type: 'checkbox',
                    columnWidth: 35,
                }}
                columns={columns}
                dataSource={tariffs}
                loading={{
                    spinning: loadingTariffs,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                onRow={(record: ITariff) => {
                    return {
                        onClick: (event) => {
                            if (!record.id) return;

                            onSelectChange([record.id]);
                        },
                    };
                }}
                pagination={false}
                virtual={true}
                scroll={{ y: height + (showFilter ? 0 : 58) }}
            />
        );
    };

    const renderBulkLines = () => {
        return bulkTariff?.lines.map((l) => {
            console.log('l.details', l.details);

            return (
                <div key={uuid()}>
                    <Form.Item wrapperCol={{ span: 24 }}>
                        <Divider orientation='left' style={{ marginBottom: 0 }}>
                            {l.countryName}
                        </Divider>
                    </Form.Item>
                    <>
                        {l.details.map((d) => {
                            return (
                                <Form.Item
                                    labelAlign='left'
                                    labelCol={{ span: 4 }}
                                    wrapperCol={{ span: 20 }}
                                    key={uuid()}
                                    label={d.itemName}
                                    style={{ marginBottom: 0 }}
                                >
                                    <Flex gap='middle'>
                                        <Flex gap='small'>
                                            <Form.Item>
                                                <Flex justify='flex-end' style={{ width: 200 }}>
                                                    <Tag color={d.cargoId ? 'var(--main-green)' : ''}>
                                                        {d.cargoName || 'Базовая доставка'}
                                                    </Tag>
                                                </Flex>
                                            </Form.Item>
                                            <Form.Item
                                                name={`setTruckPriceRequired${l.countryId}${d.itemId}${d.cargoId}`}
                                                style={{ width: 30 }}
                                                valuePropName='checked'
                                            >
                                                <Checkbox
                                                    onChange={(e) => {
                                                        let entities = { ...fieldSetRequirieds };
                                                        entities[`setTruckPriceRequired${l.countryId}${d.itemId}${d.cargoId}`] =
                                                            e.target.checked;
                                                        setFieldSetRequirieds(entities);
                                                    }}
                                                />
                                            </Form.Item>
                                            <Form.Item name={`truckPrice${l.countryId}${d.itemId}${d.cargoId}`} style={{ marginBottom: 0 }}>
                                                <InputNumber
                                                    decimalSeparator=','
                                                    addonAfter={
                                                        <Tooltip
                                                            title={
                                                                d.cargoId ? 'Транспортная доставка с перелетом' : 'Транспортная доставка '
                                                            }
                                                            placement='topRight'
                                                        >
                                                            <div>
                                                                <TruckIcon style={{ color: d.cargoId ? 'var(--main-green)' : '' }} />
                                                            </div>
                                                        </Tooltip>
                                                    }
                                                    min={0}
                                                    disabled={
                                                        !fieldSetRequirieds[`setTruckPriceRequired${l.countryId}${d.itemId}${d.cargoId}`]
                                                    }
                                                />
                                            </Form.Item>
                                        </Flex>

                                        <Flex gap='small' style={{ visibility: d.cargoId ? 'visible' : 'hidden' }}>
                                            <Form.Item
                                                name={`setFlightPriceRequired${l.countryId}${d.itemId}${d.cargoId}}`}
                                                style={{ width: 30 }}
                                                valuePropName='checked'
                                            >
                                                <Checkbox
                                                    onChange={(e) => {
                                                        let entities = { ...fieldSetRequirieds };
                                                        entities[`setFlightPriceRequired${l.countryId}${d.itemId}${d.cargoId}`] =
                                                            e.target.checked;
                                                        setFieldSetRequirieds(entities);
                                                    }}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                name={`flightPrice${l.countryId}${d.itemId}${d.cargoId}`}
                                                style={{ marginBottom: 0 }}
                                            >
                                                <InputNumber
                                                    decimalSeparator=','
                                                    addonAfter={
                                                        <Tooltip title='Перелет' placement='topRight'>
                                                            <div>
                                                                <AirplaneIcon />
                                                            </div>
                                                        </Tooltip>
                                                    }
                                                    min={0}
                                                    step='0.1'
                                                    stringMode
                                                    disabled={
                                                        !fieldSetRequirieds[`setFlightPriceRequired${l.countryId}${d.itemId}${d.cargoId}`]
                                                    }
                                                />
                                            </Form.Item>
                                        </Flex>
                                    </Flex>
                                </Form.Item>
                            );
                        })}
                    </>
                </div>
            );
        });
    };

    return (
        <div ref={containerRef} style={{ height: `calc(100vh - 243px - ${filterHeight}px)` }}>
            {renderHeaders()}
            {renderToolbar()}
            {showFilter && renderFilter()}
            {renderTable()}

            <Modal
                title='Новый тариф'
                open={headerOpen}
                okText='Сохранить'
                onOk={() => headerForm.submit()}
                onCancel={() => {
                    headerForm.resetFields();
                    setHeaderOpen(false);
                }}
            >
                <Form colon={false} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} onFinish={onAddHeader} form={headerForm}>
                    <Form.Item
                        required={true}
                        label='Название'
                        name='name'
                        rules={[{ required: true, message: 'Укажите название тарифа' }]}
                    >
                        <Input autoFocus />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title={`Копия тарифа "${currentHeader?.name}"`}
                open={copyHeaderOpen}
                okText='Сохранить'
                confirmLoading={copyInProcess}
                onOk={() => headerForm.submit()}
                cancelButtonProps={{ style: { display: copyInProcess ? 'none' : '' } }}
                onCancel={() => {
                    headerForm.resetFields();
                    setCopyHeaderOpen(false);
                }}
            >
                <Form colon={false} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} onFinish={onCopyHeader} form={headerForm}>
                    <Form.Item
                        required={true}
                        label='Название'
                        name='name'
                        rules={[{ required: true, message: 'Укажите название тарифа' }]}
                    >
                        <Input autoFocus />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                destroyOnClose={true}
                title='Массовое редактирование тарифа'
                open={bulkTariffOpen}
                okText='Сохранить'
                onOk={() => bulkTariffForm.submit()}
                cancelButtonProps={{ style: { display: bulkUpdating ? 'none' : '' } }}
                confirmLoading={bulkUpdating}
                onCancel={() => {
                    initBulkTariff();
                    setFieldSetRequirieds({});
                    setBulkTariffOpen(false);
                }}
                width={800}
            >
                <Form
                    colon={false}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    onFinish={onBulkUpdate}
                    form={bulkTariffForm}
                    style={{ marginTop: 20 }}
                >
                    <Form.Item label='Комментарий' name='comment'>
                        <Flex gap='middle'>
                            <Form.Item name='setComment' valuePropName='checked'>
                                <Checkbox
                                    onChange={(e) => {
                                        setBulkTariff({ ...bulkTariff, setCommentRequired: e.target.checked });
                                    }}
                                />
                            </Form.Item>
                            <Form.Item name='comment' style={{ width: '100%' }}>
                                <TextArea
                                    disabled={!bulkTariff?.setCommentRequired}
                                    rows={4}
                                    autoSize={false}
                                    onChange={(data) => {
                                        setBulkTariff({ ...bulkTariff, comment: data.target.value });
                                    }}
                                />
                            </Form.Item>
                        </Flex>
                    </Form.Item>

                    {bulkTariffOpen && renderBulkLines()}
                </Form>
            </Modal>

            {contextHolder}
            {modalContextHolder}
        </div>
    );
};

export default Tariffs;
