export enum DeliveryType {
    OnHand = 1,
    Supplier = 2,
}

export const enumLabel = (value?: DeliveryType) => {
    switch (value) {
        case DeliveryType.OnHand:
            return 'На руки';
        case DeliveryType.Supplier:
            return 'До поставщика';
        default:
            return '';
    }
};
