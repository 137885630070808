import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { v4 as uuid } from 'uuid';

import 'core-js/actual/array/group';

const borderColor = '#90e5fc';

const TableRow = ({ boxGroup }) => {
    const styles = StyleSheet.create({
        row: {
            flexDirection: 'row',
            alignItems: 'right',
            textAlign: 'center',
        },

        marking: {
            paddingTop: 2,
            width: '40%',
            borderRightColor: borderColor,
            borderRightWidth: 1,
            borderTopColor: borderColor,
            borderTopWidth: 1,
        },
        country: {
            paddingTop: 2,
            width: '40%',
            borderRightColor: borderColor,
            borderRightWidth: 1,
            borderTopColor: borderColor,
            borderTopWidth: 1,
        },
        qty: {
            paddingTop: 2,
            width: '20%',
            borderTopColor: borderColor,
            borderTopWidth: 1,
        },

        footer: {
            width: '100%',
            borderTopColor: borderColor,
            borderTopWidth: 1,
        },
    });

    const rows = [];
    rows.push(
        <View style={styles.row} key={boxGroup.markingCode}>
            <Text style={styles.marking}>{boxGroup.markingCode}</Text>
            <Text style={styles.country}>{boxGroup.countryName}</Text>
            <Text style={styles.qty}>{boxGroup.qty}</Text>
        </View>
    );

    rows.push(
        <View style={styles.row} key={uuid()}>
            <Text style={styles.footer}></Text>
        </View>
    );

    return <Fragment>{rows}</Fragment>;
};

export default TableRow;
