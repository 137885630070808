import React, { useState, useEffect } from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';
import { notification } from 'antd';

import { Modal, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { LoadingOutlined, ReloadOutlined, EditOutlined, DeleteOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';

import { exception, securityRestriction } from '@extensions/notification';
import { Permission, hasPermission } from '@enums/permission';

import { userLoaded } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';

import { IUserSession } from '@entities/user-session';
import { IItem } from '@entities/item';

import { serverFetch } from '@src/core/server';

const Items = () => {
    const navigate = useNavigate();
    const resolved = useResolvedPath('');
    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [items, setItems] = useState<Array<IItem>>([]);
    const [currentItem, setCurrentItem] = useState<IItem>();

    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!refreshRequired) return;

        setRefreshRequired(false);

        setItems([]);

        setLoading(true);

        serverFetch('items', { method: 'GET' })
            .then((data) => {
                setItems(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения продуктов', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    }, [refreshRequired]);

    const onDelete = () => {
        if (!currentItem) return;

        serverFetch(`items/${currentItem.id}`, { method: 'DELETE' })
            .then(() => {
                onSelectChange([]);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка удаления продукта', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        type: 'primary',
                        label: 'Добавить',
                        key: 'add',
                        icon: <PlusOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageItems)) {
                                securityRestriction(api, [Permission.ManageItems]);
                                return;
                            }

                            navigate(`${resolved.pathname}/new`);
                        },
                    },
                    {
                        label: `${hasPermission(userSession.permissions, Permission.ManageItems) ? 'Изменить' : 'Информация о продукте'}`,
                        key: 'edit',
                        disabled: !currentItem,
                        icon: <EditOutlined />,
                        onClick: () => {
                            navigate(`${resolved.pathname}/${currentItem?.id}`);
                        },
                    },
                    {
                        label: 'Удалить',
                        key: 'delete',
                        disabled: !currentItem,
                        icon: <DeleteOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.DeleteItem)) {
                                securityRestriction(api, [Permission.DeleteItem]);
                                return;
                            }

                            Modal.confirm({
                                title: `Удалить продукт "${currentItem?.name}"?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'Удалить',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onDelete();
                                },
                            });
                        },
                    },
                ]}
            />
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let entity = items.find((o) => o.id == selectedRowKeys[0]);
            setCurrentItem(entity);
        } else {
            setCurrentItem(undefined);
        }
    };

    const renderTable = () => {
        const columns: ColumnsType<IItem> = [
            {
                title: '#',
                dataIndex: 'sequence',
                align: 'center',
                width: 60,
            },
            {
                title: 'Название',
                dataIndex: 'name',
                width: 300,
            },
            {
                title: 'Код',
                dataIndex: 'code',
                width: 80,
            },
            {},
        ];

        const tableLoading = {
            spinning: loading,
            indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
        };

        return (
            <Table
                rowKey='id'
                size='small'
                rowSelection={{
                    selectedRowKeys: selectedIds,
                    onChange: onSelectChange,
                    type: 'radio',
                }}
                columns={columns}
                dataSource={items}
                loading={tableLoading}
                onRow={(record: IItem) => {
                    return {
                        onClick: (event) => {
                            if (!record.id) return;
                            onSelectChange([record.id]);
                        },
                    };
                }}
                pagination={false}
                scroll={{ y: `calc(100vh - 175px)` }}
            />
        );
    };

    return (
        <>
            {renderToolbar()}
            {renderTable()}

            {contextHolder}
        </>
    );
};

export default Items;
