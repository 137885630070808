import React from 'react';
import Icon from '@ant-design/icons';

const PayOffDebtIcon = ({ ...props }) => {
    return <Icon component={svg} style={{ fontSize: 18, ...props.style }} />;
};
export default PayOffDebtIcon;

const svg = ({ ...props }) => (
    <svg fill='currentColor' {...props} version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 489.875 489.875'>
        <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
        <g id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'></g>
        <g id='SVGRepo_iconCarrier'>
            <g>
                <path d='M212.637,79.125l67.8-67.8c15.1-15.1,39.7-15.1,54.8,0l67.8,67.8H212.637z M240.738,15.525h-97.3 c-21.4,0-38.8,17.4-38.8,38.8v24.8h72.6L240.738,15.525z M250.138,476.125h94.1c20.7,0,37.5-16.8,37.5-37.5v-25.3h-68.8 L250.138,476.125z M86.037,413.425l65.5,65.5c14.6,14.6,38.4,14.6,53,0l65.5-65.5H86.037z M368.538,223.025 c-13,0-23.5,10.5-23.5,23.5c0,13,10.5,23.5,23.5,23.5s23.5-10.5,23.5-23.5C392.038,233.525,381.438,223.025,368.538,223.025z M121.337,223.025c-13,0-23.5,10.5-23.5,23.5c0,13,10.5,23.5,23.5,23.5s23.5-10.5,23.5-23.5 C144.938,233.525,134.337,223.025,121.337,223.025z M468.638,146.525v199.5c0,21.4-17.4,38.8-38.8,38.8h-369.8 c-21.4,0-38.8-17.4-38.8-38.8v-199.5c0-21.4,17.4-38.8,38.8-38.8h369.9C451.237,107.725,468.638,125.125,468.638,146.525z M435.538,188.425c-3.1,0.8-6.4,1.2-9.7,1.2c-21.7,0-39.2-17.6-39.2-39.2c0-3.3,0.4-6.5,1.2-9.6h-288c0.4,2.3,0.7,4.8,0.7,7.2 c0,21.7-17.6,39.2-39.2,39.2c-2.4,0-4.7-0.2-6.9-0.6v119.2c2.2-0.4,4.5-0.6,6.9-0.6c21.7,0,39.2,17.6,39.2,39.2 c0,2.5-0.2,4.9-0.7,7.2h287.1c-0.2-1.6-0.3-3.2-0.3-4.9c0-21.7,17.6-39.2,39.2-39.2c3.4,0,6.6,0.4,9.7,1.2L435.538,188.425 L435.538,188.425z M329.837,246.525c0,46.8-38.1,84.9-84.9,84.9s-84.9-38.1-84.9-84.9s38.1-84.9,84.9-84.9 S329.837,199.725,329.837,246.525z M283.038,268.225l-21.7-21.7l21.7-21.7l-16.4-16.4l-21.7,21.8l-21.7-21.7l-16.4,16.4l21.7,21.7 l-21.7,21.7l16.4,16.4l21.7-21.8l21.7,21.7L283.038,268.225z'></path>
            </g>
        </g>
    </svg>
);
