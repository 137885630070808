import React, { useState, useEffect } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';

import { isMobile } from 'react-device-detect';
import { Space, Button, Layout, Menu, Avatar, Badge } from 'antd';
import { SettingFilled, MessageFilled } from '@ant-design/icons';

import { useAppSelector, useAppDispatch } from '@store/hooks';
import { setCurrentMenuItem, userLoaded, setRestartServiceRequired } from '@store/actions';
import { serverFetch } from '@src/core/server';

import { IUserSession } from '@entities/user-session';

import { Permission, hasPermission } from '@enums/permission';
import {
    WarehouseIcon,
    AcceptanceIcon,
    LogisticsIcon,
    ImportTruckIcon,
    DeliveryTruckIcon,
    CartIcon,
    BoxesIcon,
    LoaderIcon,
    MovingIcon,
    CalculatorIcon,
    AuditReportIcon,
    TariffIcon,
    CustomerRateIcon,
    BillIcon,
    UsdIcon,
    DebtIcon,
    CreditCardIcon,
    MiniLogoIcon,
    MainLogoIcon,
} from '@icons/index';

import '@src/core/index.css';

const getDefaultOpenKey = (menuItem: string) => {
    switch (menuItem) {
        case 'import':
        case 'delivery':
            return 'logistics';

        case 'acceptance':
        case 'extraconsignments':
        case 'remainings':
        case 'shipmentjournals':
        case 'transferlogs':
        case 'discrepancies':
            return 'warehouse';

        case 'bills':
        case 'tariffs':
            return 'finances';

        case 'countries':
        case 'cities':
        case 'waypoints':
        case 'warehouses':
        case 'consignees':
        case 'packagetemplates':
        case 'tradingplatforms':
        case 'transporttariffs':
        case 'users':
        case 'companies':
        case 'contacts':
        case 'accounts':
        case 'debts':
        case 'items':
            return 'settings';

        default:
            return '';
    }
};

const MainLayout = () => {
    const { Header, Content, Sider } = Layout;

    const location = useLocation();

    const d = useAppDispatch();
    const currentMenuItem = useAppSelector<string>((s) => s.currentMenuItem);
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);
    const restartServiceRequired = useAppSelector<boolean>((s) => s.restartServiceRequired);

    const [defaultOpenKey, setDefaultOpenKey] = useState(getDefaultOpenKey(currentMenuItem));
    const [activeFeedbacksQty, setActiveFeedbacksQty] = useState<number | undefined>();

    const [viewSetup] = useState<boolean>(hasPermission(userSession.permissions, Permission.ViewSetup));
    const [viewAudit] = useState<boolean>(hasPermission(userSession.permissions, Permission.FullAccess));

    const [siderCollapsed, setSiderCollapsed] = useState<boolean>(isMobile);

    function getItem(label: any, key: string, urlTo?: string, icon?: any, children?: Array<any>) {
        let itemlabel = urlTo ? <Link to={urlTo}>{label}</Link> : label;

        return {
            key,
            icon,
            children,
            label: itemlabel,
        };
    }

    useEffect(() => {
        let items = location.pathname.split('/');

        let key;
        switch (items[1]) {
            case 'logistics':
            case 'settings':
            case 'warehouse':
            case 'finances':
                key = items[2];
                break;
            default:
                key = items[1];
        }

        d(setCurrentMenuItem(key));

        getActiveFeedbacks();

        const interval = setInterval(() => {
            getActiveFeedbacks();
        }, 60000); //per 1 min

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        getActiveFeedbacks();
    }, [restartServiceRequired]);

    const getActiveFeedbacks = async () => {
        d(setRestartServiceRequired(false));

        await serverFetch('feedbacks/active', { method: 'GET' })
            .then((data) => {
                setActiveFeedbacksQty(data);
            })
            .catch((ex) => {
                setActiveFeedbacksQty(undefined);
            });
    };

    const onLogout = () => {
        serverFetch(`auth/logout`, { method: 'POST' })
            .then(() => {
                d(userLoaded(undefined));
            })
            .catch(() => {
                d(userLoaded(undefined));
            });
    };

    useEffect(() => {
        setDefaultOpenKey(getDefaultOpenKey(currentMenuItem));
    }, [currentMenuItem]);

    const items = [
        getItem('Логистика', 'logistics', undefined, <LogisticsIcon />, [
            getItem('Импорт', 'import', '/logistics/import', <ImportTruckIcon />),
            getItem('Доставка', 'delivery', '/logistics/delivery', <DeliveryTruckIcon />),
        ]),
        getItem('Склад', 'warehouse', undefined, <WarehouseIcon style={{ fontSize: 16 }} />, [
            getItem('Приемка', 'acceptance', '/warehouse/acceptance', <AcceptanceIcon />),
            getItem('Догруз', 'extraconsignments', '/warehouse/extraconsignments', <CartIcon />),
            getItem('Остатки', 'remainings', '/warehouse/remainings', <BoxesIcon />),
            getItem('Отгрузка', 'shipmentjournals', '/warehouse/shipmentjournals', <LoaderIcon />),
            getItem('Перемещения', 'transferlogs', '/warehouse/transferlogs', <MovingIcon style={{ fontSize: 16 }} />),
            getItem('Расхождения', 'discrepancies', '/warehouse/discrepancies', <CalculatorIcon />),
        ]),
    ];

    if (
        hasPermission(userSession.permissions, Permission.ManageTruckTariff) ||
        hasPermission(userSession.permissions, Permission.SetPaid) ||
        hasPermission(userSession.permissions, Permission.ManageTariff)
    ) {
        const finItems = [];

        if (
            hasPermission(userSession.permissions, Permission.ManageTruckTariff) ||
            hasPermission(userSession.permissions, Permission.SetPaid)
        ) {
            finItems.push(getItem('Счета на оплату', 'bills', '/finance/bills', <BillIcon />));
        }

        if (hasPermission(userSession.permissions, Permission.ManageBillDebts)) {
            finItems.push(getItem('Задолженности', 'billdebts', '/finance/billdebts', <DebtIcon style={{ fontSize: 20 }} />));
        }

        if (hasPermission(userSession.permissions, Permission.ManageBillDebts)) {
            finItems.push(getItem('Балансы', 'balances', '/finance/balances', <CreditCardIcon style={{ fontSize: 22 }} />));
        }

        if (hasPermission(userSession.permissions, Permission.ManageTariff)) {
            finItems.push(getItem('Тарифы', 'tariffs', '/finance/tariffs', <TariffIcon />));
        }

        items.push(getItem('Финансы', 'finances', undefined, <UsdIcon style={{ fontSize: 16 }} />, finItems));
    }

    items.push(
        getItem(
            <>
                Отзывы
                <Badge count={activeFeedbacksQty} overflowCount={10} offset={[10, -1]}></Badge>
            </>,
            'feedbacks',
            'feedbacks',
            <CustomerRateIcon />
        ),
        getItem('Уведомления', 'notifications', 'notifications', <MessageFilled />)
    );

    if (viewSetup) {
        items.push(
            getItem('Управление', 'settings', undefined, <SettingFilled />, [
                getItem('Грузополучатели', 'consignees', '/settings/consignees'),
                getItem('Пользователи', 'users', '/settings/users'),
                getItem('Контрагенты', 'companies', '/settings/companies'),
                getItem('Маршрутные точки', 'waypoints', '/settings/waypoints'),
                getItem('Транспортные тарифы', 'transporttariffs', '/settings/transporttariffs'),
                getItem('Упаковка', 'packagetemplates', '/settings/packagetemplates'),
                getItem('Города', 'cities', '/settings/cities'),
                getItem('Склады', 'warehouses', '/settings/warehouses'),
                getItem('Страны', 'countries', '/settings/countries'),
                getItem('Теги', 'tags', '/settings/tags'),
                getItem('Торговые площадки', 'tradingplatforms', '/settings/tradingplatforms'),
                getItem('Расчетные счета', 'accounts', '/settings/accounts'),
                getItem('Долги', 'debts', '/settings/debts'),
                getItem('Продукты', 'items', '/settings/items'),
                getItem('Контакты', 'contacts', '/settings/contacts'),
            ])
        );
    }

    if (viewAudit) {
        items.push(getItem('Аудит журналы', 'auditlogs', '/auditlogs', <AuditReportIcon />));
    }

    const onMenuClick = (e: any) => {
        d(setCurrentMenuItem(e.key));
    };

    const userMenuItems = [
        getItem(
            <Space style={{ cursor: 'pointer' }}>
                <Avatar
                    style={{ backgroundColor: '#fff' }}
                    size='large'
                    src='https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'
                />
                <div className='user'>{userSession.fullName}</div>
            </Space>,
            'account',
            undefined,
            undefined,
            [getItem('Личный кабинет', 'useraccount', '/useraccount', undefined)]
        ),
    ];

    const getLogo = () => {
        return (
            <div className='logo'>
                {siderCollapsed ? (
                    <MiniLogoIcon style={{ fontSize: 32, display: 'table-cell', verticalAlign: 'middle' }} />
                ) : (
                    <MainLogoIcon style={{ fontSize: 48, display: 'table-cell', verticalAlign: 'middle' }} />
                )}
            </div>
        );
    };

    return (
        <Layout style={{ height: '100vh', minHeight: '100vh' }}>
            <Sider
                collapsible={!isMobile}
                collapsed={siderCollapsed}
                onCollapse={(e) => setSiderCollapsed(e)}
                width={250}
                style={{
                    overflow: 'auto',
                    height: '100vh',
                }}
            >
                {getLogo()}
                <Menu
                    defaultOpenKeys={[defaultOpenKey]}
                    selectedKeys={[currentMenuItem]}
                    mode='inline'
                    items={items}
                    onClick={onMenuClick}
                />
            </Sider>
            <Layout>
                <Header className='main-header'>
                    <Space style={{ float: 'right' }}>
                        <Menu items={userMenuItems} mode='horizontal' style={{ width: 500, backgroundColor: '#ffffff' }} />
                        <Button type='link' className='logout' onClick={() => onLogout()}>
                            Выход
                        </Button>
                    </Space>
                </Header>
                <Content className='main-content'>
                    <Outlet />
                </Content>
            </Layout>
        </Layout>
    );
};

export default MainLayout;
