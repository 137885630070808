import React from 'react';
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import TableHeader from './table-header';
import TableRow from './table-row';
import { v4 as uuid } from 'uuid';

const borderColor = '#90e5fc';

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'column',
        flexWrap: 'wrap',
        marginBottom: 5,
        borderWidth: 1,
        borderColor: borderColor,
        borderBottom: 'none',
        width: '60%',
        marginTop: 5,
    },

    infoContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
    },

    infoValue: {
        fontWeight: 600,
    },
});
const PdfPage = ({ header }) => {
    return (
        <View>
            <View style={styles.infoContainer}>
                <Text>Клиент: </Text>
                <Text style={styles.infoValue}>
                    {header?.consigneeCode} / {header?.consigneeName}
                </Text>
            </View>
            <View style={styles.infoContainer}>
                <Text>Тел. клиент: </Text>
                <Text style={styles.infoValue}>{header?.consigneePhone}</Text>
            </View>
            <View style={styles.infoContainer}>
                <Text>Адрес доставки: </Text>
                <Text style={styles.infoValue}>
                    {header?.cityName}, {header?.consigneeAddress}
                </Text>
            </View>
            {header?.boxGroups?.map((g) => {
                return (
                    <View key={uuid()} style={styles.tableContainer}>
                        <TableHeader />
                        <TableRow boxGroup={g} />
                    </View>
                );
            })}
        </View>
    );
};

export default PdfPage;
