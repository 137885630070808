import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { notification } from 'antd';

import { Button, Input, InputNumber, Form, Space } from 'antd';

import FormHeader from '@controls/form-header/form-header';

import { exception } from '@extensions/notification';
import { Permission, hasPermission } from '@enums/permission';

import { userLoaded } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { IUserSession } from '@entities/user-session';
import { ICity } from '@entities/city';

const City = () => {
    const { id } = useParams();

    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [entity, setEntity] = useState<ICity>({
        name: '',
        distance: 0,
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [manageCity] = useState<boolean>(hasPermission(userSession.permissions, Permission.ManageCities));

    useEffect(() => {
        initData();
    }, []);

    const initData = () => {
        if (!id) {
            return;
        }

        setLoading(true);

        serverFetch(`cities/${id}`, { method: 'GET' })
            .then((data) => {
                setEntity(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения города', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    };

    const onSave = () => {
        setLoading(true);

        serverFetch(`cities`, { method: id ? 'PUT' : 'POST', bodyData: entity })
            .then(() => {
                setLoading(false);
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка сохранения города', ex, () => d(userLoaded(undefined)));
            });
    };

    return (
        <>
            <FormHeader title={`${id ? (!manageCity ? 'Информация о городе' : 'Изменить город') : 'Добавить город'}`} />
            {!loading && (
                <Form colon={false} labelCol={{ span: 3 }} wrapperCol={{ span: 8 }} onFinish={onSave}>
                    <Form.Item
                        initialValue={entity.name}
                        required
                        label='Название'
                        name='name'
                        rules={[{ required: true, message: 'Укажите название города' }]}
                    >
                        <Input
                            disabled={!manageCity}
                            autoFocus
                            onChange={(data) => {
                                setEntity({ ...entity, name: data.target.value });
                            }}
                        />
                    </Form.Item>
                    <Form.Item initialValue={entity.distance} label='Расстояние' name='distance'>
                        <InputNumber
                            disabled={!manageCity}
                            addonAfter='км'
                            onChange={(value) => {
                                setEntity({ ...entity, distance: value as number });
                            }}
                        />
                    </Form.Item>
                    <Form.Item label='' wrapperCol={{ offset: 3, span: 8 }}>
                        <Space size={'small'} style={{ float: 'right' }}>
                            <Button type='text' onClick={() => navigate(-1)}>
                                Отменить
                            </Button>
                            {manageCity && (
                                <Button type='primary' htmlType='submit' loading={loading}>
                                    Сохранить
                                </Button>
                            )}
                        </Space>
                    </Form.Item>
                </Form>
            )}

            {contextHolder}
        </>
    );
};

export default City;
