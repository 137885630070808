import React from 'react';
import Icon from '@ant-design/icons';

const LoaderIcon = ({ ...props }) => {
    return <Icon component={svg} style={{ fontSize: 18, ...props.style }} />;
};
export default LoaderIcon;

const svg = ({ ...props }) => (
    <svg {...props} fill='currentColor' viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'>
        <g id='SVGRepo_bgCarrier'></g>
        <g id='SVGRepo_tracerCarrier'></g>
        <g id='SVGRepo_iconCarrier'>
            <path d='M21.984375 3.9863281 A 1.0001 1.0001 0 0 0 21 5L21 16.425781C20.60421 16.251297 20.182772 16.12702 19.742188 16.060547L16.386719 6L17 6 A 1.0001 1.0001 0 1 0 17 4L15.720703 4L8 4C6.3550302 4 5 5.3550302 5 7L5 15L4 15C3.448 15 3 15.448 3 16L3 21L3 22.5C3 24.421152 4.578848 26 6.5 26C7.8831519 26 9.0777003 25.175306 9.6445312 24L15.029297 24C15.944421 25.206267 17.378383 26 19 26C21.038595 26 22.798152 24.760386 23.574219 23L28 23 A 1.0001 1.0001 0 1 0 28 21L24 21C24 19.887976 23.618387 18.868039 22.998047 18.035156 A 1.0001 1.0001 0 0 0 23 18L23 5 A 1.0001 1.0001 0 0 0 21.984375 3.9863281 z M 8 6L14.279297 6L17.679688 16.201172C16.224835 16.605207 15.026819 17.63664 14.425781 19L13.333984 19L12.228516 15.683594C12.092516 15.274594 11.709297 15 11.279297 15L9.7988281 15L9.3203125 12.607422C9.1333125 11.672422 8.312375 11 7.359375 11L7 11L7 7C7 6.4349698 7.4349698 6 8 6 z M 19 18C20.668699 18 22 19.331301 22 21C22 22.668699 20.668699 24 19 24C17.331301 24 16 22.668699 16 21C16 19.331301 17.331301 18 19 18 z M 19 20 A 1 1 0 0 0 18 21 A 1 1 0 0 0 19 22 A 1 1 0 0 0 20 21 A 1 1 0 0 0 19 20 z M 6.5 21C7.3402718 21 8 21.659728 8 22.5C8 23.340272 7.3402718 24 6.5 24C5.6597282 24 5 23.340272 5 22.5C5 21.659728 5.6597282 21 6.5 21 z'></path>
        </g>
    </svg>
);
