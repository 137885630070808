import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc';
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: borderColor,
        alignItems: 'center',
        height: 15,
        maxHeight: 15,
        textAlign: 'center',
        fontWeight: 600,
        fontSize: 7,
        flexGrow: 1,
    },

    consigneeCode: {
        width: 40,
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },

    marking: {
        width: 200,
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    phone: {
        width: 100,
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    country: {
        width: 100,
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    qty: {
        width: 70,
    },
});

const TableHeader = () => {
    return (
        <View style={styles.container}>
            <Text style={styles.consigneeCode}>ID</Text>
            <Text style={styles.marking}>Маркировка</Text>
            <Text style={styles.phone}>Контакт</Text>
            <Text style={styles.country}>Страна</Text>
            <Text style={styles.qty}>Количество</Text>
        </View>
    );
};

export default TableHeader;
