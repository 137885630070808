export enum NotificationType {
    Push = 1,
}

export const enumLabel = (value: NotificationType) => {
    switch (value) {
        case NotificationType.Push:
            return 'Push';

        default:
            return '';
    }
};
