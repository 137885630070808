import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Modal, Button, Input, InputNumber, Form, Space, Select, Flex, Tooltip, Divider, Tag, notification } from 'antd';
import { UndoOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import { v4 as uuid } from 'uuid';

import FormHeader from '@controls/form-header/form-header';
import Toolbar from '@controls/toolbar/toolbar';

import { exception, securityRestriction } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { ICity } from '@entities/city';
import { ICountry } from '@entities/country';
import { IUserSession } from '@entities/user-session';
import { ITransportTariff } from '@entities/transport-tariff';
import { ITariffLine } from '@entities/tariff-line';
import { ITariff } from '@entities/tariff';
import { IConsigneeTariffSetting } from '@entities/consignee-tariff-setting';
import { IConsignee } from '@entities/consignee';
import { ICargo } from '@entities/cargo';
import { ICountryItem } from '@entities/country-item';

import { Permission, hasPermission } from '@enums/permission';
import { UnitType } from '@enums/unit-type';

import { TruckIcon, AirplaneIcon } from '@icons/index';

const Tariff = () => {
    const { TextArea } = Input;
    const [form] = Form.useForm();

    const { headerId, id, isCopy } = useParams();

    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const [modal, modalContextHolder] = Modal.useModal();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [entity, setEntity] = useState<ITariff>({
        id: undefined,
        headerId: headerId,
        consigneeId: undefined,
        cityId: undefined,
        lines: [],
    });

    const [consignees, setConsignees] = useState<Array<IConsignee>>([]);
    const [tariffSettings, setTariffSettings] = useState<Array<IConsigneeTariffSetting>>([]);
    const [cities, setCities] = useState<Array<ICity>>([]);
    const [transportTariffs, setTransportTariffs] = useState<Array<ITransportTariff>>([]);

    const [loading, setLoading] = useState<boolean>(false);
    const [canManage] = useState<boolean>(hasPermission(userSession.permissions, Permission.ManageTradingPlatforms));

    useEffect(() => {
        let cleanup = false;

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch('countries', { method: 'GET', queryParams: { withTransportOnly: true } })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения стран', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('countries/items', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения продуктов', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('cargoes/all', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения авиаперевозчиков', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('consignees', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения грузополучателей', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('cities', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения городов', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch(`consignees/tariffsettings`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения настроек тарифа грузополучателей', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('transportTariffs', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения транспортного тарифа', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            if (id) {
                promises.push(
                    await serverFetch(`tariffs/${id}`, { method: 'GET' })
                        .then((data) => {
                            return data;
                        })
                        .catch((ex) => {
                            exception(api, 'Ошибка получения тарифа', ex, () => d(userLoaded(undefined)));
                        })
                );
            }

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                let countries = result[0][0] as Array<ICountry>;
                let items = result[0][1] as Array<ICountryItem>;
                let cargoes = result[0][2] as Array<ICargo>;

                setConsignees(result[0][3]);
                setCities(result[0][4]);
                setTariffSettings(result[0][5]);
                setTransportTariffs(result[0][6]);

                if (id) {
                    let data = result[0][7] as ITariff;

                    if (isCopy) {
                        data.id = undefined;
                        data.consigneeId = undefined;
                        data.cityId = undefined;
                    }

                    let lines: Array<ITariffLine> = data.lines.filter((l) => {
                        let country = countries.find((c) => c.id === l.countryId);
                        if (country) {
                            l.countryName = country.name;
                            return l;
                        }
                    });

                    lines.map((l) => {
                        if (isCopy) {
                            l.id = undefined;

                            l.details.map((d) => {
                                d.id = undefined;
                            });
                        }
                    });

                    setEntity({ ...data, lines: lines });
                } else {
                    let lines: Array<ITariffLine> = [];

                    countries.map((c) => {
                        let line: ITariffLine = {
                            id: undefined,
                            tariffId: undefined,
                            countryId: c.id,
                            unitType: c.unitType,
                            countryName: c.name,
                            details: [],
                        };

                        let countryItems = items.filter((e) => e.countryId == c.id);

                        let countryCargoes = cargoes.filter((e) => e.countryId == c.id);

                        countryItems.map((i) => {
                            countryCargoes.map((e) => {
                                line.details.push({
                                    id: undefined,
                                    tariffLineId: undefined,
                                    itemId: i.itemId,
                                    flightPrice: e.flightPrice,
                                    truckPrice: e.truckPrice,
                                });
                            });

                            line.details.push({
                                id: undefined,
                                tariffLineId: undefined,
                                itemId: i.itemId,
                                flightPrice: undefined,
                                truckPrice: undefined,
                            });

                            lines.push(line);
                        });
                    });

                    setEntity({ ...entity, lines: lines });
                }

                setLoading(false);
            });
        };

        fetchData();
        return () => {
            cleanup = true;
        };
    }, []);

    const resetDefault = () => {
        setLoading(true);

        serverFetch(`tariffs/${id}/default`, { method: 'GET' })
            .then((result: Array<ITariffLine>) => {
                setLoading(false);

                let lines = [...entity.lines];

                for (let i = 0; i < result.length; i++) {
                    let defaultLine = result[i];

                    let line = lines.find((l) => l.countryId === defaultLine.countryId);
                    if (line) {
                        line.details.map((d) => {
                            var defaultDetail = defaultLine?.details.find((e) => e.id == d.id);
                            d.flightPrice = defaultDetail?.flightPrice;
                            d.truckPrice = defaultDetail?.truckPrice;

                            form.setFieldValue(`flightPrice${line?.id}${d.itemId}${d.cargoId}`, defaultDetail?.flightPrice);
                            form.setFieldValue(`truckPrice${line?.id}${d.itemId}${d.cargoId}`, defaultDetail?.truckPrice);
                        });
                    }
                }

                setEntity({ ...entity, lines: lines });
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка получения тарифа по умолчанию', ex, () => d(userLoaded(undefined)));
            });
    };

    const onSave = () => {
        setLoading(true);

        let data = { ...entity };

        data.lines.map((l) => {
            l.details.map((d) => {
                d.truckPrice = form.getFieldValue(`truckPrice${l.id}${d.itemId}${d.cargoId}`);
                d.flightPrice = form.getFieldValue(`flightPrice${l.id}${d.itemId}${d.cargoId}`);
            });
        });

        serverFetch(`tariffs`, { method: id && !isCopy ? 'PUT' : 'POST', bodyData: data })
            .then(() => {
                setLoading(false);
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка сохранения тарифа', ex, () => d(userLoaded(undefined)));
            });
    };

    const onConsigneeChanged = (id: string) => {
        let consignee = consignees.find((m) => m.id === id);

        let city = cities.find((c) => c.id === consignee?.cityId);

        let data = { ...entity };
        data.consigneeId = id;
        data.cityId = city?.id;

        data.lines = data.lines.map((l) => {
            var markingTariffSetting = tariffSettings.find((s) => s.consigneeId === consignee?.id && s.countryId === l.countryId);
            if (markingTariffSetting) {
                l.unitType = markingTariffSetting.unitType;
            }

            l.details.map((d) => {
                form.setFieldValue(`flightPrice${l.id}${d.itemId}${d.cargoId}`, d.flightPrice);
            });

            return l;
        });

        onCityChanged(data);
    };

    const onCityChanged = (data: ITariff) => {
        let lines = [...entity.lines];

        if (!isCopy) {
            let transportTariff = transportTariffs.find((t) => t.cityId === data.cityId);
            lines = lines.map((l) => {
                let details = l.details.filter((d) => !d.cargoId);

                details.map((detail) => {
                    if (transportTariff) {
                        if (l.unitType === UnitType.Weight) {
                            detail.truckPrice = transportTariff?.weightPrice;
                        } else if (l.unitType === UnitType.Pallet) {
                            detail.truckPrice = transportTariff?.palletPrice;
                        }
                    } else {
                        detail.truckPrice = undefined;
                    }

                    form.setFieldValue(`truckPrice${detail.id}`, detail.truckPrice);
                });

                return l;
            });
        }

        form.setFieldValue('cityId', data.cityId);

        setEntity({ ...entity, ...data, lines: lines });
    };

    const renderLines = () => {
        return entity.lines.map((l) => {
            return (
                <div key={l.id}>
                    <Form.Item>
                        <Divider orientation='left' style={{ marginBottom: 0 }}>
                            {l.countryName}
                        </Divider>
                    </Form.Item>
                    <>
                        {l.details.map((d) => {
                            return (
                                <Form.Item
                                    key={uuid()}
                                    labelAlign='left'
                                    label={d.itemName}
                                    style={{ marginBottom: 0 }}
                                    labelCol={{ span: 6 }}
                                    wrapperCol={{ span: 18 }}
                                >
                                    <Flex gap='small'>
                                        <Form.Item style={{ width: 200}}>
                                            <Flex justify='flex-end' >
                                                <Tag color={d.cargoId ? 'var(--main-green)' : ''}>{d.cargoName || 'Базовая доставка'}</Tag>
                                            </Flex>
                                        </Form.Item>
                                        <Form.Item initialValue={d.truckPrice} name={`truckPrice${l.id}${d.itemId}${d.cargoId}`}>
                                            <InputNumber
                                                decimalSeparator=','
                                                addonAfter={
                                                    <Tooltip
                                                        title={d.cargoId ? 'Транспортная доставка с перелетом' : 'Транспортная доставка '}
                                                        placement='topRight'
                                                    >
                                                        <div>
                                                            <TruckIcon style={{ color: d.cargoId ? 'var(--main-green)' : '' }} />
                                                        </div>
                                                    </Tooltip>
                                                }
                                                min={0}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            initialValue={d.flightPrice}
                                            name={`flightPrice${l.id}${d.itemId}${d.cargoId}`}
                                            style={{ visibility: d.cargoId ? 'visible' : 'hidden' }}
                                        >
                                            <InputNumber
                                                decimalSeparator=','
                                                addonAfter={
                                                    <Tooltip title='Перелет' placement='topRight'>
                                                        <div>
                                                            <AirplaneIcon />
                                                        </div>
                                                    </Tooltip>
                                                }
                                                min={0}
                                                step='0.1'
                                                stringMode
                                                disabled={!canManage}
                                            />
                                        </Form.Item>
                                    </Flex>
                                </Form.Item>
                            );
                        })}
                    </>
                </div>
            );
        });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                style={{ padding: '10px 0 0' }}
                commands={[
                    {
                        type: 'primary',
                        label: 'Сбросить тариф по умолчанию',
                        key: 'resetDefault',
                        icon: <UndoOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.DeleteTransportTariff)) {
                                securityRestriction(api, [Permission.DeleteTransportTariff]);
                                return;
                            }

                            modal.confirm({
                                title: `Сбросить значения тарифа по умолчанию?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'ОК',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    resetDefault();
                                },
                            });
                        },
                    },
                ]}
            />
        );
    };

    return (
        <>
            <FormHeader title={`${id && !isCopy ? (!canManage ? 'Информация о тарифе' : 'Изменить тариф') : 'Добавить тариф'}`} />
            {!loading && (
                <Form colon={false} labelCol={{ span: 3 }} wrapperCol={{ span: 9 }} onFinish={onSave} form={form}>
                    <Form.Item
                        initialValue={entity.consigneeId}
                        required
                        label='ID клиента'
                        name='consigneeId'
                        rules={[{ required: true, message: 'Выберите ID клиента' }]}
                    >
                        <Select
                            autoFocus
                            showSearch
                            disabled={!canManage}
                            onChange={(value) => {
                                onConsigneeChanged(value);
                            }}
                            filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                            filterSort={(a, b) => {
                                if ((a?.label as string).length !== (b?.label as string).length) {
                                    return (a?.label as string).length - (b?.label as string).length;
                                }

                                return (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase());
                            }}
                            options={consignees.map((c) => {
                                return { value: c.id, label: `${c.code}` };
                            })}
                        ></Select>
                    </Form.Item>
                    <Form.Item
                        initialValue={entity.cityId}
                        required
                        label='Расчетный город'
                        name='cityId'
                        rules={[{ required: true, message: 'Выберите город' }]}
                    >
                        <Select
                            showSearch
                            disabled={!canManage}
                            onChange={(value) => {
                                let data = { ...entity };
                                data.cityId = value;

                                onCityChanged(data);
                            }}
                            filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                            filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                            options={cities.map((c) => {
                                return { value: c.id, label: c.name };
                            })}
                        ></Select>
                    </Form.Item>

                    <Form.Item label='Комментарий' name='comment' initialValue={entity?.comment}>
                        <TextArea
                            disabled={!canManage}
                            rows={4}
                            onChange={(data) => {
                                setEntity({ ...entity, comment: data.target.value });
                            }}
                        />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 1, span: 11 }} style={{ marginBottom: 0 }}>
                        {renderToolbar()}
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 1, span: 11 }}>{entity.lines && renderLines()}</Form.Item>

                    <Form.Item label='' wrapperCol={{ offset: 3, span: 9 }}>
                        <Space size={'small'} style={{ float: 'right' }}>
                            <Button type='text' onClick={() => navigate(-1)}>
                                Отменить
                            </Button>
                            {canManage && (
                                <Button type='primary' htmlType='submit' loading={loading}>
                                    Сохранить
                                </Button>
                            )}
                        </Space>
                    </Form.Item>
                </Form>
            )}

            {contextHolder}
            {modalContextHolder}
        </>
    );
};

export default Tariff;
