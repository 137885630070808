import React, { useState, useEffect } from 'react';

import { Modal, Button, Switch, Input, Form, Space, Divider, notification } from 'antd';

import { MaskedInput } from 'antd-mask-input';

import { exception } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { serverFetch } from '@src/core/server';
import { IUserSession } from '@entities/user-session';
import { IUser } from '@entities/user';

import { UserType } from '@enums/user-type';
import { Permission, hasPermission } from '@enums/permission';

import './user.css';

interface IEmployeeForm {
    parentUserId: string | undefined;
    parentLogin: string | undefined;
    id?: string;
    onClose: (refreshRequired: boolean) => void;
}

const Employee = (props: IEmployeeForm) => {
    const { id, parentUserId, parentLogin, onClose } = props;

    const [userForm] = Form.useForm();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);
    const [api, contextHolder] = notification.useNotification();

    const [entity, setEntity] = useState<IUser>();

    const [loading, setLoading] = useState<boolean>(false);
    const [allowSetPassword, setAllowSetPassword] = useState<boolean>(!id);
    const [isViewOnly] = useState<boolean>(!hasPermission(userSession.permissions, Permission.ManageUsers));

    const [phone, setPhone] = useState<string>();

    useEffect(() => {
        let cleanup = false;

        const fetchData = async () => {
            setLoading(true);

            let promises = [];

            if (id) {
                promises.push(
                    await serverFetch(`users/${id}`, { method: 'GET' })
                        .then((data) => {
                            return data;
                        })
                        .catch((ex) => {
                            exception(api, 'Ошибка получения пользователя', ex, () => d(userLoaded(undefined)));
                        })
                );
            }

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                let user: IUser = result[0][0];
                if (user) {
                    user.login = user.login?.replace(`${parentLogin}/`, '');
                    setEntity(user);
                    setPhone(user.phone);
                } else {
                    setEntity({
                        id: undefined,
                        parentUserId: parentUserId,
                        login: undefined,
                        email: undefined,
                        fullName: undefined,
                        isActive: undefined,
                        phone: undefined,
                        isArchived: false,
                        type: UserType.Employee,
                        userSettings: {
                            showBills: false,
                            showNotifications: false,
                        },
                    });
                }

                setLoading(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, []);

    const onFinish = () => {
        if (!entity) return;

        setLoading(true);

        entity.phone = phone;
        entity.login = `${parentLogin}/${entity.login}`;

        serverFetch(`users`, { method: id ? 'PUT' : 'POST', bodyData: { user: entity } })
            .then(() => {
                setLoading(false);

                userForm.resetFields();

                onClose(true);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка сохранения пользователя', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderSettings = () => {
        return (
            <>
                <Form.Item wrapperCol={{ offset: 2, span: 22 }}>
                    <Divider orientation='left' style={{ marginBottom: 0 }}>
                        Разрешения
                    </Divider>
                </Form.Item>
                <Form.Item
                    key='showBills'
                    label='Счета'
                    initialValue={entity?.userSettings?.showBills}
                    valuePropName='checked'
                    name='showBills'
                    style={{ marginBottom: 0 }}
                >
                    <Switch
                        disabled={isViewOnly}
                        onChange={(value) => {
                            if (!entity) return;

                            setEntity({ ...entity, userSettings: { ...entity.userSettings, showBills: value } });
                        }}
                    />
                </Form.Item>
                <Form.Item
                    key='showNotifications'
                    label='Уведомления'
                    initialValue={entity?.userSettings?.showNotifications}
                    valuePropName='checked'
                    name='showNotifications'
                    style={{ marginBottom: 0 }}
                >
                    <Switch
                        disabled={isViewOnly}
                        onChange={(value) => {
                            if (!entity) return;

                            setEntity({ ...entity, userSettings: { ...entity.userSettings, showNotifications: value } });
                        }}
                    />
                </Form.Item>
            </>
        );
    };

    return (
        <Modal
            title={`${id ? (isViewOnly ? 'Информация о сотруднике' : 'Изменить сотрудника') : 'Добавить сотрудника'}`}
            open={true}
            okText='Сохранить'
            onOk={() => userForm.submit()}
            onCancel={() => {
                userForm.resetFields();
                onClose(false);
            }}
        >
            {entity && (
                <Form form={userForm} colon={false} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} onFinish={onFinish}>
                    <Form.Item
                        initialValue={entity?.login}
                        required
                        validateFirst={true}
                        label='Логин'
                        name='login'
                        rules={[
                            { required: true, message: 'Укажите логин' },
                            {
                                validator: async (_, value) => {
                                    let newLogin = `${parentLogin}%2F${value}`;

                                    let isEmailExist = await serverFetch(`users/loginexist/${id}/${newLogin}`, { method: 'GET' })
                                        .then((data) => {
                                            return data;
                                        })
                                        .catch((ex) => {
                                            exception(api, 'Ошибка проверки логина', ex, () => d(userLoaded(undefined)));
                                        });

                                    if (!id && isEmailExist) return Promise.reject('Учетная запись с указанным логином уже существует');
                                    return Promise.resolve();
                                },
                            },
                        ]}
                    >
                        <Input
                            prefix={`${parentLogin}/`}
                            autoFocus
                            disabled={isViewOnly || !!id}
                            autoComplete='none'
                            onChange={(data) => {
                                setEntity({ ...entity, login: data.target.value });
                            }}
                        />
                    </Form.Item>
                    <Form.Item required label='Пароль' name='newPassword' rules={[{ required: !id, message: 'Укажите пароль' }]}>
                        <Space.Compact style={{ width: '100%' }}>
                            <Input.Password
                                placeholder={id && !allowSetPassword ? '*******' : ''}
                                disabled={!allowSetPassword || isViewOnly}
                                onChange={(data) => {
                                    setEntity({ ...entity, newPassword: data.target.value });
                                }}
                            />
                            {id && (
                                <Button
                                    disabled={isViewOnly}
                                    type='primary'
                                    onClick={() => {
                                        setAllowSetPassword(true);
                                    }}
                                >
                                    Сменить пароль
                                </Button>
                            )}
                        </Space.Compact>
                    </Form.Item>
                    <Form.Item
                        initialValue={entity?.fullName}
                        required
                        label='ФИО'
                        name='fullName'
                        rules={[{ required: true, message: 'Укажите ФИО' }]}
                    >
                        <Input
                            disabled={isViewOnly}
                            onChange={(data) => {
                                setEntity({ ...entity, fullName: data.target.value });
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        initialValue={entity?.phone}
                        required
                        name='phone'
                        label='Телефон'
                        rules={[{ required: true, message: 'Укажите телефон' }]}
                    >
                        <MaskedInput
                            disabled={isViewOnly}
                            size='middle'
                            mask={'+7 (000) 000-00-00'}
                            onChange={(data) => {
                                setPhone(data.maskedValue);
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        initialValue={entity?.email}
                        validateFirst={true}
                        label='Email'
                        name='email'
                        rules={[
                            { type: 'email', message: 'Неверный формат Email' },
                            {
                                validator: async (_, value) => {
                                    let isEmailExist = await serverFetch(`users/emailexist/${id}/${value}`, { method: 'GET' })
                                        .then((data) => {
                                            return data;
                                        })
                                        .catch((ex) => {
                                            exception(api, 'Ошибка проверки Email', ex, () => d(userLoaded(undefined)));
                                        });

                                    if (!id && isEmailExist) return Promise.reject('Учетная запись с указанным email уже существует');
                                    return Promise.resolve();
                                },
                            },
                        ]}
                    >
                        <Input
                            disabled={isViewOnly}
                            autoComplete='none'
                            onChange={(data) => {
                                setEntity({ ...entity, email: data.target.value });
                            }}
                        />
                    </Form.Item>
                    <Form.Item label='Активно' name='isActive' valuePropName='checked'>
                        <Switch
                            disabled={isViewOnly}
                            defaultChecked={entity?.isActive}
                            onChange={(value) => {
                                setEntity({ ...entity, isActive: value });
                            }}
                        />
                    </Form.Item>

                    {renderSettings()}
                </Form>
            )}

            {contextHolder}
        </Modal>
    );
};

export default Employee;
