export enum ConsigneeType {
    Main = 1,
    GroupMember = 2,
}

export const enumLabel = (value?: ConsigneeType) => {
    switch (value) {
        case ConsigneeType.Main:
            return 'Основной';
        case ConsigneeType.GroupMember:
            return 'Член группы';
        default:
            return '';
    }
};