export enum JobStatus {
    Created = 1,
    Started = 2,
    Finished = 3,
    Cancelled = 4,
}

export const enumLabel = (value?: JobStatus) => {
    switch (value) {
        case JobStatus.Created:
            return 'Назначено';
        case JobStatus.Started:
            return 'В работе';
        case JobStatus.Finished:
            return 'Завершено';
        case JobStatus.Cancelled:
            return 'Отменено';
        default:
            return '';
    }
};
