import React, { useState, useEffect } from 'react';

import { Modal, Table, Form, Input, InputNumber, Tooltip, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { LoadingOutlined, ReloadOutlined, EditOutlined, DeleteOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';

import { exception, securityRestriction } from '@extensions/notification';
import { Permission, hasPermission } from '@enums/permission';

import { userLoaded } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { IUserSession } from '@entities/user-session';
import { ICargo } from '@entities/cargo';

import { TruckIcon, AirplaneIcon } from '@icons/index';

interface ICargoProps {
    countryId?: string | undefined;
}

const Cargoes = (props: ICargoProps) => {
    const { countryId } = props;

    const [api, contextHolder] = notification.useNotification();

    const [cargoForm] = Form.useForm();
    const [modal, modalContextHolder] = Modal.useModal();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [entities, setEntities] = useState<Array<ICargo>>([]);
    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const [currentEntity, setCurrentEntity] = useState<ICargo>();
    const [refreshRequired, setRefreshRequired] = useState<boolean>();
    const [loading, setLoading] = useState<boolean>(false);
    const [cargoOpen, setCargoOpen] = useState<boolean>(false);

    const [isViewOnly] = useState<boolean>(!hasPermission(userSession.permissions, Permission.ManageCargoes));

    useEffect(() => {
        setSelectedIds([]);
        setCurrentEntity(undefined);
        cargoForm.resetFields();
        setRefreshRequired(true);
    }, [countryId]);

    useEffect(() => {
        if (!refreshRequired) return;

        setRefreshRequired(false);

        setEntities([]);

        setLoading(true);

        serverFetch(`cargoes/${countryId}/all`, { method: 'GET' })
            .then((data) => {
                setEntities(data);

                cargoForm.resetFields();
                setCargoOpen(false);

                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения авиаперевозчиков', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    }, [refreshRequired]);

    const onDelete = () => {
        if (!currentEntity) return;

        serverFetch(`cargoes/${currentEntity.id}`, { method: 'DELETE' })
            .then(() => {
                onSelectChange([]);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка удаления авиаперевозчика', ex, () => d(userLoaded(undefined)));
            });
    };

    const onSave = (data: ICargo) => {
        setLoading(true);

        serverFetch(`cargoes`, { method: data.id ? 'PUT' : 'POST', bodyData: data })
            .then(() => {
                setLoading(false);
                setRefreshRequired(true);

                cargoForm.resetFields();
                setCargoOpen(false);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка сохранения авиаперевозчика', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Добавить',
                        key: 'add',
                        type: 'primary',
                        icon: <PlusOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageCargoes)) {
                                securityRestriction(api, [Permission.ManageCargoes]);
                                return;
                            }

                            setSelectedIds([]);
                            setCurrentEntity(undefined);

                            setCargoOpen(true);
                        },
                    },
                    {
                        label: `${
                            hasPermission(userSession.permissions, Permission.ManageCargoes) ? 'Изменить' : 'Информация об авиаперевозчике'
                        }`,
                        key: 'edit',
                        disabled: !currentEntity,
                        icon: <EditOutlined />,
                        onClick: () => {
                            setCargoOpen(true);
                        },
                    },
                    {
                        label: 'Удалить',
                        key: 'delete',
                        disabled: !currentEntity,
                        icon: <DeleteOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.DeleteCargo)) {
                                securityRestriction(api, [Permission.DeleteCargo]);
                                return;
                            }

                            Modal.confirm({
                                title: `Удалить авиаперевозчика "${currentEntity?.name}"?`,
                                okType: 'primary',
                                icon: <QuestionCircleOutlined />,
                                okText: 'Удалить',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onDelete();
                                },
                            });
                        },
                    },
                ]}
            />
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let entity = entities.find((o) => o.id == selectedRowKeys[0]);
            setCurrentEntity(entity);
        } else {
            setCurrentEntity(undefined);
        }
    };

    const renderTable = () => {
        const columns: ColumnsType<ICargo> = [
            {
                title: 'Название',
                dataIndex: 'name',
                width: 250,
            },
            {
                title: 'Код',
                dataIndex: 'code',
                width: 80,
            },
            {
                title: <AirplaneIcon style={{ fontSize: 16 }} />,
                align: 'center',
                dataIndex: 'flightPrice',
                width: 180,
            },
            /*   {
                title: <TruckIcon style={{ color: 'var(--main-green)', fontSize: 16 }} />,
                align: 'center',
                dataIndex: 'truckPrice',
                width: 180,
            }, */
            {},
        ];

        const tableLoading = {
            spinning: loading,
            indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
        };

        return (
            <Table
                rowKey='id'
                size='small'
                rowSelection={{
                    selectedRowKeys: selectedIds,
                    onChange: onSelectChange,
                    type: 'radio',
                }}
                columns={columns}
                dataSource={entities}
                loading={tableLoading}
                onRow={(record: ICargo) => {
                    return {
                        onClick: (event) => {
                            if (!record.id) return;

                            onSelectChange([record.id]);
                        },
                    };
                }}
                pagination={false}
                scroll={{ y: `calc(100vh - 175px)` }}
            />
        );
    };

    return (
        <>
            {renderToolbar()}
            {renderTable()}
            <Modal
                destroyOnClose={true}
                maskClosable={false}
                title={currentEntity ? 'Изменить авиаперевозчика' : 'Добавить авиаперевозчика'}
                open={cargoOpen}
                okText='ОК'
                onOk={() => cargoForm.submit()}
                onCancel={() => {
                    cargoForm.resetFields();
                    setCargoOpen(false);
                }}
                width={500}
            >
                <Form
                    preserve={false}
                    colon={false}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    style={{ marginTop: 20 }}
                    onFinish={onSave}
                    form={cargoForm}
                >
                    <Form.Item hidden name='id' initialValue={currentEntity?.id}>
                        <Input />
                    </Form.Item>
                    <Form.Item hidden name='countryId' initialValue={countryId}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        initialValue={currentEntity?.name}
                        label='Название'
                        name='name'
                        required={true}
                        rules={[{ required: true, message: 'Укажите название' }]}
                    >
                        <Input autoFocus disabled={isViewOnly} />
                    </Form.Item>
                    <Form.Item
                        initialValue={currentEntity?.code}
                        required
                        label='Код'
                        name='code'
                        rules={[{ required: true, message: 'Укажите код авиаперевозчика' }]}
                    >
                        <Input disabled={isViewOnly} />
                    </Form.Item>
                    <Form.Item
                        label={
                            <Tooltip title='Перелет' placement='topRight'>
                                <div>
                                    <AirplaneIcon />
                                </div>
                            </Tooltip>
                        }
                        initialValue={currentEntity?.flightPrice}
                        name='flightPrice'
                    >
                        <InputNumber decimalSeparator=',' min={0} step='0.1' stringMode disabled={isViewOnly} />
                    </Form.Item>
                    {/*  <Form.Item
                        label={
                            <Tooltip title='Транспортная доставка с перелетом' placement='topRight'>
                                <div>
                                    <TruckIcon style={{ color: 'var(--main-green)' }} />
                                </div>
                            </Tooltip>
                        }
                        initialValue={currentEntity?.truckPrice}
                        name='truckPrice'
                    >
                        <InputNumber decimalSeparator=',' min={0} step='0.1' stringMode disabled={isViewOnly} />
                    </Form.Item> */}
                </Form>
            </Modal>

            {contextHolder}
            {modalContextHolder}
        </>
    );
};

export default Cargoes;
