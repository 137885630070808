import React from 'react';
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import TableHeader from './table-header';
import TableRow from './table-row';
import { v4 as uuid } from 'uuid';

const borderColor = '#90e5fc';

const styles = StyleSheet.create({
    pageContainer: {
        margin: 20,
        fontSize: 8,
    },
    tableContainer: {
        flexDirection: 'column',
        flexWrap: 'wrap',
        marginTop: 5,
        marginBottom: 15,
        borderWidth: 1,
        borderColor: borderColor,
        borderBottom: 'none',
    },

    addressContainer: {
        display: 'flex' /* or inline-flex */,
        flexDirection: 'row',
    },

    addressLabel: {
        fontSize: 9,
        fontWeight: '600',
        textAlign: 'left',
        textDecoration: 'underline',
    },
    address: {
        marginLeft: 5,
        fontSize: 9,
        fontWeight: '600',
        textAlign: 'left',
    },
});
const PdfPage = ({ routeSheet }) => (
    <View style={styles.pageContainer}>
        {routeSheet?.map((r) => {
            return (
                <View key={uuid()}>
                    <View style={styles.addressContainer}>
                        <Text style={styles.addressLabel}>Адрес доставки:</Text>
                        <Text style={styles.address}>
                            {r.cityName}, {r.address}
                        </Text>
                    </View>
                    <View style={styles.tableContainer}>
                        <TableHeader />
                        <TableRow item={r} />
                    </View>
                </View>
            );
        })}
    </View>
);

export default PdfPage;
