import React, { useState } from 'react';

import { Form, Modal, Space, InputNumber, Radio, Input, RadioChangeEvent } from 'antd';
import { NotificationInstance } from 'antd/es/notification/interface';

import { warning } from '@extensions/notification';

import { QuestionCircleOutlined } from '@ant-design/icons';

import { ITransactionParams } from '@entities/transaction-params';

import { OperationType } from '@src/core/enums/operation-type';
import { Currency } from '@enums/currency';

import { RubleCircleIcon, UsdCircleIcon } from '@icons/index';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

interface IRefund {
    userId: string;
    userLogin: string;
    balance?: number;
    onSave: (value: ITransactionParams) => void;
    onCancel: () => void;
    api: NotificationInstance;
}

const RefundModal = (props: IRefund) => {
    const { TextArea } = Input;

    const [modal, modalContextHolder] = Modal.useModal();
    const { userId, userLogin, balance, onSave, onCancel, api } = props;

    const [transaction, setTransaction] = useState<ITransactionParams>({
        userId: userId,
        operationType: undefined,
        currency: undefined,
    });

    const onCurrencyChange = (e: RadioChangeEvent) => {
        setTransaction({ ...transaction, currency: e.target.value });
    };

    return (
        <>
            <Modal
                destroyOnClose={true}
                width={450}
                title={`Вернуть средства со счета "${userLogin}"`}
                open={true}
                okText='ОК'
                onOk={() => {
                    if (!transaction.currency) {
                        warning(api, 'Выберите счет для списания');
                        return;
                    }

                    if (transaction.currency == Currency.Usd && (!transaction.amount || transaction.amount <= 0)) {
                        warning(api, 'Укажите сумму списываемых средств');
                        return;
                    }

                    if (transaction.currency == Currency.Usd && (transaction.amount || 0) > (balance || 0)) {
                        warning(api, 'Превышен лимит списания');
                        return;
                    }

                    if (!transaction.comment) {
                        warning(api, 'Укажите комментарий');
                        return;
                    }

                    modal.confirm({
                        title: `Вернуть деньги со счета?`,
                        icon: <QuestionCircleOutlined />,
                        okType: 'primary',
                        okText: 'ОК',
                        cancelText: 'Отмена',
                        onOk: () => onSave(transaction),
                    });
                }}
                onCancel={() => onCancel()}
            >
                <Form colon={false} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} style={{ marginTop: 20 }}>
                    <Radio.Group onChange={onCurrencyChange} value={transaction.currency}>
                        {transaction.operationType != OperationType.NonCash && (
                            <Form.Item wrapperCol={{ offset: 3 }}>
                                <Space.Compact style={{ width: '100%' }}>
                                    <Form.Item style={{ width: 30 }} valuePropName='checked'>
                                        <Radio value={Currency.Usd} />
                                    </Form.Item>
                                    <Form.Item>
                                        <UsdCircleIcon style={{ color: 'green', fontSize: 28, marginLeft: 5, marginRight: 12 }} />
                                    </Form.Item>
                                    <Form.Item style={{ width: 'calc(100% - 30px)' }}>
                                        <InputNumber
                                            precision={2}
                                            stringMode
                                            disabled={true}
                                            style={{ width: '100%' }}
                                            value={balance}
                                            decimalSeparator=','
                                        />
                                    </Form.Item>
                                </Space.Compact>
                            </Form.Item>
                        )}
                    </Radio.Group>

                    <Form.Item required label='Сумма списания'>
                        <InputNumber
                            decimalSeparator=','
                            precision={2}
                            min={0}
                            stringMode
                            style={{ width: '70%' }}
                            disabled={!transaction.currency}
                            addonAfter={
                                transaction.currency == Currency.Usd ? (
                                    <UsdCircleIcon style={{ color: 'green', fontSize: 22 }} />
                                ) : (
                                    <RubleCircleIcon style={{ color: 'red', fontSize: 22 }} />
                                )
                            }
                            max={balance}
                            value={transaction.amount}
                            onChange={(value) => {
                                if (!value) return;

                                setTransaction({
                                    ...transaction,
                                    amount: transaction.currency == Currency.Usd ? value : undefined,
                                });
                            }}
                        />
                    </Form.Item>
                    <Form.Item label='Комментарий' name='comment' required>
                        <TextArea
                            rows={4}
                            onChange={(data) => {
                                setTransaction({
                                    ...transaction,
                                    comment: data.target.value,
                                });
                            }}
                        />
                    </Form.Item>
                </Form>
            </Modal>

            {modalContextHolder}
        </>
    );
};

export default RefundModal;
