export enum DayOfWeek {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
}

export const enumLabel = (value?: DayOfWeek) => {
    switch (value) {
        case DayOfWeek.Sunday:
            return 'Воскресенье';
        case DayOfWeek.Monday:
            return 'Понедельник';
        case DayOfWeek.Tuesday:
            return 'Вторник';
        case DayOfWeek.Wednesday:
            return 'Среда';
        case DayOfWeek.Thursday:
            return 'Четверг';
        case DayOfWeek.Friday:
            return 'Пятница';
        case DayOfWeek.Saturday:
            return 'Суббота';
        default:
            return '';
    }
};
