import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc';
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: borderColor,
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 20,
        textAlign: 'center',
        fontWeight: 600,
        fontSize: 7,
        flexGrow: 1,
    },
    company: {
        width: 117,
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    country: {
        width: 45,
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    total: {
        borderRightColor: borderColor,
        borderRightWidth: 1,
        width: 30,
    },
    totalVolume: {
        width: 30,
    },
});

const TableHeader = ({ countries }) => {
    return (
        <View style={styles.container}>
            <Text style={styles.company}>Город / Маркировка</Text>
            {countries.map((c) => {
                return (
                    <Text key={c.id} style={styles.country}>
                        {c.name}
                    </Text>
                );
            })}
            <Text style={styles.total}>Кол-во</Text>
            <Text style={styles.totalVolume}>Объем</Text>
        </View>
    );
};

export default TableHeader;
