import React, { useState, useEffect } from 'react';

import { Table } from 'antd';
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { notification } from 'antd';

import Toolbar from '@controls/toolbar/toolbar';

import { exception } from '@extensions/notification';
import { userLoaded } from '@store/actions';
import { useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';
import { ITransferLog } from '@entities/transfer-log';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const TransferLogs = () => {
    const d = useAppDispatch();
    const [api, contextHolder] = notification.useNotification();

    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [refreshRequired, setRefreshRequired] = useState(true);

    useEffect(() => {
        let cleanup = false;

        if (!refreshRequired) return;

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch('transferlogs', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения логов', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setLogs(result[0][0]);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                ]}
            />
        );
    };

    const renderTable = () => {
        const columns: ColumnsType<ITransferLog> = [
            {
                title: 'История',
                dataIndex: 'markingCode',
                render: (_: any, record: ITransferLog) => {
                    return (
                        <>
                            Коробка с номером <b>{`${record.fromBoxNumber}`}</b> из маркировки <b>{`${record.fromMarkingCode}`}</b>
                            перенесена на маркировку <b>{`${record.toMarkingCode}`}</b> с новым номером <b>{`${record.toBoxNumber}`}</b>
                        </>
                    );
                },
            },
            {
                title: 'Инициатор',
                dataIndex: 'initiator',
                width: 250,
            },
            {
                title: 'Дата',
                width: 150,
                render: (_, record) => {
                    return dayjs.utc(record.createdOn).local().format('DD.MM.YYYY HH:mm');
                },
            },
        ];

        return (
            <Table
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={logs}
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                pagination={false}
                scroll={{ y: `calc(100vh - 180px)`, x: 'max-content' }}
            />
        );
    };

    return (
        <>
            {renderToolbar()}
            {renderTable()}

            {contextHolder}
        </>
    );
};

export default TransferLogs;
