import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Modal, Input, Form, Divider, Select, Table, notification, InputNumber, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { DeleteOutlined, EditOutlined, PlusOutlined, LoadingOutlined, QuestionCircleOutlined, ReloadOutlined } from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';

import { getEnumList, toFinanceString } from '@extensions/utils';

import { exception } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';
import { IUserDiscount } from '@entities/user-discount';
import { IUserDiscountFilter } from '@entities/user-discount-filter';
import { IUserDebt } from '@entities/user-debt';

import { DiscountType, enumLabel as discountTypeLabel } from '@enums/discount-type';
import { Currency, enumSign as currencySign } from '@src/core/enums/currency';

import './user.css';

interface IDiscountsProps {
    userId: string;
    isViewOnly: boolean;
    userDebts: Array<IUserDebt>;
}

const Discounts = (props: IDiscountsProps) => {
    const { userId, userDebts, isViewOnly } = props;

    const [discountForm] = Form.useForm();

    const [modal, modalContextHolder] = Modal.useModal();

    const d = useAppDispatch();
    const [api, contextHolder] = notification.useNotification();

    const [discountTypes] = useState(getEnumList(DiscountType, discountTypeLabel));
    const [discounts, setDiscounts] = useState<Array<IUserDiscount>>([]);
    const [selectedDiscountIds, setSelectedDiscountIds] = useState<React.Key[]>([]);
    const [currentDiscount, setCurrentDiscount] = useState<IUserDiscount>();
    const [refreshRequired, setRefreshRequired] = useState<boolean>();
    const [discountOpen, setDiscountOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!refreshRequired) return;

        setDiscounts([]);

        setSelectedDiscountIds([]);
        setCurrentDiscount(undefined);

        setRefreshRequired(false);

        setLoading(true);

        var discountFilter: IUserDiscountFilter = {
            userId: userId,
        };

        serverFetch(`userdiscounts`, { method: 'GET', queryParams: discountFilter })
            .then((data) => {
                setDiscounts(data);
                setLoading(false);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка получения скидок', ex, () => d(userLoaded(undefined)));
            });
    }, [refreshRequired]);

    useEffect(() => {
        setRefreshRequired(true);
    }, [userDebts]);

    const onSave = (data: IUserDiscount) => {
        if (!data) return;

        setLoading(true);

        data.userId = userId;

        serverFetch(`userdiscounts`, { method: data.id ? 'PUT' : 'POST', bodyData: data })
            .then(() => {
                setLoading(false);

                setRefreshRequired(true);

                discountForm.resetFields();
                setDiscountOpen(false);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка сохранения скидки', ex, () => d(userLoaded(undefined)));
            });
    };

    const onDeleteDiscount = () => {
        serverFetch(`userdiscounts/${currentDiscount?.id}`, { method: 'DELETE' })
            .then(() => {
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка удаления скидки', ex, () => d(userLoaded(undefined)));
            });
    };

    const onDiscountSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedDiscountIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let entity = discounts.find((c) => c.id === selectedRowKeys[0]);
            setCurrentDiscount(entity);
        } else {
            setCurrentDiscount(undefined);
        }
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        disabled: loading || loading || !userId,
                        key: 'refresh',
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Добавить ',
                        key: 'add',
                        type: 'primary',
                        icon: <PlusOutlined />,
                        disabled: isViewOnly,
                        onClick: () => {
                            setSelectedDiscountIds([]);
                            setCurrentDiscount(undefined);

                            setDiscountOpen(true);
                        },
                    },
                    {
                        label: 'Изменить',
                        key: 'edit',
                        disabled: !currentDiscount || isViewOnly,
                        icon: <EditOutlined />,
                        onClick: () => {
                            setDiscountOpen(true);
                        },
                    },
                    {
                        label: 'Удалить',
                        key: 'delete',
                        disabled: !currentDiscount || isViewOnly,
                        icon: <DeleteOutlined />,
                        onClick: () => {
                            modal.confirm({
                                title: `Удалить скидку "${discountTypeLabel(currentDiscount?.type)}"?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'Удалить',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onDeleteDiscount();
                                },
                            });
                        },
                    },
                ]}
            />
        );
    };

    const renderTable = () => {
        const columns: ColumnsType<IUserDiscount> = [
            {
                title: 'Тип',
                width: 250,
                render: (_: any, record) => {
                    return discountTypeLabel(record.type);
                },
            },
            {
                title: 'Скидка',
                width: 180,
                render: (_: any, record) => {
                    return <>{toFinanceString(record.value, 2)} $</>;
                },
            },
            {
                title: 'В счет долга',
                width: 180,
                render: (_: any, record) => {
                    return record.debtName && <Tag color='var(--main-red)'>{record.debtName}</Tag>;
                },
            },
            {},
        ];

        return (
            <Table
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={discounts}
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                rowSelection={{
                    selectedRowKeys: selectedDiscountIds,
                    onChange: onDiscountSelectChange,
                    columnWidth: 35,
                    type: 'radio',
                }}
                onRow={(record) => {
                    return {
                        onClick: (event) => {
                            onDiscountSelectChange([record.id || '']);
                        },
                    };
                }}
                pagination={false}
                scroll={{ y: `calc(50vh - 180px)`, x: 'max-content' }}
            />
        );
    };

    return (
        <>
            <Divider orientation='left' style={{ marginTop: 40 }}>
                Скидки
            </Divider>

            {renderToolbar()}
            {renderTable()}

            <Modal
                destroyOnClose={true}
                maskClosable={false}
                title={currentDiscount ? 'Изменить скидку' : 'Добавить скидку'}
                open={discountOpen}
                okText='ОК'
                onOk={() => discountForm.submit()}
                onCancel={() => {
                    discountForm.resetFields();
                    setDiscountOpen(false);
                }}
                width={500}
            >
                <Form
                    preserve={false}
                    colon={false}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    style={{ marginTop: 20 }}
                    onFinish={onSave}
                    form={discountForm}
                >
                    <Form.Item hidden name='id' initialValue={currentDiscount?.id}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label='Тип'
                        initialValue={currentDiscount?.type}
                        name='type'
                        rules={[{ required: true, message: 'Укажите тип скидки' }]}
                    >
                        <Select
                            disabled={isViewOnly}
                            options={discountTypes.map((t) => {
                                return { value: t.value, label: t.label };
                            })}
                        />
                    </Form.Item>
                    <Form.Item
                        label='Скидка'
                        initialValue={currentDiscount?.value}
                        name='value'
                        required={true}
                        rules={[{ required: true, message: 'Укажите значение скидки' }]}
                    >
                        <InputNumber decimalSeparator=',' disabled={isViewOnly} addonAfter='$' />
                    </Form.Item>
                    <Form.Item label='В счет долга' initialValue={currentDiscount?.userDebtId} name='userDebtId'>
                        <Select
                            disabled={isViewOnly}
                            options={userDebts
                                .filter((d) => d.currency == Currency.Usd)
                                .map((d) => {
                                    return { value: d.id, label: `${d.debtName} (${d.amount}${currencySign(d.currency)})` };
                                })}
                        />
                    </Form.Item>
                </Form>
            </Modal>

            {contextHolder}
            {modalContextHolder}
        </>
    );
};

export default Discounts;
