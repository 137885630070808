import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { v4 as uuid } from 'uuid';

import { distinct } from '@extensions/utils';

import 'core-js/actual/array/group';

const borderColor = '#90e5fc';

const TableRow = ({ consignments, countries }) => {
    const styles = StyleSheet.create({
        row: {
            flexDirection: 'row',
            alignItems: 'right',
            textAlign: 'center',
        },
        city: {
            backgroundColor: '#F3F6F4',
            textAlign: 'left',
            width: 467,
            fontSize: 8,
            paddingLeft: 5,
        },
        noneCity: {
            backgroundColor: '#F3F6F4',
            width: 25,
            borderRightColor: borderColor,
            borderRightWidth: 1,
        },
        consigneeCode: {
            paddingTop: 2,
            width: 25,
            borderRightColor: borderColor,
            borderRightWidth: 1,
            borderTopColor: borderColor,
            borderTopWidth: 1,
        },
        marking: {
            paddingTop: 2,
            width: 92,
            borderRightColor: borderColor,
            borderRightWidth: 1,
            borderTopColor: borderColor,
            borderTopWidth: 1,
        },
        country: {
            paddingTop: 2,
            width: 45,
            borderRightColor: borderColor,
            borderRightWidth: 1,
            borderTopColor: borderColor,
            borderTopWidth: 1,
        },
        markingTotal: {
            paddingTop: 2,
            width: 30,
            borderTopColor: borderColor,
            borderTopWidth: 1,
            borderRightColor: borderColor,
            borderRightWidth: 1,
        },
        markingTotalVolume: {
            paddingTop: 2,
            width: 30,
            borderTopColor: borderColor,
            borderTopWidth: 1,
        },
        cityTotal: {
            backgroundColor: '#F3F6F4',
            paddingTop: 2,
            width: 30,
            borderTopColor: borderColor,
            borderTopWidth: 1,
            borderRightColor: borderColor,
            borderRightWidth: 1,
            borderBottomColor: borderColor,
            borderBottomWidth: 1,
        },
        cityTotalVolume: {
            backgroundColor: '#F3F6F4',
            paddingTop: 2,
            width: 30,
            borderTopColor: borderColor,
            borderTopWidth: 1,
            borderBottomColor: borderColor,
            borderBottomWidth: 1,
        },
        none: {
            textAlign: 'left',
            width: 432,
            borderTopColor: borderColor,
            borderTopWidth: 1,
            borderRightColor: borderColor,
            borderRightWidth: 1,
            borderBottomColor: borderColor,
            borderBottomWidth: 1,
        },

        totalLabel: {
            backgroundColor: '#F3F6F4',
            paddingTop: 2,
            paddingLeft: 5,
            fontWeight: 600,
            textAlign: 'left',
            width: 432,
            borderRightColor: borderColor,
            borderRightWidth: 1,
            borderBottomColor: borderColor,
            borderBottomWidth: 1,
        },
        total: {
            backgroundColor: '#F3F6F4',
            paddingTop: 2,
            fontWeight: 600,
            width: 30,
            borderRightColor: borderColor,
            borderRightWidth: 1,
            borderBottomColor: borderColor,
            borderBottomWidth: 1,
        },
        totalVolume: {
            backgroundColor: '#F3F6F4',
            paddingTop: 2,
            fontWeight: 600,
            width: 30,
            borderBottomColor: borderColor,
            borderBottomWidth: 1,
        },

        nonePackageHeader: {
            borderTopColor: borderColor,
            borderTopWidth: 1,
            width: 25,
            borderRightColor: borderColor,
            borderRightWidth: 1,
        },

       noneСonsigneeCode: {
            paddingTop: 2,
            width: 25,
            borderRightColor: borderColor,
            borderRightWidth: 1,
        },

        packageHeaderCode: {
            borderTopColor: borderColor,
            borderTopWidth: 1,
            borderRightColor: borderColor,
            borderRightWidth: 1,
            backgroundColor: '#F3F6F4',
            textAlign: 'center',
            width: 160,
            fontSize: 8,
            paddingLeft: 5,
            paddingTop: 1,
        },

        packageHeaderSize: {
            borderTopColor: borderColor,
            borderTopWidth: 1,
            borderRightColor: borderColor,
            borderRightWidth: 1,
            backgroundColor: '#F3F6F4',
            textAlign: 'center',
            width: 60,
            fontSize: 8,
            paddingLeft: 5,
            paddingTop: 1,
        },

        packageHeaderQty: {
            borderTopColor: borderColor,
            borderTopWidth: 1,
            borderRightColor: borderColor,
            borderRightWidth: 1,
            backgroundColor: '#F3F6F4',
            textAlign: 'center',
            width: 60,
            fontSize: 8,
            paddingLeft: 5,
            paddingTop: 1,
        },

        packageHeaderComment: {
            borderTopColor: borderColor,
            borderTopWidth: 1,
            borderRightColor: borderColor,
            borderRightWidth: 1,
            backgroundColor: '#F3F6F4',
            textAlign: 'center',
            width: 187,
            fontSize: 8,
            paddingLeft: 5,
            paddingTop: 1,
        },

        packageCode: {
            padding: 2,
            paddingLeft:2,
            width: 160,
            textAlign: 'left',
            borderRightColor: borderColor,
            borderRightWidth: 1,
            borderTopColor: borderColor,
            borderTopWidth: 1,
        },
        packageSize: {
            paddingTop: 2,
            width: 60,
            borderRightColor: borderColor,
            borderRightWidth: 1,
            borderTopColor: borderColor,
            borderTopWidth: 1,
        },
        packageQty: {
            paddingTop: 2,
            width: 60,
            borderRightColor: borderColor,
            borderRightWidth: 1,
            borderTopColor: borderColor,
            borderTopWidth: 1,
        },
        packageComment: {
            padding: 2,
            paddingLeft:2,
            width: 187,
            borderTopColor: borderColor,
            borderTopWidth: 1,
            textAlign: 'left',
        },
    });

    var cityKeys = [];

    if (consignments) {
        consignments.map((e) => {
            cityKeys.push(`${e.cityName}#${e.tag}`);
        });
    }

    const rows = [];

    var total = 0;
    var totalVolume = 0;

    cityKeys = distinct(cityKeys);

    cityKeys.map((key) => {
        var item = consignments.find((l) => `${l.cityName}#${l.tag}` === key);

        rows.push(
            <View style={styles.row} key={key}>
                <Text style={styles.noneCity}></Text>
                <Text style={styles.city}>
                    {item.cityName}
                    {item.tag && `   #${item.tag}`}
                </Text>
            </View>
        );

        let itemsByCity = consignments.filter((l) => `${l.cityName}#${l.tag}` === key);

        let cityTotalQty = 0;
        let cityTotalVolume = 0;

        itemsByCity.map((item) => {
            var boxGroups = {};
            item.boxGroups.map((d) => {
                boxGroups[d.countryId] = d.qty;
            });

            let consignmentLine = {
                ...item,
                ...boxGroups,
            };

            let markingTotalQty = 0;
            let markingTotalVolume = 0;

            rows.push(
                <View style={styles.row} key={item.id}>
                    <Text style={styles.consigneeCode}>{consignmentLine.consigneeCode}</Text>
                    <Text style={styles.marking}>{consignmentLine.markingCode}</Text>
                    {countries.map((c) => {
                        let boxGroups = item.boxGroups.filter((d) => d.countryId === c.id);

                        let qty = 0;
                        boxGroups.map((bg) => {
                            qty += +bg.qty;
                            markingTotalQty += +bg.qty;
                            markingTotalVolume += bg.calcVolume;
                        });

                        return (
                            <Text key={uuid()} style={styles.country}>
                                {qty > 0 ? qty : ''}
                            </Text>
                        );
                    })}
                    <Text style={styles.markingTotal}>{markingTotalQty}</Text>
                    <Text style={styles.markingTotalVolume}>{markingTotalVolume.toFixed(2)}</Text>
                </View>
            );

            item.boxGroups.map((bg) => {
                if (bg.packages && bg.packages.length > 0) {
                    rows.push(
                        <View style={styles.row} key={uuid()}>
                            <Text style={styles.nonePackageHeader}></Text>
                            <Text style={styles.packageHeaderCode}>Упаковка</Text>
                            <Text style={styles.packageHeaderSize}>Размер</Text>
                            <Text style={styles.packageHeaderQty}>Кол-во (шт)</Text>
                            <Text style={styles.packageHeaderComment}>Комментарий</Text>
                        </View>
                    );

                    bg.packages.map((p) => {
                        rows.push(
                            <View key={uuid()} style={styles.row}>
                                <Text style={styles.noneСonsigneeCode}></Text>
                                <Text style={styles.packageCode}>{p.templateName}</Text>
                                <Text style={styles.packageSize}>{`${p.length || 0} x ${p.width || 0} x ${p.height || 0}`}</Text>
                                <Text style={styles.packageQty}>{p.boxQty}</Text>
                                <Text style={styles.packageComment}>{p.comment}</Text>
                            </View>
                        );
                    });
                }
            });

            cityTotalQty += markingTotalQty;
            cityTotalVolume += markingTotalVolume;
        });

        rows.push(
            <View style={styles.row} key={uuid()}>
                <Text style={styles.none}></Text>
                <Text style={styles.cityTotal}>{cityTotalQty}</Text>
                <Text style={styles.cityTotalVolume}>{cityTotalVolume.toFixed(2)}</Text>
            </View>
        );

        total += cityTotalQty;
        totalVolume += cityTotalVolume;
    });

    rows.push(
        <View style={styles.row} key={uuid()}>
            <Text style={styles.totalLabel}>ИТОГО:</Text>
            <Text style={styles.total}>{total}</Text>
            <Text style={styles.totalVolume}>{totalVolume.toFixed(2)}</Text>
        </View>
    );

    return <Fragment>{rows}</Fragment>;
};

export default TableRow;
