import React, { useState, useEffect } from 'react';

import { Modal, Table, Form, Switch, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { LoadingOutlined, ReloadOutlined, SaveOutlined } from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';

import { exception, securityRestriction } from '@extensions/notification';
import { Permission, hasPermission } from '@enums/permission';

import { userLoaded } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { IUserSession } from '@entities/user-session';
import { IItem } from '@entities/item';

interface ICountryItem {
    id: string | undefined;
    itemId: string | undefined;
    name: string | undefined;
    code: string | undefined;
    isActive: boolean;
}

interface ICountryItemProps {
    countryId?: string | undefined;
}

const CountryItems = (props: ICountryItemProps) => {
    const { countryId } = props;

    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [entities, setEntities] = useState<Array<ICountryItem>>([]);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);

    const [isViewOnly] = useState<boolean>(!hasPermission(userSession.permissions, Permission.ManageCargoes));

    useEffect(() => {
        setRefreshRequired(true);
    }, [countryId]);

    useEffect(() => {
        let cleanup = false;

        if (!refreshRequired) return;

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch('items', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения продуктов', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch(`countries/${countryId}/items`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения продуктов для выбранной страны', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                let items: Array<ICountryItem> = [];

                let baseItems: Array<IItem> = result[0][0];
                let countryItems: Array<ICountryItem> = result[0][1];

                baseItems.map((i) => {
                    let countryItem = countryItems.find((ci) => ci.itemId == i.id);

                    items.push({
                        id: countryItem?.id,
                        itemId: i.id,
                        name: i.name,
                        code: i.code,
                        isActive: countryItem != undefined,
                    });
                });

                setEntities(items);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    const onSave = () => {
        setLoading(true);

        let data = entities.filter((e) => e.isActive);

        serverFetch(`countries/${countryId}/items`, { method: 'POST', bodyData: data })
            .then(() => {
                setLoading(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка сохранения продуктов', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Сохранить',
                        key: 'save',
                        disabled: loading || isViewOnly,
                        icon: <SaveOutlined />,
                        onClick: () => {
                            onSave();
                        },
                    },
                ]}
            />
        );
    };

    const renderTable = () => {
        const columns: ColumnsType<ICountryItem> = [
            {
                title: 'Название',
                dataIndex: 'name',
                width: 250,
            },
            {
                title: 'Код',
                dataIndex: 'code',
                width: 80,
            },
            {
                align: 'center',
                dataIndex: 'isActive',
                width: 60,
                render: (_, record) => {
                    return (
                        <Switch
                            disabled={isViewOnly}
                            size='small'
                            checked={record.isActive}
                            onChange={(value: boolean) => {
                                let items = [...entities];
                                let item = items.find((i) => i.itemId == record.itemId);
                                if (item) {
                                    item.isActive = value;
                                }

                                setEntities(items);
                            }}
                        />
                    );
                },
            },
            {},
        ];

        const tableLoading = {
            spinning: loading,
            indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
        };

        return (
            <Table
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={entities}
                loading={tableLoading}
                pagination={false}
                scroll={{ y: `calc(100vh - 175px)` }}
            />
        );
    };

    return (
        <>
            {renderToolbar()}
            {renderTable()}

            {contextHolder}
        </>
    );
};

export default CountryItems;
