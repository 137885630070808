import React from 'react';
import Icon from '@ant-design/icons';

const AuditReportIcon = ({ ...props }) => {
    return <Icon component={svg} style={{ fontSize: 18 , ...props.style }} />;
};
export default AuditReportIcon;

const svg = ({ ...props }) => (
    <svg fill='currentColor' {...props} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25.677 25.677'>
        <g id='SVGRepo_bgCarrier'></g>
        <g id='SVGRepo_tracerCarrier'></g>
        <g id='SVGRepo_iconCarrier'>
            <g>
                <g>
                    <path d='M15.865,0c-5.42,0-9.812,4.393-9.812,9.813c0,1.994,0.598,3.846,1.619,5.394h-0.66v1.44 c-0.634-0.208-1.357-0.067-1.861,0.437l-4.615,4.614c-0.714,0.713-0.714,1.869,0,2.583l0.86,0.861 c0.714,0.713,1.871,0.713,2.584,0l4.615-4.614c0.2-0.2,0.338-0.437,0.426-0.688h0.273v-2.754c1.74,1.574,4.04,2.54,6.571,2.54 c5.42,0,9.813-4.393,9.813-9.813S21.285,0,15.865,0z M15.865,17.811c-4.418,0-7.997-3.58-7.997-7.998s3.579-7.998,7.997-7.998 s7.997,3.58,7.997,7.998S20.282,17.811,15.865,17.811z'></path>
                    <path d='M22.809,10.487c-0.557-1.617-2.727-4.674-6.945-4.674c-4.217,0-6.387,3.057-6.945,4.674 c-0.18,0.522,0.097,1.091,0.619,1.271c0.521,0.178,1.091-0.097,1.271-0.619c0.003-0.008,0.069-0.187,0.209-0.453 c0.405,2.318,2.415,4.085,4.849,4.085c2.43,0,4.439-1.761,4.848-4.074c0.127,0.244,0.193,0.415,0.202,0.443 c0.144,0.413,0.532,0.673,0.947,0.673c0.108,0,0.219-0.018,0.326-0.055C22.714,11.578,22.99,11.009,22.809,10.487z M15.868,13.938 c-2.249,0-3.898-1.406-4.065-4.372c0.484-0.54,1.168-1.091,2.11-1.428c-0.389,0.452-0.632,1.033-0.632,1.675 c0,1.426,1.157,2.583,2.583,2.583s2.583-1.157,2.583-2.583c0-0.643-0.244-1.225-0.633-1.677c0.973,0.346,1.671,0.919,2.157,1.475 C19.792,12.716,18.112,13.938,15.868,13.938z'></path>
                </g>
            </g>
        </g>
    </svg>
);
